export const MSG_REQUIRED_FIELD = 'Required field.';
export const MSG_INVALID_EMAIL = 'Invalid email type.';
export const ONE_MINUTE_IN_MS = 60000;
export const MSG_ERROR = 'Error.';
export const TYPE_EVENT = 'event';
export const INTERVAL_FOR_RUNTIME = 10000;
export const ROOMID = '111111111111111111111111';
export const INTERVAL_FOR_RUNTIME_2 = 30000;

export const CONTRACT_EDITOR_CONFIG = {
  placeholder: 'Start typing your content here...',
  readonly: false,
  toolbar: true,
  height: 530,
  uploader: {
    insertImageAsBase64URI: true,
  },
  toolbarSticky: true,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: true,
  allowResizeY: true,
  buttons: [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'ul',
    'ol',
    'outdent',
    'indent',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'table',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'hr',
    'fullsize',
    '|',
    'preview',
    'selectall',
    'cut',
    'copy',
    'paste',
  ],
  buttonsXS: [
    'bold',
    'italic',
    'underline',
    '|',
    'ul',
    'ol',
    '|',
    'undo',
    'redo',
    '|',
    'lineHeight',
  ],
  language: 'en',
  tabIndex: 1,
};

export const AUTOMATED_EMAIL_EDITOR_CONFIG = {
  placeholder: 'Start typing your content here...',
  readonly: false,
  toolbar: true,
  height: 500,
  uploader: {
    insertImageAsBase64URI: true,
  },
  toolbarSticky: true,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: true,
  allowResizeY: true,
  buttons: [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'ul',
    'ol',
    'outdent',
    'indent',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'table',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '|',
    'hr',
    'fullsize',
    '|',
    'preview',
    'selectall',
    'cut',
    'copy',
    'paste',
  ],
  buttonsXS: [
    'bold',
    'italic',
    'underline',
    '|',
    'ul',
    'ol',
    '|',
    'undo',
    'redo',
  ],
  language: 'en',
  tabIndex: 1,
};
