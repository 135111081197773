import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';

import { apiHeader, BaseURL } from '../config/apiUrl';
import { Post } from '../Axios/AxiosFunctions';
import {
  setCompanyAddress,
  setCompanyFrontendDomain,
  setCompanyName,
  setLogo,
  setLogoLoading,
} from '../store/company/companySlice';

export const LogoProvider = ({ children }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.companyReducer?.loading);
  const existingLogo = useSelector((state) => state?.companyReducer?.logo);

  useEffect(() => {
    const fetchLogo = async () => {
      if (existingLogo) {
        return;
      }

      try {
        dispatch(setLogoLoading(true));
        const url = BaseURL('company/logo');
        const headers = apiHeader();
        const response = await Post(url, {}, headers);
        const logoUrl = response?.data?.logoUrl || '';
        dispatch(setLogo(logoUrl));
        dispatch(setCompanyName(response?.data?.name));
        dispatch(setCompanyFrontendDomain(response?.data?.frontendDomain));
        dispatch(setCompanyAddress(response?.data?.address));
      } catch (error) {
        console.error('Error fetching logo:', error);
      } finally {
        dispatch(setLogoLoading(false));
      }
    };

    fetchLogo();
  }, [dispatch, existingLogo]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return children;
};
