import React, { useEffect, useRef, useState } from 'react';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { BaseURL, imageUrl } from '../../config/apiUrl';
import classes from './NDATemplateView.module.css';
import { useSelector } from 'react-redux';
import { Get } from '../../Axios/AxiosFunctions';
import { useNavigate } from 'react-router-dom';
import ContractHeader from '../../Component/ContractHeader';
import { Button } from '../../Component/Button/Button';
import Loader from '../../Component/Loader';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { getTimezoneName } from '../../utils/timeUtils';
const advancedFormat = require('dayjs/plugin/advancedFormat');

const formateDate = (date) => {
  return `${dayjs(new Date(date))?.format('MM-DD-YYYY')}`;
};

const formateCurrentDateAndTime = () => {
  dayjs.locale('en');
  dayjs?.extend(advancedFormat);

  return `${dayjs(new Date()).tz(getTimezoneName()).format('ddd, DD MMM YYYY [at] hh:mm A z')}`;
};

const NDATemplateView = () => {
  const navigate = useNavigate();
  const baseApiUrl = BaseURL(`company-nda-template`);

  const { access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state.authReducer,
  );

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getAgreementContent = (content) => {
    if (content) {
      let replacedContent = content || '';

      if (data?.company?.logoUrl) {
        const companyLogo = `${imageUrl(data?.company?.logoUrl)}`;

        replacedContent = replacedContent?.replaceAll(
          '*|company_logo|*',
          `
              <img style="
                width: 200px;
                height: 100%;
                object-fit: contain;
                object-position: center;
              " src="${companyLogo}" alt="logo" />`,
        );
      }

      replacedContent = replacedContent?.replaceAll(
        '*|brokerage_name|*',
        data?.company?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|[ ]|*',
        `<p class="check_box_container">
        <input type="checkbox" name="checkbox_name[]" required>  
        <span class="checkmark"></span>
          </p>`,
      );

      let index = 1;

      replacedContent = replacedContent?.replaceAll('*|input_box|*', () => {
        const inputId = `input_${index}`;

        index++;

        return `<input type="text" id="${inputId}" name="${inputId}" required style="border:1px solid gray; padding:16px; width:100%; border-radius:6px; font-size:16px; font-weight:500 !important; color:inherit">`;
      });

      replacedContent = replacedContent?.replaceAll(
        '*|choose_one_option|*',

        () => {
          return `<div class="chooseOneOption">
            <div class="chooseOneOptionItem">
              <label for="Yes">Yes</label>
              <p class="check_box_container">
                <input class="choose_one_option_item" checked type="radio" name="choose_one_option" id="option_1" value="Yes" required>
                <span class="checkmark choose_one_option"></span>
              </p>
            </div>

            <div class="chooseOneOptionItem">
              <label for="No">No</label>
              <p class="check_box_container">
                <input class="choose_one_option_item" type="radio" name="choose_one_option" id="option_2" value="No" required>
                <span class="checkmark choose_one_option"></span>
              </p>
            </div>
            </div>`;
        },
      );

      replacedContent = replacedContent?.replaceAll(
        '*|today_date|*',
        formateDate(new Date()) || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|today_date_and_time|*',
        formateCurrentDateAndTime() || '',
      );

      return replacedContent;
    } else {
      return '';
    }
  };

  const getCompanyTemplate = async () => {
    try {
      const response = await Get(baseApiUrl, accessToken, companyId);

      if (response?.status === 200 && response?.data) {
        setData(response?.data);
        setIsLoading(false);
      } else {
        setData(null);
        setIsLoading(false);
      }
    } catch {
      setData(null);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getCompanyTemplate();
    }
  }, [accessToken]);

  return (
    <>
      <div>
        <SideBarSkeleton>
          <ContractHeader />
          <div className={[classes.mainContainer]}>
            <div className={[classes.headerContainer]}>
              <h3>NDA Template</h3>

              {!isLoading && (
                <div className={[classes.inputDiv]}>
                  <Button
                    label={
                      data?.content ? 'Update NDA Template' : 'Add NDA Template'
                    }
                    className={classes.addProjectBtn}
                    onClick={() => {
                      if (data?.content) {
                        navigate('/nda-template/update');
                      } else {
                        navigate('/nda-template/add');
                      }
                    }}
                  />
                </div>
              )}
            </div>

            {isLoading && (
              <div>
                <Loader />
              </div>
            )}

            {!isLoading && (
              <>
                <style jsx>{`
                  .table_row table {
                    border-collapse: collapse;
                  }

                  .table_row th,
                  td {
                    border-width: 1px;
                  }
                  ul,
                  li {
                    margin: initial;
                    list-style-type: unset;
                  }
                `}</style>
                <section style={{ padding: '0 60px' }}>
                  {data?.content && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getAgreementContent(data?.content),
                      }}
                    />
                  )}

                  {!data?.content && (
                    <p className={classes?.noDataContainer}>No Data Found</p>
                  )}
                </section>
              </>
            )}
          </div>
        </SideBarSkeleton>
      </div>
    </>
  );
};

export default NDATemplateView;
