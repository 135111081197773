import React, { useState } from 'react';

import { createListingstatusOptions } from '../../constant/commonData';
import { Button } from '../../Component/Button/Button';
import { DropDown } from '../../Component/DropDown/DropDown';
import ModalSkeleton from '../ModalSkeleton';
import classes from './GeneralFilterModal.module.css';

const GeneralFilterModal = ({
  show,
  setShow,
  setFilter,
  isStatus = true,
  status,
  isBroker = true,
  broker,
  allBrokers = [],
}) => {
  const [selectedStatus, setSelectedStatus] = useState(status || []);
  const [selectedBroker, setSelectedBroker] = useState(
    broker || { _id: 'null', email: 'All' },
  );

  return (
    <div>
      <style>{`
      .modal-content{
        overflow:visible !important;
      }
      `}</style>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        showCloseIcon={true}
        width="700px"
        borderRadius="20px"
        header={'Select Filters For Projects'}
      >
        <div className={classes.addProjectModal_main}>
          {isStatus && (
            <DropDown
              options={[...createListingstatusOptions.slice(5)]}
              label={'Status'}
              customStyle={{
                marginBottom: '1em',
                minWidth: '150px',
                paddingBlock: '0px',
              }}
              value={selectedStatus}
              setter={(e) => {
                setSelectedStatus(e);
              }}
              optionLabel={'label'}
              optionValue={'value'}
              isMulti={true}
              placeholder={'Select the status'}
            />
          )}
          {isBroker && (
            <DropDown
              options={[{ _id: 'null', email: 'All' }, ...allBrokers]}
              label={'Broker'}
              customStyle={{
                marginBottom: '1em',
                minWidth: '150px',
                paddingBlock: '0px',
              }}
              value={selectedBroker}
              setter={(e) => {
                setSelectedBroker(e);
              }}
              getOptionLabel={(i) =>
                i._id != 'null' ? `${i.firstName} ${i.lastName}` : 'All'
              }
              optionValue={'_id'}
            />
          )}
          <Button
            onClick={() => {
              setFilter(selectedStatus, selectedBroker);
            }}
            className={classes.btn}
            label={'Set Filter'}
            customStyle={{
              marginTop: '2em',
            }}
          />
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default GeneralFilterModal;
