import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { Delete, Get, Patch, Post } from '../../../../Axios/AxiosFunctions';
import { apiHeader, BaseURL } from '../../../../config/apiUrl';
import useDebounce from '../../../../CustomHooks/useDebounce';
import {
  formatPhoneNumber,
  formatPhoneNumberWithoutPrefix,
  getRealPhoneNumber,
} from '../../../../utils/functions';
import { onlinePresenceOptions } from '../../../../constant/commonData';
import { Button } from '../../../../Component/Button/Button';
import { Input } from '../../../../Component/Input/Input';
import { DropDown } from '../../../../Component/DropDown/DropDown';
import AddMultiValueInputWithDropDownContainer from '../../../../Component/AddMultiValueInputWithDropDownContainer';
import AreYouSureModal from '../../../../modals/AreYouSureModal';
import classes from './EditEntity.module.css';

const EditEntity = ({ _id }) => {
  const {
    user,
    access_token: accessToken,
    company_id: companyId,
  } = useSelector((state) => state.authReducer);
  const isSuperAdmin = user?.role?.includes('super-admin');
  const entityId = _id ? _id : useParams()?.id || null;
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  const [name, setName] = useState('');

  const [listings, setListings] = useState([]);
  const [allListings, setAllListings] = useState([]);

  const [allUsers, setAllUsers] = useState([]);
  const [allUserSearch, setAllUserSearch] = useState('');
  const debouncedUserSearch = useDebounce(allUserSearch, 1000);
  const [contacts, setContacts] = useState(null);

  const [industry, setIndustry] = useState('');
  const [phone, setPhone] = useState('');

  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [zipcode, setZipcode] = useState('');

  const [onlinePresenceInputState, setOnlinePresenceInputState] = useState({
    key: '',
    link: '',
  });
  const [onlinePresence, setOnlinePresence] = useState([]);

  const [showSureModal, setShowSureModal] = useState(false);

  const getEntityInfo = async () => {
    if (!entityId) return;

    setIsSaving(true);
    const res = await Get(BaseURL(`entity/${entityId}`), accessToken);
    setIsSaving(false);

    if (res?.data) {
      const data = res?.data;

      setName(data?.name || '');
      setContacts(data?.contacts || []);
      setListings(data?.listings || []);
      setPhone(formatPhoneNumber(data?.phone || ''));
      setIndustry(data?.industry || '');
      setOnlinePresence(data?.thirdPartyPresence || []);
      setCountry(data?.country);
      setState(data?.state);
      setStreet(data?.street);
      setCity(data?.city);
      setZipcode(data?.zipcode);
    } else {
      toast.error('Entity does not exist.');
      navigate(-1);
    }
  };

  const updateEntity = async () => {
    const url = BaseURL(`entity${entityId ? '/' + entityId : ''}`);

    const body = {
      name,
      contacts,
      listings: listings?.map((ele) => ele?._id),
      phone: phone ? `+1${getRealPhoneNumber(phone)}` : '',
      industry,
      thirdPartyPresence: onlinePresence,
      country,
      state,
      street,
      city,
      zipcode,
    };

    setIsSaving(true);
    const res = entityId
      ? await Patch(url, body, apiHeader(accessToken))
      : await Post(url, body, apiHeader(accessToken), companyId);
    setIsSaving(false);
    console.log(res);
    if (res?.data) {
      toast.success(`Successfully Saved`);
      if (entityId) getEntityInfo();
      else navigate(-1);
      return;
    }
  };

  const deleteEntity = async () => {
    if (!entityId) return;
    const url = BaseURL(`entity/${entityId}`);
    setIsSaving(true);
    const res = await Delete(url, null, apiHeader(accessToken));
    setIsSaving(false);
    if (res) {
      navigate(-1);
    }
  };

  const getAllUsers = async () => {
    if (!allUserSearch) return setAllUsers([]);
    const res = await Get(
      BaseURL(`users/all/?search=${allUserSearch}`),
      accessToken,
      companyId,
    );
    if (Array.isArray(res?.data)) {
      console.log(res?.data?.length);
      setAllUsers(res?.data);
    } else {
      setAllUsers([]);
    }
  };

  const getAllListings = async () => {
    const res = await Post(
      BaseURL(`business/admin/all`),
      {},
      apiHeader(accessToken),
      companyId,
    );
    if (Array.isArray(res?.data?.business)) {
      console.log(res?.data?.business);
      setAllListings(res?.data?.business);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [debouncedUserSearch]);

  useEffect(() => {
    getEntityInfo();
    getAllListings();
  }, []);

  return (
    <>
      <div className={classes.header}>
        <h4>{entityId ? 'Edit' : 'Add'} Entity</h4>
        <div style={{ display: 'flex', gap: '20px' }}>
          <Button
            label={isSaving ? 'Saving...' : entityId ? 'Save' : 'Add'}
            disabled={isSaving}
            onClick={updateEntity}
          />
          {entityId && (
            <Button
              label={'Delete'}
              onClick={() => {
                setShowSureModal(true);
              }}
              disabled={isSaving}
            />
          )}
        </div>
      </div>
      <Row className={[classes.body, 'gy-3'].join(' ')}>
        <Col xl={6} lg={6} sm={12}>
          <Input
            setter={setName}
            value={name}
            label={'Entity Name'}
            placeholder={'Enter Entity Name'}
            type={'string'}
          />
        </Col>
        <Col xl={6} lg={6} sm={12}>
          <DropDown
            setter={setContacts}
            value={contacts}
            label={'Entity Contacts'}
            inputValue={allUserSearch}
            inputSetter={setAllUserSearch}
            placeholder={'Select Entity Contacts'}
            options={allUsers}
            optionValue={'_id'}
            isMulti={true}
            getOptionLabel={(option) => {
              return `${option['firstName']} ${option['lastName']} (${option['email']})`;
            }}
          />
        </Col>
        <Col xl={12} lg={12} sm={12}>
          <DropDown
            setter={setListings}
            value={listings}
            label={'Select Listings'}
            placeholder={'Select Listings'}
            options={allListings}
            optionValue={'_id'}
            isMulti={true}
            optionLabel={'companyName'}
          />
        </Col>
        <Col xl={6} lg={6} sm={12}>
          <Input
            setter={setIndustry}
            value={industry}
            placeholder={'Enter Entity Industry'}
            label={'Industry'}
          />
        </Col>
        <Col xl={6} lg={6} sm={12}>
          <Input
            setter={(value) => setPhone(formatPhoneNumberWithoutPrefix(value))}
            value={phone}
            label={'Business Phone'}
            placeholder={'Enter Phone'}
            type={'string'}
          />
        </Col>

        <Col xl={4} lg={4} sm={6}>
          <Input
            setter={setStreet}
            value={street}
            label={'Street'}
            placeholder={'Enter Street'}
            type={'string'}
          />
        </Col>
        <Col xl={2} lg={2} sm={6}>
          <Input
            setter={setCity}
            value={city}
            label={'City'}
            placeholder={'Enter City'}
            type={'string'}
          />
        </Col>
        <Col xl={2} lg={2} sm={6}>
          <Input
            setter={setState}
            value={state}
            label={'State'}
            placeholder={'Enter State'}
            type={'string'}
          />
        </Col>
        <Col xl={2} lg={2} sm={6}>
          <Input
            setter={setZipcode}
            value={zipcode}
            label={'Zipcode'}
            placeholder={'Enter Zipcode'}
            type={'string'}
          />
        </Col>
        <Col xl={2} lg={2} sm={6}>
          <Input
            setter={setCountry}
            value={country}
            label={'Country'}
            placeholder={'Enter Country'}
            type={'string'}
          />
        </Col>

        <Col lg={12} md={12} sm={12} style={{ marginTop: '15px' }}>
          <AddMultiValueInputWithDropDownContainer
            title={'Third Party Presence'}
            inputValue={onlinePresenceInputState}
            inputSetter={setOnlinePresenceInputState}
            arrayValue={onlinePresence}
            arraySetter={setOnlinePresence}
            inputPlaceholder={'Enter Url'}
            dropDownPlaceholder={'Select Platform'}
            dropDownValueKey={'key'}
            inputValueKey={'link'}
            dropDownoptions={onlinePresenceOptions?.filter((item) => {
              const exx = onlinePresence?.find((e) => e?.key == item?.value);
              if (!exx) {
                return item;
              }
            })}
          />
        </Col>
      </Row>
      <AreYouSureModal
        show={showSureModal}
        setShow={setShowSureModal}
        subTitle={`Are you sure you want delete this Entity?`}
        onClick={deleteEntity}
      />
    </>
  );
};

export default EditEntity;
