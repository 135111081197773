import { LocalizationProvider } from '@mui/x-date-pickers';
import { enUS } from '@mui/x-date-pickers/locales';
import classes from './AddContractTemplate.module.css';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { Row } from 'react-bootstrap';
import { MdContentCopy } from 'react-icons/md';
import 'dayjs/locale/en';

export default function PopupDialog({
  open,
  setOpen,
  popupTitle,
  popupVariables,
  copyText
}) {

  const onClose = () => {
    setOpen(false);
  };

  return (
    <LocalizationProvider
      adapterLocale="en"
      localeText={
        enUS.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
            flexDirection:'column'
          }}
        >
          {popupTitle}
        </DialogTitle>

        <DialogContent>
          <Row className="gy-3 mt-1">
            {
                popupVariables && popupVariables.map(variable => (
                  <div className={classes.var_item} key={variable.id}>
                  {variable.label} ({variable.value})
                  <div className={classes.action_button}>
                    <button
                      type="button"
                      onClick={() => copyText(variable.value)}
                    >
                      <MdContentCopy size={18} />
                    </button>
                  </div>
                </div>)
              )
            }
          </Row>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>

      </Dialog>
    </LocalizationProvider>
  );
}
