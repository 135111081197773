import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { Container, OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CSVReader from 'react-csv-reader';
import { CgProfile } from 'react-icons/cg';
import { AiFillLock } from 'react-icons/ai';
import { RiBuildingLine } from 'react-icons/ri';
import { TbLogout } from 'react-icons/tb';
import { io } from 'socket.io-client';

import {
  setShowModal,
  setAddedContacts,
} from '../../store/conversation/conversationSlice';
import { BaseURL, apiUrl, imageUrl } from '../../config/apiUrl';
import { signOutRequest } from '../../store/auth/authSlice';
import { Get, Post } from '../../Axios/AxiosFunctions';
import { MSG_ERROR } from '../../utils/contants';
import AddTodoDialog from '../../pages/Conversation/dialogs/AddTodoDialog';
import Style from './AfterLoginHeader.module.css';

export const AfterLoginHeader = () => {
  const superAdminEmail = process.env.REACT_APP_SUPER_ADMIN_EMAIL;
  const [showAddTodo, setShowAddTodo] = useState(false);
  const [allAdmins, setAllAdmins] = useState([]);
  const [showNotificationOverlay, setShowNotificationOverlay] = useState(false);
  const {
    user,
    fcmToken,
    access_token: accessToken,
    company_id: companyId,
  } = useSelector((state) => state?.authReducer);
  const isHR = user?.role?.includes('hr');
  const { addedContacts } = useSelector((state) => state?.conversationReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  async function getAdminUsers() {
    if (!accessToken) return;
    const url = BaseURL('users/get-all-admins');
    const response = await Get(url, accessToken, companyId);
    if (response !== undefined) {
      setAllAdmins(response?.data?.data);
    }
  }

  useEffect(() => {
    getAdminUsers();
  }, []);

  const socket = useRef(null);

  const HandleSubmitSignOut = () => {
    socket.current = io(apiUrl);
    socket.current.emit('logout', user?._id, fcmToken);
    dispatch(signOutRequest());
    navigate('/login');
  };

  // add contact
  const handleBulkUpload = async (data, fileInfo) => {
    const keyWords = data[0];
    const keyArray = keyWords.map((str) => {
      return str
        .toLowerCase()
        .split(' ')
        .map((word, index) => {
          if (index === 0) {
            return word;
          }
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join('');
    });
    const keys = keyArray.map((word) => {
      if (word == 'phone') return 'contact';
      if (word == 'contactTypes') return 'role';
      return word;
    });
    console.log(keys);
    const contacts = data.slice(1).map((values) => {
      return keys.reduce((object, key, index) => {
        object[key] = values[index];
        return object;
      }, {});
    });

    const validContacts = contacts.filter((contact) => {
      return contact.firstName && contact.lastName && contact.email;
    });

    try {
      dispatch(setAddedContacts([...addedContacts, ...validContacts]));
    } catch (e) {
      console.log(e);
    }

    if (validContacts.length !== contacts.length) {
      console.warn('Some contacts were ignored due to missing required fields');
    }

    // Further process your valid contacts here as required
    let contactsLen = validContacts.length;
    let addedLen = 0;
    setLoading(true);
    let size = 50;
    for (let start = 0; start < contactsLen; start += size) {
      let url = `${BaseURL('communication')}/insert-contacts`;
      let end = Math.min(start + size, contactsLen);
      try {
        console.log(start);
        let response = await Post(
          url,
          validContacts.slice(start, end),
          accessToken,
        );
        console.log(response?.data);
        if (response?.data?.status == 'success') {
          addedLen += response?.data?.result;
        }
      } catch (error) {
        toast.error(error?.response?.data || MSG_ERROR);
      }
      setProgress(Math.floor((start * 1000) / contactsLen) / 10);
    }
    toast.success(`${addedLen}/${contactsLen} are added successfully`);
    setLoading(false);
    dispatch(setShowModal(true)); // Show modal after uploading contacts
  };

  return (
    <Container className={[Style.navbarContainer].join(' ')}>
      <>
        <div className={Style.container}>
          {!isHR && (
            <Button variant="contained" onClick={() => setShowAddTodo(true)}>
              Add To-do
            </Button>
          )}
          <OverlayTrigger
            placement={'bottom'}
            show={showNotificationOverlay}
            trigger={['click']}
            overlay={
              <div className={[Style.notifyoverlayDiv]}>
                <ul>
                  <li onClick={() => navigate('/update-profile')}>
                    {' '}
                    <CgProfile
                      size={24}
                      color={'var(--sidebar-text-color)'}
                    />{' '}
                    Update Profile
                  </li>
                  <li
                    onClick={() => navigate('/update-password')}
                    style={{ marginBottom: 10 }}
                  >
                    <AiFillLock size={24} color={'var(--sidebar-text-color)'} />{' '}
                    Update Password
                  </li>
                  {user?.email === superAdminEmail && (
                    <li
                      onClick={() => navigate('/company-profile')}
                      style={{ marginBottom: 10 }}
                    >
                      <RiBuildingLine
                        size={24}
                        color={'var(--sidebar-text-color)'}
                      />{' '}
                      Company Profile
                    </li>
                  )}

                  <li
                    className={[Style?.Logout].join(' ')}
                    onClick={() => {
                      HandleSubmitSignOut();
                    }}
                  >
                    <TbLogout size={24} color={'var(--sidebar-text-color)'} />
                    <span>Logout</span>
                  </li>
                </ul>
              </div>
            }
            onToggle={() =>
              setShowNotificationOverlay(!showNotificationOverlay)
            }
          >
            <div className={[Style.profileImg]}>
              <img src={imageUrl(user?.photo)} alt="..." />
            </div>
          </OverlayTrigger>
        </div>

        <CSVReader
          onFileLoaded={handleBulkUpload}
          inputId="csvUploadButton"
          inputStyle={{ display: 'none' }}
          inputProps={{ accept: '.csv' }}
        />

        <AddTodoDialog
          open={showAddTodo}
          setOpen={setShowAddTodo}
          allAdmins={allAdmins}
        />
      </>
    </Container>
  );
};
