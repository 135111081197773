import Papa from 'papaparse';
import { toast } from 'react-toastify';

export async function CsvToJsonConvertion(
  file,
  fn,
  header = true,
  skipEmptyLines = true,
) {
  try {
    const reader = new FileReader();
    reader.onload = function (event) {
      const csv = event.target.result;
      Papa.parse(csv, {
        header: header,
        skipEmptyLines: skipEmptyLines,
        complete: function (results) {
          const newData = [];
          if (Object.values(results?.data[0])?.length < 6) {
            return toast.error('Csv must contains 6 columns');
          } else {
            results?.data?.forEach((item) => {
              const valuesArray = Object.values(item);
              newData.push({
                column1: valuesArray[0],
                column2: valuesArray[1],
                column3: valuesArray[2],
                column4: valuesArray[3],
                column5: valuesArray[4],
                column6: valuesArray[5],
              });
            });
            fn(newData);
          }
        },
      });
      reader.onerror = function (error) {
        console.log('Error reading file: ' + error.message);
        return;
      };
    };
    reader.readAsText(file);
  } catch (error) {
    console.log('Error in CsvToJsonConvertionPromise: ' + error.message);
  }
}

export const CsvToJsonConvertionPromise = async (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (event) {
        const csv = event.target.result;
        Papa.parse(csv, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            if (
              results.data.length === 0 ||
              Object.keys(results.data[0]).length < 6
            ) {
              console.log('Invalid CSV format.');
              resolve([]);
            } else {
              const newData = [];
              results?.data?.forEach((item) => {
                const keysArray = Object.keys(item);
                const valuesArray = Object.values(item);
                newData.push({
                  [keysArray[0]]: valuesArray[0],
                  [keysArray[1]]: valuesArray[1],
                  [keysArray[2]]: valuesArray[2],
                  [keysArray[3]]: valuesArray[3],
                  [keysArray[4]]: valuesArray[4],
                  [keysArray[5]]: valuesArray[5],
                });
              });
              resolve(newData);
            }
          },
          error: function (error) {
            reject(new Error('Error parsing CSV: ' + error.message));
          },
        });
      };

      reader.onerror = function (error) {
        console.log('Error reading file: ' + error.message);
        resolve([]);
      };

      reader.readAsText(new Blob([file], {}));
    } catch (error) {
      console.log('Error in CsvToJsonConvertionPromise: ' + error.message);
      resolve([]);
    }
  });
};

export async function BulkUserCsvToJsonConvertion(
  data,
  fn,
  header = true,
  skipEmptyLines = true,
) {
  Papa.parse(data, {
    header: header,
    skipEmptyLines: skipEmptyLines,
    complete: function (results) {
      const newData = [];
      if (Object.values(results?.data[0])?.length < 8) {
        return toast.error('Csv must contains 8 columns');
      } else {
        results?.data?.forEach((item) => {
          //   const data = toLowerKeys(item);
          newData.push({
            ...item,
          });
        });
        fn(newData);
      }
    },
  });
}

// function toLowerKeys(obj) {
//   return Object.keys(obj).reduce((accumulator, key) => {
//     accumulator[key.toLowerCase()] = obj[key];
//     return accumulator;
//   }, {});
// }
