import axios from 'axios';
import { toast } from 'react-toastify';

/**
 * @description Sends a Get request to api
 * @param {String} route
 * @example "/api/route"
 * @returns Promise<any>
 */
const Get = async (
  route,
  accessToken,
  companyId,
  showAlert = true,
  callback,
  responseType,
) => {
  const options = accessToken
    ? {
        ...(responseType && { responseType: responseType }),
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          ...(companyId && { 'company-id': companyId }),
        },
      }
    : {
        ...(responseType && { responseType: responseType }),
        headers: {
          Accept: 'application/json',
          ...(companyId && { 'company-id': companyId }),
        },
      };
  try {
    const response = await axios.get(route, options);
    return response;
  } catch (error) {
    if (error?.response?.status * 1 == 401) {
      toast.error('User is unauthorized', {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem('persist:root');
      if (!window.location.pathname.includes('login')) {
        window.location.href = '/login';
      }
    }
    let errorMessage = '';
    Array.isArray(error?.response?.data?.message?.error)
      ? error?.response?.data?.message?.error?.map(
          (item, i) => (errorMessage = `${errorMessage} • ${item} \n`),
        )
      : (errorMessage = error?.response?.data?.message?.error);
    if (showAlert == true) {
      if (!route.includes('users/get-runtime-data')) {
        if (error.message === 'Network Error') {
          // toast.error(`${error.message} : Please Check Your Network Connection`, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        } else {
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    }
    if (!route.includes('users/get-runtime-data')) {
      callback && callback(error);
    }
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 * @returns Promise<any>
 */
const Post = async (route, data, headers, companyId, showAlert = true) => {
  const options = {
    ...headers,
    headers: {
      ...headers?.headers,
      ...(companyId && { 'company-id': companyId }),
    },
  };

  try {
    return await axios.post(route, data, options);
  } catch (error) {
    if (error?.response?.status * 1 == 401) {
      toast.error('User is unauthorized', {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem('persist:root');
      if (!window.location.pathname.includes('login')) {
        window.location.href = '/login';
      }
    }
    let errorMessage = '';
    Array.isArray(error?.response?.data?.message?.error)
      ? error?.response?.data?.message?.error?.map(
          (item, i) => (errorMessage = `${errorMessage} • ${item} \n`),
        )
      : (errorMessage = error?.response?.data?.message?.error);
    if (error.message === 'Network Error') {
      // toast.error(`${error.message} : Please Check Your Network Connection`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    } else {
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */
const Patch = async (route, data, headers, companyId, showAlert = true) => {
  const options = {
    ...headers,
    headers: {
      ...headers?.headers,
      ...(companyId && { 'company-id': companyId }),
    },
  };

  try {
    return await axios.patch(route, data, options);
  } catch (error) {
    if (error?.response?.status * 1 == 401) {
      toast.error('User is unauthorized', {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem('persist:root');
      if (!window.location.pathname.includes('login')) {
        window.location.href = '/login';
      }
    }
    let errorMessage = '';
    Array.isArray(error?.response?.data?.message?.error)
      ? error?.response?.data?.message?.error?.map(
          (item, i) => (errorMessage = `${errorMessage} • ${item} \n`),
        )
      : (errorMessage = error?.response?.data?.message?.error);
    if (error.message === 'Network Error') {
      // toast.error(`${error.message} : Please Check Your Network Connection`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    } else {
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    return error;
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */
const Put = async (route, data, headers, showAlert = true) => {
  try {
    return await axios.put(route, data, headers);
  } catch (error) {
    if (error?.response?.status * 1 == 401) {
      toast.error('User is unauthorized', {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem('persist:root');
      if (!window.location.pathname.includes('login')) {
        window.location.href = '/login';
      }
    }
    let errorMessage = '';
    Array.isArray(error?.response?.data?.message?.error)
      ? error?.response?.data?.message?.error?.map(
          (item, i) => (errorMessage = `${errorMessage} • ${item} \n`),
        )
      : (errorMessage = error?.response?.data?.message?.error);
    if (error.message === 'Network Error') {
      // toast.error(`${error.message} : Please Check Your Network Connection`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    } else {
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
};

/**
 * @description Sends a Delete request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */
const Delete = async (route, data, headers, companyId, showAlert = true) => {
  try {
    const options = {
      ...headers,
      headers: {
        ...headers?.headers,
        ...(companyId && { 'company-id': companyId }),
      },
    };
    return data == null
      ? await axios.delete(route, options)
      : await axios.delete(route, data, options);
  } catch (error) {
    if (error?.response?.status * 1 == 401) {
      toast.error('User is unauthorized', {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem('persist:root');
      if (!window.location.pathname.includes('login')) {
        window.location.href = '/login';
      }
    }
    let errorMessage = '';
    Array.isArray(error?.response?.data?.message?.error)
      ? error?.response?.data?.message?.error?.map(
          (item, i) => (errorMessage = `${errorMessage} • ${item} \n`),
        )
      : (errorMessage = error?.response?.data?.message?.error);
    if (error.message === 'Network Error') {
      // toast.error(`${error.message} : Please Check Your Network Connection`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    } else {
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
};

export { Post, Put, Get, Patch, Delete };
