import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../Component/Button/Button';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { apiHeader, BaseURL } from '../../config/apiUrl';
import classes from './ContractTemplates.module.css';
import TableSkeleton from '../../Component/TableSkeleton';
import NoData from '../../Component/NoData/NoData';
import { BsThreeDots } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Get, Delete, Post } from '../../Axios/AxiosFunctions';
import { useNavigate } from 'react-router-dom';
import PoperComponent from '../../Component/PopperComponent';
import AreYouSureModal from '../../modals/AreYouSureModal';
import { toast } from 'react-toastify';
import { LuEye } from 'react-icons/lu';
import SearchInput from '../../Component/SearchInput';
import useDebounce from '../../CustomHooks/useDebounce';
import ModalSkeleton from '../../modals/ModalSkeleton';
import ContractHeader from '../../Component/ContractHeader';
import { MSG_ERROR } from '../../utils/contants';

const recordsLimit = 40;

const ContractTemplates = () => {
  const navigate = useNavigate();

  const { access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state?.authReducer,
  );
  const baseApiUrl = BaseURL(`contract-template`);

  const [isFetchingTemplates, setIsFetchingTemplates] = useState(true);

  const [page] = useState(1);
  const [templateData, setTemplateData] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [isOpenEmailPreview, setIsOpenEmailPreview] = useState(false);
  const anchorRef = useRef(null);
  const [indexMap, setIndexMap] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState('');
  const [search, setSearch] = useState('');

  const debouncedSearchTerm = useDebounce(search, 500);

  const getAllEmailTemplates = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = `${baseApiUrl}?search=${search}&page=${page}&limit=${recordsLimit}`;

      const response = await Get(url, accessToken);

      if (response?.status === 200) {
        setTemplateData(response?.data);
      }

      setIsFetchingTemplates(false);
    }
  };

  const handleActionClick = (flag) => {
    if (flag == 'Delete') {
      setOpenDeleteConfirmation(true);
    } else if (flag == 'Edit') {
      navigate(`/contract-templates/edit-contract/${selectedItem?._id}`);
    }
  };

  const handleDelete = async () => {
    const response = await Delete(
      `${baseApiUrl}/${selectedItem?._id}`,
      apiHeader(accessToken),
    );

    if (response?.status === 200) {
      toast.success('Deleted successfully.');
      getAllEmailTemplates();
      setSelectedItem(null);
      setOpenDeleteConfirmation(false);
    } else {
      toast.error(response.data || MSG_ERROR);
    }
  };

  const handleToggle = () => {
    setOpenPopper((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    getAllEmailTemplates();
  }, [accessToken, debouncedSearchTerm]);

  return (
    <>
      <style>
        {`
      .table100 {
        overflow-x:scroll !important;
        padding-top: 0px;
      }
      .table100-head {
        min-width: 940px;
        position: relative;
      }
      .table100.ver1 th {
        font-size: 13px;
      }
      .table100.ver1 td {
        font-size: 13px;
      }
      .table100-body {
        min-width: 940px;
        max-height: calc(100vh - 340px);
        margin-top: 3px;
        overflow: auto;
      }
      .table100-body td {
        padding-block: 18px;
      }
      .column1 {
        padding-left: 16px;
      }
      .table100.ver1 .table100-body tr {
        margin:15px;
      }
      .table100 .table100-head tr {
        margin: 0 15px;
      }
      .table100-foot {
        min-width: 940px;
        position: relative;
      }
      `}
      </style>
      <div>
        <SideBarSkeleton>
          <ContractHeader />
          <div className={[classes.mainContainer]}>
            <div className={[classes.headerContainer]}>
              <h3>Contract Templates</h3>
              <div className={[classes.inputDiv]}>
                <SearchInput
                  setter={setSearch}
                  value={search}
                  placeholder={'Search'}
                />

                <Button
                  label="Add Template"
                  className={classes.addProjectBtn}
                  onClick={() => {
                    navigate('/contract-templates/add-template');
                  }}
                />
              </div>
            </div>

            <div className="table100 ver1 m-b-110">
              <div className="table100-head">
                <table>
                  <thead>
                    <tr className="row100 head">
                      <th
                        className="cell100 column1"
                        style={{ width: '5%', textAlign: 'start' }}
                      >
                        S.No
                      </th>
                      <th
                        className="cell100 column1"
                        style={{ width: '30%', textAlign: 'start' }}
                      >
                        Name
                      </th>
                      <th
                        className="cell100 column2"
                        style={{ width: '35%', textAlign: 'start' }}
                      >
                        Type
                      </th>
                      <th
                        className="cell100 column3"
                        style={{ width: '25%', textAlign: 'start' }}
                      >
                        Contract
                      </th>

                      {/* <th
                        className="cell100 column7"
                        style={{ width: '10%', textAlign: 'center' }}
                      >
                        Is Enable?
                      </th> */}

                      <th
                        className="cell100 column10"
                        style={{ width: '5%', textAlign: 'center' }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              {isFetchingTemplates ? (
                <TableSkeleton rowsCount={recordsLimit} colsCount={6} />
              ) : (
                <div className="table100-body js-pscroll ps ps--active-y">
                  <table>
                    <tbody>
                      {templateData?.result?.length > 0 ? (
                        templateData?.result?.map((item, index) => (
                          <tr
                            className="row100 body"
                            style={{
                              backgroundColor:
                                selectedItem?._id == item?._id && '#E5F5FF',
                            }}
                          >
                            <td
                              className="cell100 column1"
                              style={{
                                width: '5%',
                                textAlign: 'start',
                              }}
                            >
                              {page == 1
                                ? index + 1
                                : recordsLimit * (page - 1) + index + 1}
                            </td>
                            <td
                              className="cell100 column1"
                              style={{ width: '30%', textAlign: 'start' }}
                            >
                              {item?.name}
                            </td>
                            <td
                              className="cell100 column3"
                              style={{
                                width: '35%',
                                textAlign: 'start',
                                maxWidth: '35%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                textTransform: 'capitalize',
                              }}
                            >
                              {item?.type?.replaceAll('_', ' ')?.toLowerCase()}
                            </td>

                            <td
                              className="cell100 column7"
                              style={{ width: '25%', textAlign: 'left' }}
                            >
                              <div
                                style={{
                                  position: 'relative',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setSelectedItem(item);
                                  setIsOpenEmailPreview(true);
                                }}
                              >
                                <LuEye size={25} />
                              </div>
                            </td>
                            {/* <td
                              className="cell100 column8"
                              style={{ width: '10%', textAlign: 'center' }}
                            >
                              <div style={{ position: 'relative' }}>
                                <Checkbox
                                  checked={item?.isEnabled}
                                  disabled={true}
                                />
                              </div>
                            </td> */}

                            <td
                              className="cell100 column11"
                              style={{ width: '5%', textAlign: 'center' }}
                            >
                              <div
                                ref={index == indexMap ? anchorRef : null}
                                id="composition-button"
                                aria-controls={
                                  openPopper ? 'composition-menu' : undefined
                                }
                                aria-expanded={openPopper ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={() => {
                                  setSelectedItem(item);
                                  setIndexMap(index);
                                  setTimeout(() => {
                                    handleToggle();
                                  }, 100);
                                }}
                              >
                                <BsThreeDots
                                  size={30}
                                  className={[classes.threeDots]}
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoData text={'No Contract Templetes Found'} />
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {/* {templateData?.result?.length > 1 && (
              <div className={[classes.paginationDiv]}>
                <PaginationComponent
                  totalPages={templateData?.totalPages}
                  currentPage={page}
                  setCurrentPage={setPage}
                />
              </div>
            )} */}

            <PoperComponent
              handleClick={handleActionClick}
              open={openPopper}
              setOpen={setOpenPopper}
              anchorRef={anchorRef}
              data={['Edit', 'Delete']}
            />
          </div>
        </SideBarSkeleton>
      </div>

      <AreYouSureModal
        show={openDeleteConfirmation}
        setShow={setOpenDeleteConfirmation}
        subTitle={`Are you sure you want delete this template`}
        isApiCall={false}
        onClick={() => handleDelete()}
      />

      <ModalSkeleton
        show={isOpenEmailPreview}
        setShow={setIsOpenEmailPreview}
        borderRadius="20px"
        width="734px"
        borderLine={false}
        header={'Contract Preview'}
        showCloseIcon={true}
      >
        <div
          dangerouslySetInnerHTML={{ __html: selectedItem?.content }}
          style={{
            maxHeight: '70dvh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        />
      </ModalSkeleton>
    </>
  );
};

export default ContractTemplates;
