import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import SideBarSkeleton from '../../Component/SideBarSkeleton';
import Documents from './Documents';
import NotesList from './NotesList';
import classes from './Documents.module.css';
import ListingStatus from './ListingStatus';
import ListingList from './ListingList';

const DocumentContainer = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const tabs = ['documents', 'notes'];
  const [documentLevel, setDocumentLevel] = useState('documents');

  const [tabValue, setTabValue] = useState(
    tab && tabs.includes(tab) ? tab : tabs[0],
  );

  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const goToDocuments = () => {
    setDocumentLevel('documents');
  };

  const goToListingStatus = () => {
    setDocumentLevel('listing-status');
  };

  const goToListings = (status) => {
    setDocumentLevel(status);
  };

  return (
    <SideBarSkeleton>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={onTabChange} aria-label="Support Center Tab">
              {tabs.map((tab, index) => (
                <Tab label={tab} value={tab} />
              ))}
            </TabList>
          </Box>
          <TabPanel value={tabs[0]} style={{ padding: '0 0 0 10px' }}>
            <div className={classes.container}>
              {documentLevel == 'documents' ? (
                <Documents onSelect={goToListingStatus} />
              ) : documentLevel == 'listing-status' ? (
                <ListingStatus onSelect={goToListings} onBack={goToDocuments} />
              ) : (
                <ListingList
                  status={documentLevel}
                  onBack={goToListingStatus}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value={tabs[1]} style={{ padding: '0 0 0 10px' }}>
            <div className={classes.container}>
              <NotesList />
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </SideBarSkeleton>
  );
};

export default DocumentContainer;
