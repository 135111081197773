import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Button } from '../../Component/Button/Button';
import classes from './NDATemplate.module.css';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  CONTRACT_EDITOR_CONFIG,
  MSG_REQUIRED_FIELD,
} from '../../utils/contants';
import { TextField, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import { apiHeader, BaseURL, imageUrl } from '../../config/apiUrl';
import { Get, Patch, Post } from '../../Axios/AxiosFunctions';
import { toast } from 'react-toastify';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { MdContentCopy } from 'react-icons/md';
import { FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import JoditEditor from 'jodit-react';
import Loader from '../../Component/Loader';
import ModalSkeleton from '../../modals/ModalSkeleton';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { getTimezoneName } from '../../utils/timeUtils';
const advancedFormat = require('dayjs/plugin/advancedFormat');

const VARIABLES = [
  {
    id: 1,
    label: 'Company Logo',
    value: '*|company_logo|*',
  },
  {
    id: 2,
    label: 'Brokerage Name',
    value: '*|brokerage_name|*',
  },
  {
    id: 3,
    label: 'Buyer Name',
    value: '*|buyer_name|*',
  },
  {
    id: 4,
    label: 'Buyer Email',
    value: '*|buyer_email|*',
  },
  {
    id: 5,
    label: 'Buyer Phone',
    value: '*|buyer_phone|*',
  },
  {
    id: 6,
    label: 'Listing Reference id',
    value: '*|listing_reference_id|*',
  },
  {
    id: 7,
    label: 'Listing Headline',
    value: '*|listing_headline|*',
  },
  {
    id: 8,
    label: 'Listing Broker Name',
    value: '*|listing_broker_name|*',
  },
  {
    id: 9,
    label: 'Today Date',
    value: '*|today_date|*',
  },
  {
    id: 10,
    label: 'Today Date and Time',
    value: '*|today_date_and_time|*',
  },
  {
    id: 11,
    label: 'Check Box',
    value: '*|[ ]|*',
  },
  {
    id: 12,
    label: 'Signature Box',
    value: '*|signature_box|*',
  },
  {
    id: 13,
    label: 'Input Box',
    value: '*|input_box|*',
  },
  {
    id: 14,
    label: 'Choose One Option',
    value: '*|choose_one_option|*',
  },
];

const validationSchema = yup.object().shape({
  content: yup.string().required(MSG_REQUIRED_FIELD),
});

const formateDate = (date) => {
  return `${dayjs(new Date(date))?.format('MM-DD-YYYY')}`;
};

const formateCurrentDateAndTime = () => {
  dayjs.locale('en');
  dayjs?.extend(advancedFormat);

  return `${dayjs(new Date()).tz(getTimezoneName()).format('ddd, DD MMM YYYY [at] hh:mm A z')}`;
};

const NDATemplate = ({ editData, isLoading = false }) => {
  const editorRef = useRef();

  const { access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state.authReducer,
  );
  const logo = useSelector((state) => state?.companyReducer?.logo);
  const companyName = useSelector(
    (state) => state?.companyReducer?.companyName,
  );

  const baseApiUrl = BaseURL(`company-nda-template`);

  const navigate = useNavigate();

  const [variables, setVariables] = useState(VARIABLES);

  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);

  const {
    values,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      content: editData?.content || '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ content }) => {
      if (content === '<p><br></p>') {
        toast.error('Please add content first');
        return;
      }

      setSubmitting(true);

      const body = {
        content,
      };

      if (editData?._id) {
        const response = await Patch(
          baseApiUrl,
          body,
          apiHeader(accessToken),
          companyId,
        );

        if (response !== undefined) {
          toast.success(`NDA template Edited Successfully.`);
          setSubmitting(false);
          navigate('/nda-template');
        }
      } else {
        const response = await Post(
          baseApiUrl,
          body,
          apiHeader(accessToken),
          companyId,
        );

        if (response !== undefined) {
          toast.success(`NDA template Created Successfully.`);
          setSubmitting(false);
          navigate('/nda-template');
          resetForm();
        }
      }
    },
  });

  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);

      toast.success('Copied successfully');
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const addText = (text) => {
    const editorInstance = editorRef?.current;

    if (editorInstance) {
      const { selection } = editorInstance;

      if (selection) {
        const { range } = selection;
        if (range) {
          selection?.insertHTML(text);
          const newRange = selection.range;
          selection.selectRange(newRange);
          toast.success('Field added successfully');
        }
      }
    }
  };

  useEffect(() => {
    if (editorRef?.current?.value) {
      setFieldValue('content', editorRef?.current?.value);
    } else {
      setFieldValue('content', '');
    }
  }, [editorRef?.current?.value]);

  const getAgreementContent = (content) => {
    if (content) {
      let replacedContent = content || '';

      if (logo) {
        const companyLogo = imageUrl(logo);

        replacedContent = replacedContent?.replaceAll(
          '*|company_logo|*',
          `
              <img style="
                width: 200px;
                height: 100%;
                object-fit: contain;
                object-position: center;
              " src="${companyLogo}" alt="logo" />`,
        );
      }

      replacedContent = replacedContent?.replaceAll(
        '*|brokerage_name|*',
        companyName || '',
      );

      let index = 1;

      replacedContent = replacedContent?.replaceAll('*|input_box|*', () => {
        const inputId = `input_${index}`;

        index++;

        return `<input type="text" id="${inputId}" name="${inputId}" required style="border:1px solid gray; padding:16px; width:100%; border-radius:6px; font-size:16px; font-weight:500 !important; color:inherit">`;
      });

      replacedContent = replacedContent?.replaceAll(
        '*|[ ]|*',
        `<p class="check_box_container">
        <input type="checkbox" name="checkbox_name[]" required>  
        <span class="checkmark"></span>
          </p>`,
      );

      replacedContent = replacedContent?.replaceAll(
        '*|choose_one_option|*',

        () => {
          return `<div class="chooseOneOption">
            <div class="chooseOneOptionItem">
              <label for="Yes">Yes</label>
              <p class="check_box_container">
                <input class="choose_one_option_item" checked type="radio" name="choose_one_option" id="option_1" value="Yes" required>
                <span class="checkmark choose_one_option"></span>
              </p>
            </div>

            <div class="chooseOneOptionItem">
              <label for="No">No</label>
              <p class="check_box_container">
                <input class="choose_one_option_item" type="radio" name="choose_one_option" id="option_2" value="No" required>
                <span class="checkmark choose_one_option"></span>
              </p>
            </div>
            </div>`;
        },
      );

      replacedContent = replacedContent?.replaceAll(
        '*|today_date|*',
        formateDate(new Date()) || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|today_date_and_time|*',
        formateCurrentDateAndTime() || '',
      );

      return replacedContent;
    } else {
      return '';
    }
  };

  return (
    <>
      <SideBarSkeleton>
        {isLoading && (
          <div>
            <Loader />
          </div>
        )}

        {!isLoading && (
          <>
            <div className={classes.container_main}>
              <div className={classes.main_heading}>
                <h4>NDA Template</h4>

                <div className={classes.btn_main}>
                  <Button
                    type="button"
                    className={classes.btn}
                    label={'Preview'}
                    onClick={() => setIsOpenPreviewModal(true)}
                  />
                </div>
              </div>

              <form
                onSubmit={handleSubmit}
                className={classes.addEmailTemplateModal_main}
              >
                <Row className={classes.input_row}>
                  <div className={classes.var_section}>
                    <TextField
                      label="Search *"
                      onChange={(e) => {
                        const value = e?.target?.value?.toLowerCase()?.trim();
                        if (value) {
                          setVariables(
                            VARIABLES?.filter(
                              (item) =>
                                item?.label?.toLowerCase()?.includes(value) ||
                                item?.value?.toLowerCase()?.includes(value),
                            ),
                          );
                        } else {
                          setVariables(VARIABLES);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className={classes?.variables}>
                      {variables &&
                        variables?.length > 0 &&
                        variables.map((variable, index) => (
                          <div className={classes.var_item} key={index}>
                            {variable.label} ({variable.value})
                            <div className={classes.action_button}>
                              <button
                                type="button"
                                onClick={() => addText(variable.value)}
                                style={{ opacity: 0.8 }}
                              >
                                <FiPlusCircle size={20} />
                              </button>

                              <button
                                type="button"
                                onClick={() => copyText(variable.value)}
                              >
                                <MdContentCopy size={18} />
                              </button>
                            </div>
                          </div>
                        ))}

                      {variables && !variables?.length && (
                        <Typography textAlign={'center'}>
                          No Data Found
                        </Typography>
                      )}
                    </div>
                  </div>

                  <div className={classes.textEditor}>
                    <JoditEditor
                      ref={editorRef}
                      value={values?.content}
                      config={{
                        ...CONTRACT_EDITOR_CONFIG,
                        height: 610,
                        events: {
                          afterInit: (e) => {
                            editorRef.current = e;
                          },
                        },
                      }}
                    />
                  </div>
                </Row>

                <div className={classes.btn_main}>
                  <Button
                    type="submit"
                    className={classes.btn}
                    label={isSubmitting ? 'Submitting...' : 'Save'}
                    disabled={isSubmitting}
                  />
                </div>
              </form>
            </div>

            <ModalSkeleton
              show={isOpenPreviewModal}
              setShow={setIsOpenPreviewModal}
              borderRadius="20px"
              width="1340px"
              borderLine={false}
              header={'NDA Template Preview'}
              showCloseIcon={true}
            >
              <style jsx>{`
                .table_row table {
                  border-collapse: collapse;
                }

                .table_row th,
                td {
                  border-width: 1px;
                }

                ul,
                li {
                  margin: initial;
                  list-style-type: unset;
                }
              `}</style>

              <div
                dangerouslySetInnerHTML={{
                  __html: getAgreementContent(values?.content),
                }}
                style={{
                  maxHeight: '80dvh',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
                className={classes?.table_row}
              />
            </ModalSkeleton>
          </>
        )}
      </SideBarSkeleton>
    </>
  );
};

export default NDATemplate;
