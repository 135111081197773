import { createContext, useContext, useState } from 'react';

const FolderContext = createContext();

export function FolderProvider({ children }) {
  const [mainItems, setMainItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [action, setAction] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  return (
    <FolderContext.Provider
      value={{
        mainItems,
        setMainItems,
        selectedItem,
        setSelectedItem,
        action,
        setAction,
        selectedItems,
        setSelectedItems,
      }}
    >
      {children}
    </FolderContext.Provider>
  );
}

export const useFolder = () => useContext(FolderContext);
