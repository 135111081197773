import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MdFileOpen, MdFolder } from 'react-icons/md';

import { BaseURL } from '../../config/apiUrl';
import { Get } from '../../Axios/AxiosFunctions';
import { Button } from '../../Component/Button/Button';
import { Checkbox } from '../../Component/Checkbox/Checkbox';
import Loader from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';
import ModalSkeleton from '../ModalSkeleton';
import classes from './ImportTaxReturnModal.module.css';
import { FaFilePdf } from 'react-icons/fa';

const ImportTaxReturnModal = ({
  title = 'Import Tax Returns',
  show,
  setShow,
  listingId,
  setImportFiles,
}) => {
  const {
    user,
    access_token: accessToken,
    company_id: companyId,
  } = useSelector((state) => state.authReducer);

  const [loading, setLoading] = useState(false);
  const [parents, setParents] = useState([]);
  const [allListings, setAllListings] = useState([]);
  const [isAllFiles, setIsAllFiles] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);

  const getChildrens = async (ps) => {
    const parent = ps[ps.length - 1];
    setLoading(true);
    const res = await Get(
      BaseURL(`data-room?child=${parent}`),
      accessToken,
      companyId,
    );
    setLoading(false);
    if (res?.data?.data?.folders) {
      let listings = res?.data?.data?.folders?.[0]?.children;
      if (ps.length == 1) {
        listings = listings.filter((e) =>
          ['admin', 'broker', 'seller'].includes(e?.name),
        );
      }
      setParents(ps);
      setAllListings(listings);
      setSelectedListings([]);
      setIsAllFiles([]);
    }
  };

  const getRootChildrens = async () => {
    setLoading(true);
    const res = await Get(
      BaseURL(`data-room/tax-return-folder?listingId=${listingId}`),
      accessToken,
    );
    setLoading(false);
    if (res?.data?.data) {
      setParents([res?.data?.data?._id]);
      setAllListings(
        res?.data?.data?.children?.filter((e) =>
          ['admin', 'broker', 'seller'].includes(e?.name),
        ),
      );
    }
  };

  const handleImportFiles = async () => {
    setImportFiles(
      allListings
        ?.filter((e) => selectedListings?.includes(e?._id))
        ?.map((e) => ({ originalname: e?.fileName, key: e?.name })),
    );
  };

  useEffect(() => {
    getRootChildrens();
  }, []);

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      bvalueRadius={'20px'}
      modalClass={classes.modal}
      width={'600px'}
      header={title}
      showCloseIcon={true}
    >
      <div className={classes.headerContainer}>
        <Checkbox
          label={' '}
          value={isAllFiles?.[0]}
          setValue={(e) => {
            if (e?.includes(' ')) {
              setSelectedListings(
                allListings?.filter((e) => e?.isFile)?.map((e) => e?._id),
              );
            } else {
              setSelectedListings([]);
            }
            setIsAllFiles(e);
          }}
          disabled={allListings?.filter((e) => e?.isFile)?.length == 0}
        />
        <div className={classes.btnContainer}>
          <Button
            label={'Back'}
            disabled={parents?.length < 2}
            onClick={() => getChildrens(parents.slice(0, parents.length - 1))}
          />
          <Button label={'Import'} onClick={() => handleImportFiles()} />
        </div>
      </div>
      <div className={classes.bodyContainer}>
        {loading ? (
          <Loader />
        ) : allListings?.length == 0 ? (
          <NoData text={'No Tax Return Found'} />
        ) : (
          allListings?.map((item) => (
            <div className={classes.row}>
              <div className={classes.checkContainer}>
                <Checkbox
                  label={' '}
                  value={selectedListings?.includes(item?._id) ? ' ' : ''}
                  setValue={(e, event) => {
                    event.stopPropagation();
                    if (e?.includes(' ')) {
                      allListings?.filter((e) => e?.isFile)?.length ==
                        selectedListings?.length + 1 && setIsAllFiles([' ']);
                      setSelectedListings([...selectedListings, item?._id]);
                    } else {
                      setSelectedListings(
                        selectedListings.filter((ele) => ele != item?._id),
                      );
                      setIsAllFiles([]);
                    }
                  }}
                  disabled={!item?.isFile}
                />
              </div>
              <div
                className={classes.iconContainer}
                onClick={() =>
                  !item?.isFile && getChildrens([...parents, item._id])
                }
              >
                {item?.isFile ? (
                  <FaFilePdf size={35} />
                ) : (
                  <MdFolder size={35} />
                )}
              </div>
              <div
                className={classes.label}
                onClick={() =>
                  !item?.isFile && getChildrens([...parents, item._id])
                }
                title={item?.isFile ? item?.fileName : item?.name}
              >
                {item?.isFile ? item?.fileName : item?.name}
              </div>
            </div>
          ))
        )}
      </div>
    </ModalSkeleton>
  );
};

export default ImportTaxReturnModal;
