import './assets/Styles/style.css';
import './assets/Styles/table.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-modern-drawer/dist/index.css';
import 'react-quill/dist/quill.snow.css';
import React, { useEffect, Suspense, lazy, useState } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { apiHeader, BaseURL } from './config/apiUrl';
import { Post } from './Axios/AxiosFunctions';
import { ToastContainer } from 'react-toastify';
import BeforeLoginRoute from './Helper/BeforeLoginRoute';
import { useSelector } from 'react-redux';
import ProtectedRouter from './Helper/ProtectedRoute';
import Loader from './Component/Loader';
import LiquorAPA from './pages/AgreementTemplates/LiquorAPA';
import CompanyFolderDetail from './pages/TeamFolder/CompanyFolderDetail';
import BrokerFolderDetail from './pages/TeamFolder/BrokerFolderDetail';
import ListingFolderDetailContainer from './pages/TeamFolder/ListingFolderDetailContainer';
import NotFound from './pages/NotFound';
import UpdatePassword from './pages/UpdatePassword';
import EditProfile from './pages/EditProfile';
// import GuestMessages from './pages/GuestMessages';
import ManageAdmins from './pages/ManageAdmins';
import { RuntimeDataProvider } from './contexts/RuntimeDataContext';
import SideBarSkeleton from './Component/SideBarSkeleton';
import { changeMomentTimeZone } from './utils/timeUtils';
import EmailTemplates from './pages/AutomatedEmails';
import AddAutomatedEmail from './pages/AddAutomatedEmail';
import EditAutomatedEmail from './pages/EditAutomatedEmail';
import ListingDetail from './pages/ListingDetail';
const Listings = lazy(() => import('./pages/Listings'));
const Commission = lazy(() => import('./pages/Commission'));
const Entity = lazy(() => import('./pages/Entity'));
const EntityDetail = lazy(() => import('./pages/Entity/sections/EntityDetail'));
const EditEntityContainer = lazy(
  () => import('./pages/Entity/sections/EditEntity/container'),
);
// import SellerFolders from "./pages/TeamFolder/SellerFolders";
const StandardAPA = lazy(
  () => import('./pages/AgreementTemplates/StandardAPA'),
);
const ListingAPA = lazy(() => import('./pages/AgreementTemplates/ListingAPA'));
const Home = lazy(() => import('./pages/Home'));
// const HomePage = lazy(() => import('./pages/Home/HomePage'));
const AddListing = lazy(() => import('./pages/AddListing'));
// const TaskManagerAllProjectsContainer = lazy(
//   () => import('./pages/TaskManager/TaskManagerAllProjects/container'),
// );
const SelectedProjectTaskManager = lazy(
  () => import('./pages/TaskManager/SelectedProjectTaskManager'),
);
const AllTasks = lazy(() => import('./pages/AllTasks'));
const Todo = lazy(() => import('./pages/Todo'));
const TaskManagerTemplateTask = lazy(
  () => import('./pages/TaskManager/TaskManagerTemplateTask'),
);
const Calendar = lazy(() => import('./pages/Calendar'));
const EditListingContainer = lazy(
  () => import('./pages/EditListing/container'),
);
const ContactManagement = lazy(() => import('./pages/ContactManagement'));
const Login = lazy(() => import('./pages/Login'));
const ViewListingContainer = lazy(
  () => import('./pages/ViewListing/container'),
);
const ViewInterest = lazy(() => import('./pages/ViewInterest'));
const PreviewListing = lazy(() => import('./pages/PreviewListing'));
const TeamFolder = lazy(() => import('./pages/TeamFolder'));
const EntityFolderDetailContainer = lazy(
  () => import('./pages/TeamFolder/EntityFolderDetailContainer'),
);
const Conversation = lazy(() => import('./pages/Conversation'));
// const ConversationNew = lazy(() => import('./pages/Conversation/index-new'));
const Email = lazy(() => import('./pages/Email'));
const Market = lazy(() => import('./pages/Market'));
const Marketing = lazy(() => import('./pages/Marketing'));
// const Messages = lazy(() => import('./pages/Messages'));
const InterestsContainer = lazy(() => import('./pages/Interests/container'));
const EditInterestsProfile = lazy(
  () => import('./pages/Interests/EditProfile'),
);
const ContactUs = lazy(() => import('./pages/WebsiteForms/ContactUs'));
const FreeEvaluation = lazy(
  () => import('./pages/WebsiteForms/FreeEvaluation'),
);
const PrefferedBusiness = lazy(
  () => import('./pages/WebsiteForms/PrefferedBusiness'),
);
const PrefferedBusinessDetail = lazy(
  () => import('./pages/WebsiteForms/PrefferedBusinessDetail'),
);
const AllUser = lazy(() => import('./pages/PlatformUsers/AllUser'));
const AllBroker = lazy(() => import('./pages/PlatformUsers/AllBroker'));
const BrokerDetail = lazy(() => import('./pages/PlatformUsers/BrokerDetail'));
const UserDetail = lazy(() => import('./pages/PlatformUsers/UserDetail'));
const ProjectTaskManager = lazy(() => import('./pages/TaskManager'));
// const TaskManagerAllTemplates = lazy(
//   () => import('./pages/TaskManager/TaskManagerAllTemplates'),
// );
const ViewTeamFolderDetail = lazy(
  () => import('./pages/TeamFolder/ViewTeamFolderDetail'),
);
const OutsideUserDetail = lazy(
  () => import('./pages/PlatformUsers/OutsideUserDetail'),
);
const Reports = lazy(() => import('./pages/Reports'));
const Groups = lazy(() => import('./pages/Groups'));

const BuyABusiness = lazy(() => import('./pages/Cms/BuyABusiness'));

// Cruds Routes
const Categories = lazy(() => import('./pages/Cruds/Categories'));
const Industries = lazy(() => import('./pages/Cruds/Industries'));
const Status = lazy(() => import('./pages/Cruds/Status'));
const UserType = lazy(() => import('./pages/Cruds/UserType'));
const TokenManagement = lazy(() => import('./pages/TokenManagement'));
const SupportCenter = lazy(() => import('./pages/SupportCenter'));
// const TaxReturnConvertor = lazy(() => import('./pages/TaxReturnConvertor'));
import { TokenProvider } from './contexts/TokenContext';
import { LogoProvider } from './contexts/LogoContext';
import ContractTemplates from './pages/ContractTemplates';
import AddContractTemplate from './pages/AddContractTemplate';
import EditContractTemplate from './pages/EditContractTemplate';
import ContractAgreements from './pages/ContractAgreements';
import AddContractAgreement from './pages/AddContractAgreement';
import EditContractAgreement from './pages/EditContractAgreement';
import ContractSent from './pages/ContractSent';
import CompanyProfile from './pages/CompanyProfile';
import NDATemplate from './pages/NDATemplate';
import NDATemplateView from './pages/NDATemplateView';
import UpdateNDATemplate from './pages/UpdateNDATemplate';
import DocumentContainer from './pages/Documents';
import ListingFolderDetails from './pages/Documents/ListingFolderDetails';
import { FolderProvider } from './contexts/FolderContext';
import AdministrativeFolder from './pages/Documents/AdministrativeFolder';

const LazyWrapper = ({ component: Component, ...props }) => (
  <Suspense
    fallback={
      <SideBarSkeleton>
        <Loader />
      </SideBarSkeleton>
    }
  >
    <Component {...props} />
  </Suspense>
);

function App() {
  const { user } = useSelector((state) => state.authReducer);
  const superAdminEmail = process.env.REACT_APP_SUPER_ADMIN_EMAIL;
  const { hasNotification } = useSelector((state) => state.commonReducer);
  const isAdmin = !user?.role?.includes('broker');
  const isBroker = user?.role?.includes('broker');
  const isOnlySuperAdmin = user?.role?.includes('super-admin');
  const allowed = [
    'mjn@denverbbs.com',
    'michael@denverbbs.com',
    superAdminEmail,
  ].includes(user?.email);
  const isAllowCMS = user?.role?.some((item) =>
    ['admin', 'executive', 'hr', 'sales']?.includes(item),
  );
  const [isPaidClient, setIsPaidClient] = useState(true);

  useEffect(() => {
    changeMomentTimeZone(user?.timezone);
  }, [user]);

  useEffect(() => {
    const updateFavicon = () => {
      const favicon = document.querySelector('link[rel="icon"]');
      const badgeIconURL = hasNotification ? '/favicon1.png' : '/favicon.png';
      favicon.href = badgeIconURL;
    };

    updateFavicon(); // Call the function when 'hasNotifications' changes
  }, [hasNotification]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = BaseURL('company/logo');
        const headers = apiHeader();
        const response = await Post(url, {}, headers);
        setIsPaidClient(response?.data?.isPaidClient);
        localStorage.setItem('testtest', response?.data?.isPaidClient);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  return (
    <RuntimeDataProvider>
      <ToastContainer />

      <BrowserRouter>
        <TokenProvider>
          <LogoProvider>
            <FolderProvider>
              <Routes>
                <Route
                  path="/login"
                  exact
                  element={
                    <BeforeLoginRoute
                      element={<LazyWrapper component={Login} />}
                    />
                  }
                />
                {/* <Route
                path="/"
                exact
                element={
                  <ProtectedRouter element={<LazyWrapper component={HomePage} />} />
                }
              /> */}
                <Route
                  path="/"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Home} />}
                    />
                  }
                />
                <Route
                  path="/listings"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Listings} />}
                    />
                  }
                />
                {((isOnlySuperAdmin || isBroker) && isPaidClient) || allowed ? (
                  <Route
                    path="/commission"
                    exact
                    element={
                      <ProtectedRouter
                        element={<LazyWrapper component={Commission} />}
                      />
                    }
                  />
                ) : (
                  <></>
                )}
                <Route
                  path="/entities"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Entity} />}
                    />
                  }
                />
                <Route
                  path="/entity-detail/:id"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={EntityDetail} />}
                    />
                  }
                />
                <Route
                  path="/edit-entity/:id?"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={EditEntityContainer} />}
                    />
                  }
                />
                <Route
                  path="/contact-management"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={ContactManagement} />}
                    />
                  }
                />
                <Route
                  path="/listing-detail/:slug/"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={ListingDetail} />}
                    />
                  }
                />
                <Route
                  path="/reports"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Reports} />}
                    />
                  }
                />
                <Route
                  path="/contacts/:slug?"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Conversation} />}
                    />
                  }
                />
                {/* <Route
                path="/conversation-new"
                exact
                element={
                  <ProtectedRouter
                    element={<LazyWrapper component={ConversationNew} />}
                  />
                }
              /> */}
                <Route
                  path="/reports"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Reports} />}
                    />
                  }
                />
                {/* {isAdmin && ( */}
                <Route
                  path="/add-listing"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={AddListing} />}
                    />
                  }
                />
                <Route
                  path="/edit-listing/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={EditListingContainer} />}
                    />
                  }
                />
                <Route
                  path="/view-listing/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={ViewListingContainer} />}
                    />
                  }
                />

                <Route
                  path="/preview-listing/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={PreviewListing} />}
                    />
                  }
                />

                {/* <Route
                path="/task-manager"
                exact
                element={
                  <ProtectedRouter
                    element={<LazyWrapper component={TaskManagerAllProjectsContainer} />}
                  />
                }
              /> */}
                <Route
                  path="/project-task-manager"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={ProjectTaskManager} />}
                    />
                  }
                />
                <>
                  {/* <Route
                  path="/all-template"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={TaskManagerAllTemplates} />}
                    />
                  }
                /> */}
                  <Route
                    path="/template-task/:slug"
                    exact
                    element={
                      <ProtectedRouter
                        element={
                          <LazyWrapper component={TaskManagerTemplateTask} />
                        }
                      />
                    }
                  />
                </>
                <Route
                  path="/task-manager/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={
                        <LazyWrapper component={SelectedProjectTaskManager} />
                      }
                    />
                  }
                />
                {/* <Route
                path="/tasks"
                exact
                element={
                  <ProtectedRouter
                    element={<LazyWrapper component={AllTasks} />}
                  />
                }
              /> */}
                <Route
                  path="/todo"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Todo} />}
                    />
                  }
                />
                <Route
                  path="/calendar"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Calendar} />}
                    />
                  }
                />
                <Route
                  path="/view-interest/:id"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={ViewInterest} />}
                    />
                  }
                />
                <Route
                  path="/interests"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={InterestsContainer} />}
                    />
                  }
                />
                <Route
                  path="/documents"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={DocumentContainer} />}
                    />
                  }
                />
                <Route
                  path="/documents/administrative"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={AdministrativeFolder} />}
                    />
                  }
                />

                <Route
                  path="/documents/listing/:id/:businessId"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={ListingFolderDetails} />}
                    />
                  }
                />

                <Route
                  path="/team-folder"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={TeamFolder} />}
                    />
                  }
                />
                <Route
                  path="/team-folder/company/:id"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={CompanyFolderDetail} />}
                    />
                  }
                />
                <Route
                  path="/team-folder/entity/:id/:entityId"
                  exact
                  element={
                    <ProtectedRouter
                      element={
                        <LazyWrapper component={EntityFolderDetailContainer} />
                      }
                    />
                  }
                />
                <Route
                  path="/team-folder/:id/:businessId"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={ViewTeamFolderDetail} />}
                    />
                  }
                />
                <Route
                  path="/team-folder/broker/:id"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={BrokerFolderDetail} />}
                    />
                  }
                />
                <Route
                  path="/team-folder/listing/:id/:businessId"
                  exact
                  element={
                    <ProtectedRouter
                      element={
                        <LazyWrapper component={ListingFolderDetailContainer} />
                      }
                    />
                  }
                />
                {/* <Route
                path="/seller-folder"
                exact
                element={<ProtectedRouter element={<LazyWrapper component={SellerFolders} />} />}
              /> */}
                <Route
                  path="/email"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Email} />}
                    />
                  }
                />
                <Route
                  path="/marketing"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Market} />}
                    />
                  }
                />
                {/* <Route
                  path="/email-templates"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={Marketing} />}
                    />
                  }
                /> */}
                {/* <Route
                path="/messages"
                exact
                element={
                  <ProtectedRouter element={<LazyWrapper component={Messages} />} />
                }
              /> */}

                <Route
                  path="/contact-us"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={ContactUs} />}
                    />
                  }
                />
                {isAdmin && (
                  <>
                    <Route
                      path="/free-evaluation"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={FreeEvaluation} />}
                        />
                      }
                    />
                    <Route
                      path="/preferred-business"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={PrefferedBusiness} />
                          }
                        />
                      }
                    />
                    <Route
                      path="/preferred-business/:id"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={PrefferedBusinessDetail} />
                          }
                        />
                      }
                    />

                    <Route
                      path="/all-user"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={AllUser} />}
                        />
                      }
                    />
                    <Route
                      path="/user-detail/:slug"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={UserDetail} />}
                        />
                      }
                    />
                    <Route
                      path="/all-broker"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={AllBroker} />}
                        />
                      }
                    />
                    <Route
                      path="/broker-detail/:slug"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={BrokerDetail} />}
                        />
                      }
                    />
                    {/* same route for two pages for admin roles and outside user roles */}
                    <Route
                      path="/admin-user-detail/:slug"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <OutsideUserDetail title={'Admin User Detail'} />
                          }
                        />
                      }
                    />
                    <Route
                      path="/outside-user-detail/:slug"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={OutsideUserDetail} />
                          }
                        />
                      }
                    />

                    <Route
                      path="/free-evaluation"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={FreeEvaluation} />}
                        />
                      }
                    />
                    <Route
                      path="/preferred-business"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={PrefferedBusiness} />
                          }
                        />
                      }
                    />
                    <Route
                      path="/preferred-business/:id"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={PrefferedBusinessDetail} />
                          }
                        />
                      }
                    />
                    {/* cms and cruds */}

                    {isAllowCMS && (
                      <>
                        <Route
                          path="/categories"
                          exact
                          element={
                            <ProtectedRouter
                              element={<LazyWrapper component={Categories} />}
                            />
                          }
                        />
                        <Route
                          path="/industries"
                          exact
                          element={
                            <ProtectedRouter
                              element={<LazyWrapper component={Industries} />}
                            />
                          }
                        />
                        <Route
                          path="/status"
                          exact
                          element={
                            <ProtectedRouter
                              element={<LazyWrapper component={Status} />}
                            />
                          }
                        />
                        <Route
                          path="/user-type"
                          exact
                          element={
                            <ProtectedRouter
                              element={<LazyWrapper component={UserType} />}
                            />
                          }
                        />
                        {/* <Route
                        path="/faqs"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={Faqs />} />}
                      />
                      <Route
                        path="/reviews"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={HomeReviews />} />}
                      />
                      <Route
                        path="/our-team"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={OurTeam />} />}
                      />
                      <Route
                        path="/core-values"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={CoreValues />} />}
                      /> */}

                        {/* <Route
                        path="/cms/home"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={HomeCms />} />}
                      /> */}
                        {/* <Route
                        path="/cms/about"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={AboutCms />} />}
                      /> */}

                        {/* <Route
                        path="/cms/contact-us"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={ContactUsCms />} />}
                      /> */}

                        {/* <Route
                        path="/cms/footer"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={FooterCms />} />}
                      /> */}
                        {/* <Route
                        path="/cms/sell-your-business"
                        exact
                        element={
                          <ProtectedRouter element={<LazyWrapper component={SellYourBusinessCms />} />
                        }
                      /> */}

                        <Route
                          path="/cms/buy-a-business"
                          exact
                          element={
                            <ProtectedRouter
                              element={<LazyWrapper component={BuyABusiness} />}
                            />
                          }
                        />
                        {/* <Route
                        path="/cms/services"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={Services />} />}
                      /> */}
                        {/* <Route
                        path="/services"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={ServicesCrud />} />}
                      /> */}

                        {/* <Route
                        path="/cms/careers"
                        exact
                        element={<ProtectedRouter element={<LazyWrapper component={CareerCms />} />}
                      /> */}
                      </>
                    )}

                    <Route
                      path="/groups"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={Groups} />}
                        />
                      }
                    />
                  </>
                )}
                {isBroker && (
                  <>
                    <Route
                      path="/all-user"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={AllUser} />}
                        />
                      }
                    />
                    <Route
                      path="/user-detail/:slug"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={UserDetail} />}
                        />
                      }
                    />
                    <Route
                      path="/outside-user-detail/:slug"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={OutsideUserDetail} />
                          }
                        />
                      }
                    />
                    <Route
                      path="/all-broker"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={AllBroker} />}
                        />
                      }
                    />
                  </>
                )}
                <Route
                  path="/agreement-templates/liquorAPA"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={LiquorAPA} />}
                    />
                  }
                />
                <Route
                  path="/agreement-templates/standardAPA"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={StandardAPA} />}
                    />
                  }
                />
                <Route
                  path="/agreement-templates/listingAPA"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={ListingAPA} />}
                    />
                  }
                />
                <Route
                  path="/update-password"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={UpdatePassword} />}
                    />
                  }
                />
                <Route path="/update-profile" element={<EditProfile />} />
                <Route
                  path="/update-profile/:id"
                  element={<LazyWrapper component={EditInterestsProfile} />}
                />
                {/* <Route
                path="/support-chat"
                element={
                  <ProtectedRouter
                    element={<LazyWrapper component={GuestMessages} />}
                  />
                }
              /> */}
                {isOnlySuperAdmin ? (
                  <Route
                    path="/manage-admins"
                    exact
                    element={
                      <ProtectedRouter
                        element={<LazyWrapper component={ManageAdmins} />}
                      />
                    }
                  />
                ) : (
                  <></>
                )}
                <Route
                  path="/token-management"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={TokenManagement} />}
                    />
                  }
                />
                <Route
                  path="/automated-emails"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={EmailTemplates} />}
                    />
                  }
                />
                <Route
                  path="/edit-automated-email/:slug"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={EditAutomatedEmail} />}
                    />
                  }
                />
                <Route
                  path="/add-automated-email"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={AddAutomatedEmail} />}
                    />
                  }
                />

                {/* Contracts pages */}
                {(isPaidClient || allowed) && (
                  <>
                    <Route
                      path="/contract-templates"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={ContractTemplates} />
                          }
                        />
                      }
                    />

                    <Route
                      path="/contract-templates/add-template"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={AddContractTemplate} />
                          }
                        />
                      }
                    />

                    <Route
                      path="/contract-templates/edit-contract/:slug"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={EditContractTemplate} />
                          }
                        />
                      }
                    />

                    {/* Contracts agreements pages */}
                    <Route
                      path="/contract-agreements"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={ContractAgreements} />
                          }
                        />
                      }
                    />

                    <Route
                      path="/contract-agreements/add-agreement"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={AddContractAgreement} />
                          }
                        />
                      }
                    />

                    <Route
                      path="/contract-agreements/edit-agreement/:slug"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={EditContractAgreement} />
                          }
                        />
                      }
                    />

                    {/* Contract Sent */}
                    <Route
                      path="/contract-sent"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={ContractSent} />}
                        />
                      }
                    />

                    <Route
                      path="/nda-template"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={NDATemplateView} />}
                        />
                      }
                    />

                    <Route
                      path="/nda-template/add"
                      exact
                      element={
                        <ProtectedRouter
                          element={<LazyWrapper component={NDATemplate} />}
                        />
                      }
                    />

                    <Route
                      path="/nda-template/update"
                      exact
                      element={
                        <ProtectedRouter
                          element={
                            <LazyWrapper component={UpdateNDATemplate} />
                          }
                        />
                      }
                    />
                  </>
                )}

                <Route
                  path="/support-center"
                  exact
                  element={
                    <ProtectedRouter
                      element={<LazyWrapper component={SupportCenter} />}
                    />
                  }
                />

                {user?.email === superAdminEmail && (
                  <Route
                    path="/company-profile"
                    exact
                    element={
                      <ProtectedRouter
                        element={<LazyWrapper component={CompanyProfile} />}
                      />
                    }
                  />
                )}

                {/* <Route
              path="/tax-return-convertor"
              exact
              element={
                <ProtectedRouter
                  element={<LazyWrapper component={TaxReturnConvertor} />}
                />
              }
            /> */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </FolderProvider>
          </LogoProvider>
        </TokenProvider>
      </BrowserRouter>
    </RuntimeDataProvider>
  );
}

export default App;
