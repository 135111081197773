import React from 'react';
import ModalSkeleton from '../ModalSkeleton';

import classes from './EmailPreviewModal.module.css';

const EmailPreviewModal = ({ show, setShow, data }) => {
  return (
    <>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        borderRadius="20px"
        width="734px"
        borderLine={false}
        header={'Email Preview'}
        showCloseIcon={true}
      >
        <div className={classes.resetStyles} dangerouslySetInnerHTML={{ __html: data }} />
      </ModalSkeleton>
    </>
  );
};

export default EmailPreviewModal;
