import { useEffect, useState } from 'react';
import { Table, TableCell, TableRow } from '@material-ui/core';
import { MdAdd, MdDelete } from 'react-icons/md';

import { Button } from '../../../Component/Button/Button';
import classes from './EditableTable.module.css';

const EditableTable = ({ loading, tableData, setTableData, sheet }) => {
  const [data, setData] = useState([]);

  const onAdd = (index, value = [0, 0, 0, 0]) => {
    let d = [...data];
    d = d?.toSpliced(index, 0, {
      no: '',
      key: '',
      value,
    });
    console.log(index, value, data, d);
    setData(d);
  };

  const onEdit = (index, value, key, valIndex = -1) => {
    let d = data;
    if (valIndex == -1) {
      d = data?.map((ele, off) =>
        off == index ? { ...ele, [key]: value } : ele,
      );
    } else {
      d = data?.map((ele, off) => {
        if (off == index) {
          const values = ele?.value;
          values[valIndex] = value;
          return { ...ele, value: values };
        } else {
          return ele;
        }
      });
    }
    setData(d);
  };

  const onRemove = (index) => {
    const d = data?.filter((ele, off) => off != index);
    setData(d);
  };

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  return (
    <div className={classes.container}>
      <style>
        {`
        .MuiTableCell-sizeSmall {
          padding: 5px !important;
        }
        `}
      </style>
      <div className={classes.tableContainer}>
        <Table size="small" aria-label="a dense table">
          {[sheet == 'balance' ? { value: [0, 0, 0, 0] } : {}, ...data]?.map(
            (ele, index) => (
              <TableRow key={`${index}`}>
                <TableCell>
                  {index != 0 && (
                    <input
                      size={1}
                      value={ele?.no}
                      name="no"
                      onChange={(e) =>
                        onEdit(index - 1, e?.target?.value, 'no')
                      }
                    />
                  )}
                </TableCell>
                <TableCell title={ele?.key}>
                  {index != 0 && (
                    <input
                      size={30}
                      value={ele?.key}
                      name="key"
                      onChange={(e) =>
                        onEdit(index - 1, e?.target?.value, 'key')
                      }
                    />
                  )}
                </TableCell>
                {Array.isArray(ele?.value) ? (
                  ele?.value?.map((val, ind) => (
                    <TableCell
                      style={val == -1 ? { backgroundColor: '#cecece' } : {}}
                    >
                      {val != -1 && index != 0 && (
                        <input
                          value={val}
                          name={`value${ind}`}
                          type="number"
                          onChange={(e) =>
                            onEdit(index - 1, e?.target?.value, 'value', ind)
                          }
                          style={{ width: '80px' }}
                        />
                      )}
                    </TableCell>
                  ))
                ) : (
                  <TableCell>
                    {index != 0 && (
                      <input
                        value={ele?.value}
                        name="value"
                        type="number"
                        onChange={(e) =>
                          onEdit(index - 1, e?.target?.value, 'value')
                        }
                        style={{ width: '100px' }}
                      />
                    )}
                  </TableCell>
                )}
                <TableCell>
                  <div className={classes.btnContainer}>
                    {index != 0 ? (
                      <MdDelete
                        size={20}
                        onClick={() => {
                          onRemove(index - 1);
                        }}
                      />
                    ) : (
                      <div style={{ width: '20px' }}></div>
                    )}
                    <MdAdd
                      size={20}
                      onClick={() => {
                        Array.isArray(ele?.value)
                          ? onAdd(index)
                          : onAdd(index, 0);
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ),
          )}
        </Table>
      </div>

      <div className={classes.footer}>
        <Button
          label={'Save'}
          onClick={() => {
            setTableData(data);
          }}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default EditableTable;
