import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';

// import classes from './ClickCopy.module.css';

const CopyToClipboardButton = ({ text }) => {
  // const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // setCopied(true);
        toast.success('Business Link Copied!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <>
      <Tooltip
        title="Copy Business Link!"
        placement="right"
        onClick={handleCopy}
      >
        <IconButton>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CopyToClipboardButton;
