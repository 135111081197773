import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { changeStringToList, changeStringList } from '../../utils/functions';
import ModalSkeleton from '../ModalSkeleton';
import { TextArea } from '../../Component/TextArea';
import { Button } from '../../Component/Button/Button';
import { Input } from '../../Component/Input/Input';
import AddEditMultiValueInputContainer from '../../Component/AddEditMultiValueInputContainer';
import classes from './CIMConfirmModal.module.css';

const CIMConfirmModal = ({ show, setShow, data, onConfirm }) => {
  const [realDescription, setRealDescription] = useState('');
  const [dummyDescription, setDummyDescription] = useState('');
  const [highlight, setHighlight] = useState([]);
  const [pros, setPros] = useState([]);
  const [cons, setCons] = useState([]);
  const [strengths, setStrengths] = useState([]);
  const [weaknesses, setWeaknesses] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [threats, setThreats] = useState([]);
  const [employeesDescription, setEmployeesDescription] = useState('');
  const [ownerInvolvment, setOwnerInvolvment] = useState('');
  const [demographicTitle, setDemographicTitle] = useState('');
  const [demographics, setDemographics] = useState('');
  const [businessOpportunity, setBusinessOpportunity] = useState(0);
  const [inventory, setInventory] = useState(0);

  const onConfirmEvent = () => {
    onConfirm({
      realDescription,
      dummyDescription,
      highlight,
      pros,
      cons,
      ownerInvolvment,
      employeesDescription,
      strengths: strengths?.filter((e) => e),
      weaknesses: weaknesses?.filter((e) => e),
      opportunities: opportunities?.filter((e) => e),
      threats: threats?.filter((e) => e),
      demographicTitle,
      demographics,
      businessOpportunity,
      inventory,
    });
  };

  const onCancel = () => {
    setShow(false);
  };

  const onReset = () => {
    if (!data) return;
    setRealDescription(data?.real_description || '');
    setDummyDescription(data?.demo_description || '');
    setHighlight(data?.highlight || []);
    setPros(data?.pros || []);
    setCons(data?.cons || []);
    setEmployeesDescription(data?.employees_description || '');
    setOwnerInvolvment(data?.owner_involvment || '');
    setDemographicTitle(`Demographics for ${data?.county_name || ''}`);
    setDemographics(data?.demographics || '');

    const swot = data?.swot_analysis || {};
    setStrengths(swot?.strengths || []);
    setWeaknesses(swot?.weaknesses || []);
    setOpportunities(swot?.opportunities || []);
    setThreats(swot?.threats || []);

    setBusinessOpportunity(data?.business_opportunity || 0);
    setInventory(data?.inventory || 0);
  };

  useEffect(() => {
    onReset();
  }, [data]);

  return (
    <>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        borderRadius="20px"
        width="950px"
        borderLine={false}
        header={'CIM Confirm Modal'}
        showCloseIcon={true}
      >
        <div className={classes.mainContainer}>
          <div className={classes.inputMain}>
            <TextArea
              label={'Real Description'}
              placeholder={'Enter Real Description'}
              value={realDescription}
              setter={setRealDescription}
            />
          </div>
          <div className={classes.inputMain}>
            <TextArea
              label={'Demo Description'}
              placeholder={'Enter Demo Description'}
              value={dummyDescription}
              setter={setDummyDescription}
            />
          </div>
          <div className={classes.inputMain}>
            <AddEditMultiValueInputContainer
              title={'Highlight'}
              arrayValue={highlight}
              arraySetter={setHighlight}
              placeholder={'Add Business Highlight'}
            />
          </div>
          <div className={classes.inputMain}>
            <AddEditMultiValueInputContainer
              title={'Pros'}
              arrayValue={pros}
              arraySetter={setPros}
              placeholder={'Add Business Pros'}
            />
          </div>
          <div className={classes.inputMain}>
            <AddEditMultiValueInputContainer
              title={'Cons'}
              arrayValue={cons}
              arraySetter={setCons}
              placeholder={'Add Business Cons'}
            />
          </div>
          <div className={classes.inputMain}>
            <TextArea
              label={'Owner Involement'}
              placeholder={'Enter Owner Involement'}
              value={ownerInvolvment}
              setter={setOwnerInvolvment}
            />
          </div>
          <div className={classes.inputMain}>
            <TextArea
              label={'Employees Description'}
              placeholder={'Enter Employees Description'}
              value={employeesDescription}
              setter={setEmployeesDescription}
              rows={4}
            />
          </div>
          <div className={classes.swotContainer}>
            <div className={classes.swotTitle}>SWOT Analysis</div>
            <Row className={classes.swotBody}>
              <Col lg={6} sm={12}>
                <div className={classes.inputMain}>
                  <TextArea
                    label={'Strengths'}
                    value={changeStringList(strengths)}
                    setter={(v) => setStrengths(changeStringToList(v))}
                    labelStyle={{ color: 'black' }}
                    customStyle={{ backgroundColor: '#8ED973' }}
                  />
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className={classes.inputMain}>
                  <TextArea
                    label={'Weaknesses'}
                    value={changeStringList(weaknesses)}
                    setter={(v) => setWeaknesses(changeStringToList(v))}
                    labelStyle={{ color: 'black' }}
                    customStyle={{ backgroundColor: '#FF7C80' }}
                  />
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className={classes.inputMain}>
                  <TextArea
                    label={'Opportunities'}
                    value={changeStringList(opportunities)}
                    setter={(v) => setOpportunities(changeStringToList(v))}
                    labelStyle={{ color: 'black' }}
                    customStyle={{ backgroundColor: '#F9C7AC' }}
                  />
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className={classes.inputMain}>
                  <TextArea
                    label={'Threats'}
                    value={changeStringList(threats)}
                    setter={(v) => setThreats(changeStringToList(v))}
                    labelStyle={{ color: 'black' }}
                    customStyle={{ backgroundColor: '#8ED973' }}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className={classes.inputMain}>
            <Input
              label={'Demographic Title'}
              placeholder={'Enter Demographic Title'}
              value={demographicTitle}
              setter={setDemographicTitle}
            />
          </div>
          <div className={classes.inputMain}>
            <TextArea
              label={'Demographics'}
              placeholder={'Enter Demographics'}
              value={demographics}
              setter={setDemographics}
            />
          </div>
          <div className={classes.inputMain}>
            <Row>
              <Col lg={6} sm={12}>
                <Input
                  label={'Business Opportunity Price'}
                  placeholder={'Enter Business Price'}
                  value={businessOpportunity}
                  setter={setBusinessOpportunity}
                  type={'number'}
                />
              </Col>
              <Col lg={6} sm={12}>
                <Input
                  label={'Inventory'}
                  placeholder={'Enter Inventory'}
                  value={inventory}
                  setter={setInventory}
                  type={'number'}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className={classes.footerContainer}>
          <Button label={'Reset'} onClick={onReset} />
          <Button label={'Confirm'} onClick={onConfirmEvent} />
          <Button label={'Cancel'} onClick={onCancel} />
        </div>
      </ModalSkeleton>
    </>
  );
};

export default CIMConfirmModal;
