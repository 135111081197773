import React from 'react';

import ListingFolderDetails from '../ListingFolderDetails';

const AdministrativeFolder = () => {
  return (
    <div>
      <ListingFolderDetails isAdministrative={true} />
    </div>
  );
};

export default AdministrativeFolder;
