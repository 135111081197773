import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { GoCheck } from 'react-icons/go';
import {
  FaDollarSign,
  FaMoneyCheckAlt,
  FaRegBuilding,
  FaRegMoneyBillAlt,
  FaUsers,
  FaInternetExplorer,
  FaYelp,
  FaCheck,
  FaUserTie,
  FaRegHandshake,
  FaMapMarker,
  FaRegThumbsUp,
  FaRegThumbsDown,
  FaRegStar,
} from 'react-icons/fa';
import {
  BsFillCheckCircleFill,
  BsFacebook,
  BsFillFlagFill,
  BsInstagram,
} from 'react-icons/bs';
import {
  MdOutlineDangerous,
  MdOutlineEmail,
  MdOutlineLiquor,
} from 'react-icons/md';
import { TbBuildingBank } from 'react-icons/tb';
import { RiBankFill, RiLightbulbFlashLine } from 'react-icons/ri';
import { BiTimeFive } from 'react-icons/bi';
import { IoLocationSharp } from 'react-icons/io5';
import { FiMap, FiPhone } from 'react-icons/fi';
import { BiCube } from 'react-icons/bi';
import { SiMicrosoftbing } from 'react-icons/si';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { BaseURL, imageUrl } from '../../config/apiUrl';
import { Get, Post } from '../../Axios/AxiosFunctions';
import HeroSection from '../../Component/HeroSection/HeroSection';
import Loader from '../../Component/Loader';
import Maps from '../../Component/MapAndPlaces';
import classes from './PreviewListing.module.css';

const PreviewListing = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  const [data, setData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [businessAddress, setBusinessAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const { access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state?.authReducer,
  );

  const fetchData = async () => {
    const url = BaseURL(`business/general/${slug}`);

    setLoading(true);
    const response = await Get(url, accessToken, companyId);
    if (response !== undefined) {
      setData(response?.data?.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data?.location?.type) {
      setLocations([data?.location]);
    } else {
      setLocations(data?.location);
    }
    console.log(data?.location, data?.businessAddress);

    if (typeof data?.businessAddress == 'string') {
      setBusinessAddress([data?.businessAddress]);
    } else {
      setBusinessAddress(data?.businessAddress);
    }
  }, [data, setLocations, setBusinessAddress]);
  let statsData = [];

  data?.businessOpportunity &&
    statsData.push({
      icon: <FaDollarSign />,
      name: `$${data?.businessOpportunity?.toLocaleString()}`,
      subTitle: 'Business Opportunity',
    });

  data?.cashFlow &&
    statsData.push({
      icon: <FaMoneyCheckAlt />,
      name: `$${data?.cashFlow?.toLocaleString()}`,
      subTitle: 'Cash Flow',
    });

  data?.ebitda &&
    statsData.push({
      icon: <FaDollarSign />,
      name: `$${data?.ebitda?.toLocaleString()}`,
      subTitle: 'EBITDA',
    });

  data?.inventory &&
    statsData.push({
      icon: <BiCube />,
      name: `$${data?.inventory?.toLocaleString()}`,
      subTitle: 'Inventory',
    });

  data?.grossSales &&
    statsData.push({
      icon: <FaRegMoneyBillAlt />,
      name: `$${data?.grossSales?.toLocaleString()}`,
      subTitle: 'Sales Revenue',
    });

  if (data?.amountType == 'rent') {
    data?.monthlyRent &&
      statsData.push({
        icon: <FaDollarSign />,
        name: `$${data?.monthlyRent?.toLocaleString()}`,
        subTitle: 'Monthly Rent',
      });
  } else {
    if (data?.amountType == 'location') {
      statsData.push({
        icon: <FaRegBuilding />,
        name: `Location`,
        subTitle: data?.buildingSF,
      });
    } else if (data?.inventory == 0 && data?.realEstate == 0) {
      statsData.push({
        icon: <FaRegBuilding />,
        name: `Home Based`,
        subTitle: 'Relocatable',
      });
    } else if (data?.realEstate) {
      statsData.push({
        icon: <FaRegBuilding />,
        name: `$${data?.realEstate?.toLocaleString()}`,
        subTitle: 'Real Estate',
      });
    }
  }

  (data?.fullTimeEmployees || data?.partTimeEmployees) &&
    statsData.push({
      icon: <FaUsers />,
      name: `${data?.fullTimeEmployees}FT/${data?.partTimeEmployees}PT`,
      subTitle: 'Employees',
    });

  const length = statsData?.length;
  let topNumbers = Math.ceil(length / 2);
  if (topNumbers == 1) topNumbers = 2;
  const restNumbers = length - topNumbers;

  const ReturnFormatedNumber = (number) => {
    let newNumber = number?.slice(2);
    newNumber = newNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3');
    return newNumber;
  };

  const getUrl = (item) => {
    return imageUrl(item);
  };

  return (
    <div className={classes.page}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeroSection
            containerClass={[classes.mainContainer]}
            defaultContainer
            customClass={classes.HeroSectionBgImage}
          >
            <div className={[classes.heroContainer]}>
              <h1 className={[classes.heroHeader]}>{data?.companyName}</h1>
              {businessAddress?.map((item) => (
                <h6 className={[classes.heroSubHeader]}>{item}</h6>
              ))}
            </div>
          </HeroSection>
          {/* Hero Section */}

          {(data?.description || data?.dummyDescription) && (
            <Container className={[classes.mainContainer]}>
              {/* description */}
              <div className={[classes.contentSection]}>
                <h3 className={[classes.title]}>Business Description</h3>
                <p className={[classes.description]}>
                  {data?.description || data?.dummyDescription}
                </p>
              </div>
            </Container>
          )}

          {/* stats */}
          {statsData?.length > 0 && (
            <div className={[classes.contentSection]}>
              <h3 className={[classes.title]}>Statistics</h3>
              <HeroSection
                // containerClass={[classes.mainContainer]}
                defaultContainer
                customClass={classes.StatsBgImage}
              >
                <div className={[classes.heroContainer]}>
                  <Row>
                    {statsData?.slice(0, topNumbers)?.map((item, i) => {
                      return (
                        <>
                          {topNumbers < 3 &&
                            topNumbers - restNumbers > 0 &&
                            i == 0 && (
                              <Col className={[classes.statsEmptyCol]} />
                            )}
                          <Col xs={3} sm={2} className={[classes.mainStatsDiv]}>
                            <CircleIconWithTitle
                              className={[classes.statsContainer]}
                              data={item}
                            />
                          </Col>
                          {!(
                            topNumbers > 2 &&
                            topNumbers - restNumbers == 1 &&
                            i == topNumbers - 1
                          ) && <Col className={[classes.statsEmptyCol]} />}
                        </>
                      );
                    })}
                  </Row>
                  <Row>
                    {statsData?.slice(topNumbers)?.map((item, i) => {
                      return (
                        <>
                          <Col className={[classes.statsEmptyCol]} />
                          <Col xs={3} sm={2} className={[classes.mainStatsDiv]}>
                            <CircleIconWithTitle
                              className={[classes.statsContainer]}
                              data={item}
                            />
                          </Col>
                          {i == restNumbers - 1 &&
                            !(topNumbers == 3 && restNumbers == 3) &&
                            !(topNumbers == 2 && restNumbers == 2) && (
                              <Col className={[classes.statsEmptyCol]} />
                            )}
                        </>
                      );
                    })}
                  </Row>
                </div>
              </HeroSection>
            </div>
          )}

          {/* Business Photo Gallery */}
          {data?.images?.length > 0 && (
            <section className={classes.businessGallerySection}>
              <h3>Business Photo Gallery</h3>
              <div className={classes.carousel}>
                <style>{`
                    .swiper-button-next,.swiper-button-prev { color: #eeeeeee6; }
                    .swiper-button-next:after,.swiper-button-prev:after {font-size:24px;font-weight:600 }
                    .swiper-pagination-bullet-active{background:var(--main-color);}
                    .swiper-pagination{position:static !important;}
                    `}</style>

                {
                  <Swiper
                    watchSlidesProgress={true}
                    slidesPerView={3}
                    className="mySwiper"
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Navigation, Pagination]}
                    navigation={true}
                  >
                    {data?.images?.map((item) => (
                      <SwiperSlide>
                        <div className={classes.imgBox}>
                          <img src={getUrl(item)} alt="..." />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                }
              </div>
            </section>
          )}

          {/* Business Photo Gallery */}

          {/* Business Highlights */}
          {data?.businessHighlights?.length > 0 && (
            <HeroSection
              defaultContainer
              customClass={classes.businessHighlightsSection}
              containerClass={[
                classes.mainContainer,
                classes.businessHighlightsMainDiv,
              ].join(' ')}
            >
              <Row className="gx-0">
                <Col md={12}>
                  <h3>Business Highlights</h3>
                </Col>
                {data?.businessHighlights?.map((item, i) => (
                  <Col md={6} key={i}>
                    <div className={classes.iconWithTitle}>
                      <BsFillCheckCircleFill /> <p>{item}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </HeroSection>
          )}

          {/* Business Highlights */}
          {/* Business Location */}
          {data?.businessAddress?.length > 0 && locations?.length > 0 && (
            <section className={classes.locationSection}>
              <h3>Business Location{locations?.length > 0 && 's'}</h3>
              <h6 className={classes.mapTitle}>{data?.companyName}</h6>
              {locations?.map((item, index) => (
                <div className={classes.mapsContiner}>
                  <div className={classes.addressContainer}>
                    <FaMapMarker />
                    <div>
                      <b>{data?.businessAddress[index]}</b>
                    </div>
                  </div>
                  <Maps
                    key={index}
                    location={{
                      lng: item?.coordinates[0] ?? -105,
                      lat: item?.coordinates[1] ?? 39,
                    }}
                    className={classes.mapContainer}
                    label={data?.title}
                  />
                </div>
              ))}
            </section>
          )}
          {/* Business Location */}

          {/* Online Presence */}
          {data?.thirdPartyPresence && data?.thirdPartyPresence?.length > 0 && (
            <div className={classes.onlinePresence}>
              <h3>Online Presence</h3>
              <HeroSection
                defaultContainer
                customClass={classes.onlinePresenceSection}
                containerClass={[classes.mainContainer]}
              >
                <div
                  className={` ${
                    data?.thirdPartyPresence?.length < 3
                      ? classes.socailCard1
                      : classes.socailCard
                  }`}
                >
                  {data?.thirdPartyPresence?.map((item, i) => {
                    let data = demoData?.onlinePresence?.find(
                      (innerItem) => innerItem?.key === item?.key,
                    );
                    return (
                      <div className={classes.jCenter} key={i}>
                        <CircleIconWithTitle data={data} link={item?.link} />
                      </div>
                    );
                  })}
                </div>
              </HeroSection>
            </div>
          )}
          {/* Online Presence */}

          {/* Finanacials */}

          {((data?.financialsCSV1?.length && data?.financialsCSV2?.length) ||
            data?.financialsCSVImages?.length > 0) && (
            <div className={classes.finanacials}>
              <h3>Financials</h3>
              <Container className={classes.mainContainer}>
                <Row>
                  <Col lg={12}>{data?.financialsDescription}</Col>
                  {data?.financialsCSV1?.length > 0 &&
                  data?.financialsCSV2?.length > 0 ? (
                    <Col lg={12}>
                      <div className={classes.csvTable}>
                        <table>
                          <thead>
                            <tr>
                              <th className={classes.column1}>
                                {data?.financialsCSV1[0]?.column1}
                              </th>
                              <th className={classes.column2}>
                                {data?.financialsCSV1[0]?.column2}
                              </th>
                              <th className={classes.column3}>
                                {data?.financialsCSV1[0]?.column3}
                              </th>
                              <th className={classes.column4}>
                                {data?.financialsCSV1[0]?.column4}
                              </th>
                              <th className={classes.column5}>
                                {data?.financialsCSV1[0]?.column5}
                              </th>
                              <th className={classes.column6}>
                                {data?.financialsCSV1[0]?.column6}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.financialsCSV1?.slice(1)?.map((item) => {
                              return (
                                <tr>
                                  <td className={classes.column1}>
                                    {item?.column1}
                                  </td>
                                  <td className={classes.column2}>
                                    {item?.column2}
                                  </td>
                                  <td className={classes.column3}>
                                    {item?.column3}
                                  </td>
                                  <td className={classes.column4}>
                                    {item?.column4}
                                  </td>
                                  <td className={classes.column5}>
                                    {item?.column5}
                                  </td>
                                  <td className={classes.column6}>
                                    {item?.column6}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {/* {/ CSV 2 /} */}
                      <div className={[classes.csvTable].join(' ')}>
                        <table>
                          <thead>
                            <tr>
                              <th className={classes.column1}>
                                {data?.financialsCSV2[0]?.column1}
                              </th>
                              <th className={classes.column2}>
                                {data?.financialsCSV2[0]?.column2}
                              </th>
                              <th className={classes.column3}>
                                {data?.financialsCSV2[0]?.column3}
                              </th>
                              <th className={classes.column4}>
                                {data?.financialsCSV2[0]?.column4}
                              </th>
                              <th className={classes.column5}>
                                {data?.financialsCSV2[0]?.column5}
                              </th>
                              <th className={classes.column6}>
                                {data?.financialsCSV2[0]?.column6}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.financialsCSV2?.slice(1)?.map((item) => {
                              return (
                                <tr>
                                  <td className={classes.column1}>
                                    {item?.column1}
                                  </td>
                                  <td className={classes.column2}>
                                    {item?.column2}
                                  </td>
                                  <td className={classes.column3}>
                                    {item?.column3}
                                  </td>
                                  <td className={classes.column4}>
                                    {item?.column4}
                                  </td>
                                  <td className={classes.column5}>
                                    {item?.column5}
                                  </td>
                                  <td className={classes.column6}>
                                    {item?.column6}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  ) : (
                    <Col md={12}>
                      <div
                        className={
                          data?.financialsCSVImages?.length > 2
                            ? classes.finanacialsImages
                            : classes.finanacialsSingleImages
                        }
                      >
                        {data?.financialsCSVImages?.map((item) => (
                          <a
                            className={classes.imgDiv}
                            href={getUrl(item)}
                            target={'_blank'}
                          >
                            <img src={getUrl(item)} />
                          </a>
                        ))}
                      </div>
                    </Col>
                  )}
                </Row>
              </Container>
            </div>
          )}
          {/* Finanacials */}

          {/* Finanacials Analysis */}
          {data?.financialsAnalysis?.length > 0 && (
            <section className={classes.finanacialsAnalysis}>
              <Container className={classes.mainContainer}>
                <h3>{data?.financialTitle || 'Finanacials Analysis'}</h3>
                <div className={classes.finanacialsImages}>
                  {data?.financialsAnalysis?.map((item, i) => {
                    return (
                      <a
                        className={classes.imgDiv}
                        href={getUrl(item)}
                        target={'_blank'}
                      >
                        <img src={getUrl(item)} alt="..." />
                      </a>
                    );
                  })}
                </div>
              </Container>
            </section>
          )}
          {/* Finanacials Analysis */}

          {/* Pros and cons */}
          {(data?.pros?.length > 0 || data?.cons?.length > 0) && (
            <HeroSection
              // containerClass={[classes.mainContainer]}
              defaultContainer
              customClass={`${classes.ProsConsBgImage}`}
            >
              <Row className={`gy-3`} style={{ padding: '50px' }}>
                <Col md={6} className={classes.consContainer}>
                  <div
                    className={classes.elementCell}
                    style={{ backgroundColor: '#8ED973' }}
                  >
                    <div className={classes.elementTitle}>
                      <FaRegThumbsUp />
                      PROS
                    </div>
                    <div
                      className={classes.elementBody}
                      style={{ backgroundColor: '#DDEBCD' }}
                    >
                      {data?.pros?.map((item) => (
                        <div className={classes.elementItem}>
                          <FaCheck size={10} />
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                    <div className={classes.elementFooter}></div>
                  </div>
                </Col>
                <Col md={6} className={classes.consContainer}>
                  <div
                    className={classes.elementCell}
                    style={{ backgroundColor: '#FF7C80' }}
                  >
                    <div className={classes.elementTitle}>
                      <FaRegThumbsDown />
                      CONS
                    </div>
                    <div
                      className={classes.elementBody}
                      style={{ backgroundColor: '#EDC0BD' }}
                    >
                      {data?.cons?.map((item) => (
                        <div className={classes.elementItem}>
                          <FaCheck size={10} />
                          <div>{item}</div>
                        </div>
                      ))}
                    </div>
                    <div className={classes.elementFooter}></div>
                  </div>
                </Col>
              </Row>
            </HeroSection>
          )}
          {/* Pros and cons */}

          {/* Property Information */}
          {((data?.amountType == 'location' && data?.businessLocation) ||
            (data?.amountType == 'real-estate' && data?.realEstatePrice) ||
            (data?.amountType == 'rent' &&
              (data?.leaseRate || data?.leaseInformation)) ||
            data?.demographics) && (
            <section className={classes.propertyInfoMain}>
              <div className={classes.header}>
                <Container className={classes.mainContainer}>
                  <div className={classes.propertyInfoContent}>
                    <h3>Property Information</h3>
                    {data?.amountType == 'location' &&
                      data?.businessLocation && (
                        <Row
                          style={{ alignItems: 'center', marginTop: '15px' }}
                        >
                          <Col xl={12} lg={12} sm={12}>
                            <span
                              style={{
                                display: 'block',
                                fontSize: '30px',
                                fontWeight: 700,
                                fontFamily: 'Bebas-Neue-bold',
                                color: '#02528a',
                                textAlign: 'left',
                              }}
                            >
                              <span>Location Information</span>{' '}
                              <span
                                style={{
                                  position: 'relative',
                                  top: '-5px',
                                  marginLeft: '30px',
                                  fontFamily: 'Open-Sans-regular',
                                  fontSize: '15px',
                                  color: 'black',
                                }}
                              >
                                {data?.businessLocation}
                              </span>
                            </span>
                          </Col>
                        </Row>
                      )}
                    {data?.amountType == 'real-estate' && (
                      <>
                        {data?.realEstatePrice && (
                          <Row
                            style={{ alignItems: 'center', marginTop: '15px' }}
                          >
                            <Col xl={12} lg={12} sm={12}>
                              <span
                                style={{
                                  display: 'block',
                                  fontSize: '30px',
                                  fontWeight: 700,
                                  fontFamily: 'Bebas-Neue-bold',
                                  color: '#02528a',
                                  textAlign: 'left',
                                }}
                              >
                                <span>Property Price</span>{' '}
                                <span
                                  style={{
                                    position: 'relative',
                                    top: '-5px',
                                    marginLeft: '30px',
                                    fontFamily: 'Open-Sans-regular',
                                    fontSize: '15px',
                                    color: 'black',
                                  }}
                                >
                                  {data?.realEstatePrice}
                                </span>
                              </span>
                            </Col>
                          </Row>
                        )}
                        {data?.realEstateInformation && (
                          <Row
                            style={{ alignItems: 'center', marginTop: '15px' }}
                          >
                            <Col xl={12} lg={12} sm={12}>
                              <span
                                style={{
                                  display: 'block',
                                  fontSize: '30px',
                                  fontWeight: 700,
                                  fontFamily: 'Bebas-Neue-bold',
                                  color: '#02528a',
                                  textAlign: 'left',
                                }}
                              >
                                <span>Property Information</span>{' '}
                                <span
                                  style={{
                                    position: 'relative',
                                    top: '-5px',
                                    marginLeft: '30px',
                                    fontFamily: 'Open-Sans-regular',
                                    fontSize: '15px',
                                    color: 'black',
                                  }}
                                >
                                  {data?.realEstateInformation}
                                </span>
                              </span>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                    {data?.amountType == 'rent' && (
                      <>
                        {data?.leaseRate && (
                          <Row
                            style={{ alignItems: 'center', marginTop: '15px' }}
                          >
                            <Col xl={12} lg={12} sm={12}>
                              <span
                                style={{
                                  display: 'block',
                                  fontSize: '30px',
                                  fontWeight: 700,
                                  fontFamily: 'Bebas-Neue-bold',
                                  color: '#02528a',
                                  textAlign: 'left',
                                }}
                              >
                                <span>Lease Rate</span>{' '}
                                <span
                                  style={{
                                    position: 'relative',
                                    top: '-5px',
                                    marginLeft: '30px',
                                    fontFamily: 'Open-Sans-regular',
                                    fontSize: '15px',
                                    color: 'black',
                                  }}
                                >
                                  {data?.leaseRate}
                                </span>
                              </span>
                            </Col>
                          </Row>
                        )}
                        {data?.leaseInformation && (
                          <Row
                            style={{ alignItems: 'center', marginTop: '15px' }}
                          >
                            <Col xl={12} lg={12} sm={12}>
                              <span
                                style={{
                                  display: 'block',
                                  fontSize: '30px',
                                  fontWeight: 700,
                                  fontFamily: 'Bebas-Neue-bold',
                                  color: '#02528a',
                                  textAlign: 'left',
                                }}
                              >
                                <span>Lease Information</span>{' '}
                                <span
                                  style={{
                                    position: 'relative',
                                    top: '-5px',
                                    marginLeft: '30px',
                                    fontFamily: 'Open-Sans-regular',
                                    fontSize: '15px',
                                    color: 'black',
                                  }}
                                >
                                  {data?.leaseInformation}
                                </span>
                              </span>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                    <span></span>
                  </div>
                </Container>
              </div>
              {data?.demographics && (
                <div className={classes.imagesBoxes}>
                  <Container className={classes.mainContainer}>
                    <h3>
                      {data?.demographicTitle || 'Demographics for Colorado'}
                    </h3>
                    <div className={classes.demographics}>
                      {data?.demographics}
                    </div>
                  </Container>
                </div>
              )}

              {/* SWOT Analysis */}
              {data?.strengths?.length ||
              data?.weaknesses?.length ||
              data?.opportunities?.length ||
              data?.threats?.length ? (
                <Container className={classes.mainContainer}>
                  <h3>{'SWOT Analysis'}</h3>
                  <Row className={`${classes.swotBody} gx-3 gy-3`}>
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      className={classes.elementContainer}
                    >
                      <div
                        className={classes.elementCell}
                        style={{ backgroundColor: '#8ED973' }}
                      >
                        <div className={classes.elementTitle}>
                          <FaRegThumbsUp />
                          Strengths
                        </div>
                        <div
                          className={classes.elementBody}
                          style={{ backgroundColor: '#DDEBCD' }}
                        >
                          {data?.strengths?.map((item) => (
                            <div className={classes.elementItem}>
                              <FaCheck size={10} />
                              <div>{item}</div>
                            </div>
                          ))}
                        </div>
                        <div className={classes.elementFooter}></div>
                      </div>
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      className={classes.elementContainer}
                    >
                      <div
                        className={classes.elementCell}
                        style={{ backgroundColor: '#FF7C80' }}
                      >
                        <div className={classes.elementTitle}>
                          <FaRegThumbsDown />
                          Weaknesses
                        </div>
                        <div
                          className={classes.elementBody}
                          style={{ backgroundColor: '#EDC0BD' }}
                        >
                          {data?.weaknesses?.map((item) => (
                            <div className={classes.elementItem}>
                              <FaCheck size={10} />
                              <div>{item}</div>
                            </div>
                          ))}
                        </div>
                        <div className={classes.elementFooter}></div>
                      </div>
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      className={classes.elementContainer}
                    >
                      <div
                        className={classes.elementCell}
                        style={{ backgroundColor: '#F9C7AC' }}
                      >
                        <div className={classes.elementTitle}>
                          <FaRegStar />
                          Opportunities
                        </div>
                        <div
                          className={classes.elementBody}
                          style={{ backgroundColor: '#F5DED1' }}
                        >
                          {data?.opportunities?.map((item) => (
                            <div className={classes.elementItem}>
                              <FaCheck size={10} />
                              <div>{item}</div>
                            </div>
                          ))}
                        </div>
                        <div className={classes.elementFooter}></div>
                      </div>
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      sm={12}
                      className={classes.elementContainer}
                    >
                      <div
                        className={classes.elementCell}
                        style={{ backgroundColor: '#8ED973' }}
                      >
                        <div className={classes.elementTitle}>
                          <MdOutlineDangerous />
                          Threats
                        </div>
                        <div
                          className={classes.elementBody}
                          style={{ backgroundColor: '#DDEBCD' }}
                        >
                          {data?.threats?.map((item) => (
                            <div className={classes.elementItem}>
                              <FaCheck size={10} />
                              <div>{item}</div>
                            </div>
                          ))}
                        </div>
                        <div className={classes.elementFooter}></div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <></>
              )}
            </section>
          )}
          {/* Property Information */}

          {/* Hours of Operation to Financing Options */}
          {
            <div className={classes.propertyInfoSection}>
              {(data?.hoursOfOperation?.length > 0 ||
                data?.hoursOfOperationOpportunity) && (
                <div className={classes.bgDivGreyColor}>
                  <Container className={[classes.mainContainer]}>
                    <IconWithTitleAndDescription
                      data={{
                        icon: <BiTimeFive />,
                        title: 'HOURS OF OPERATION',
                      }}
                    >
                      <Row className={classes.hrsOfOperation}>
                        {data?.hoursOfOperation?.map((item) => {
                          return (
                            <>
                              <Col xs={3}>
                                <p>{item?.days}</p>
                              </Col>
                              <Col xs={9}>
                                <p>{item?.hours}</p>
                              </Col>
                            </>
                          );
                        })}
                        {data?.hoursOfOperationOpportunity && (
                          <>
                            <Col md={3}>
                              <p>Opportunity</p>
                            </Col>
                            <Col md={9}>
                              <p>{data?.hoursOfOperationOpportunity}</p>
                            </Col>
                          </>
                        )}
                      </Row>
                    </IconWithTitleAndDescription>
                  </Container>
                </div>
              )}
              {/* Owner Involvement */}
              {data?.ownerInvolvment && (
                <div className={classes.bgDivwhiteColor}>
                  <Container className={[classes.mainContainer]}>
                    <IconWithTitleAndDescription
                      data={{
                        icon: <FaUserTie />,
                        title: 'OWNER INVOLVEMENT',
                      }}
                    >
                      <Row className={classes.hrsOfOperation}>
                        <Col md={12}>
                          <p>{data?.ownerInvolvment}</p>
                        </Col>
                      </Row>
                    </IconWithTitleAndDescription>
                  </Container>
                </div>
              )}
              {/* No Of Employees */}
              {data?.fullTimeEmployees ||
              data?.partTimeEmployees ||
              data?.employeesDescription ? (
                <div className={classes.bgDivGreyColor}>
                  <Container className={[classes.mainContainer]}>
                    <IconWithTitleAndDescription
                      data={{
                        title: 'NUMBER OF EMPLOYEES',
                        icon: <FaUsers />,
                      }}
                    >
                      <Row className={`${classes.hrsOfOperation} gy-3 gx-5`}>
                        <Col md={4}>
                          {!(
                            data?.fullTimeEmployees == 0 ||
                            data?.partTimeEmployees == 0
                          ) && (
                            <div className={classes.employeeNumberBody}>
                              <p>Total Employees</p>
                              <p>
                                {data?.fullTimeEmployees +
                                  data?.partTimeEmployees}
                              </p>
                            </div>
                          )}
                          {data?.fullTimeEmployees !== 0 && (
                            <div className={classes.employeeNumberBody}>
                              <p>Full Time</p>
                              <p>{data?.fullTimeEmployees}</p>
                            </div>
                          )}
                          {data?.partTimeEmployees !== 0 && (
                            <div className={classes.employeeNumberBody}>
                              <p>Part Time</p>
                              <p>{data?.partTimeEmployees}</p>
                            </div>
                          )}
                        </Col>
                        <Col className={classes.disclaimer}>
                          {data?.employeesDescription}
                        </Col>
                      </Row>
                    </IconWithTitleAndDescription>
                  </Container>
                </div>
              ) : (
                <></>
              )}
              {/* Reason For Selling */}
              {data?.reason && (
                <div className={classes.bgDivwhiteColor}>
                  <Container className={[classes.mainContainer]}>
                    <IconWithTitleAndDescription
                      data={{
                        icon: <BsFillFlagFill />,
                        title: 'REASON FOR SELLING',
                      }}
                    >
                      <Row className={classes.hrsOfOperation}>
                        <Col md={12}>
                          <p>{data?.reason}</p>
                        </Col>
                      </Row>
                    </IconWithTitleAndDescription>
                  </Container>
                </div>
              )}
              {/* Recent Improvements */}
              {data?.recentImprovements?.length > 0 && (
                <div className={classes.bgDivGreyColor}>
                  <Container className={[classes.mainContainer]}>
                    <IconWithTitleAndDescription
                      data={{
                        icon: <RiLightbulbFlashLine />,
                        title: 'RECENT IMPROVEMENTS',
                      }}
                      className={classes.recentImprIcon}
                    >
                      <Row className={classes.hrsOfOperation}>
                        {data?.recentImprovements?.map((item) => (
                          <Col sm={6} lg={3} className={classes.recentImprCol}>
                            <div className={classes.yearBox}>{item?.year}</div>
                            <ul>
                              {item?.features?.map((e, i) => (
                                <li key={i}>
                                  <FaCheck />
                                  <p>{e}</p>
                                </li>
                              ))}
                            </ul>
                          </Col>
                        ))}
                      </Row>
                    </IconWithTitleAndDescription>
                  </Container>
                </div>
              )}
            </div>
          }
          {/* Hours of Operation to Financing Options */}

          {/* Financing Options */}
          {data?.financingOptions?.length > 0 && (
            <div className={classes.financing}>
              <h3>Financing Options</h3>
              <HeroSection
                defaultContainer
                customClass={classes.financingOptionsSection}
              >
                <div
                  className={` ${
                    data?.financingOptions?.length < 3
                      ? classes.socailCard1
                      : classes.socailCard
                  }`}
                >
                  {data?.financingOptions?.map((ite) => {
                    return (
                      <div className={classes.jCenter}>
                        <CircleIconWithTitle
                          data={{
                            name: ite,
                            icon:
                              ite == 'SBA' ? (
                                <TbBuildingBank />
                              ) : ite == 'Cash' ? (
                                <FaDollarSign />
                              ) : ite == 'Conventional' ? (
                                <RiBankFill />
                              ) : (
                                <FaUserTie />
                              ),
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </HeroSection>
            </div>
          )}

          {/* Financing Options */}
          {/* Contact Us */}
          {data?.broker?.length > 0 && (
            <section className={classes.contactUs}>
              <Container className={classes.mainContainer}>
                <h3>Contact Us</h3>
                <div className={classes.details}>
                  <div className={classes.imgDiv}>
                    <img src={getUrl(data?.broker[0]?.photo)} />
                  </div>
                  <div className={classes.right}>
                    <div>
                      <h5>
                        {data?.broker[0]?.firstName} {data?.broker[0]?.lastName}{' '}
                        |{data?.broker[0]?.designation}
                      </h5>
                      <p>{data?.broker[0]?.description}</p>
                    </div>
                    <Row className={classes.contactInfo}>
                      <Col md={6} lg={4}>
                        <a
                          href={`tel:${data?.broker[0]?.contact}`}
                          className={classes.iconWithValue}
                        >
                          <FiPhone />
                          <p>{`Office : ${ReturnFormatedNumber(
                            data?.broker[0]?.contact,
                          )}`}</p>
                        </a>
                      </Col>
                      <Col md={6} lg={4}>
                        {data?.broker[0]?.deskContact && (
                          <a
                            href={`tel:${data?.broker[0]?.deskContact}`}
                            className={classes.iconWithValue}
                          >
                            <FiPhone />
                            <p>{`Desk : ${ReturnFormatedNumber(
                              data?.broker[0]?.deskContact,
                            )}`}</p>
                          </a>
                        )}
                      </Col>
                      <Col md={6} lg={4}>
                        {data?.broker[0]?.cell && (
                          <a
                            href={`tel:${data?.broker[0]?.cell}`}
                            className={classes.iconWithValue}
                          >
                            <FiPhone />
                            <p>{`Cell Phone : ${ReturnFormatedNumber(
                              data?.broker[0]?.cell,
                            )}`}</p>
                          </a>
                        )}
                      </Col>
                      <Col md={6} lg={4}>
                        <a
                          href={`mailto:${data?.broker[0]?.email}`}
                          className={classes.iconWithValue}
                        >
                          <MdOutlineEmail />
                          <p>{`${data?.broker[0]?.email}`}</p>
                        </a>
                      </Col>
                      <Col md={6} lg={4}>
                        {data?.broker[0]?.meetingLink && (
                          <a
                            href={`${data?.broker[0]?.meetingLink}`}
                            className={classes.iconWithValue}
                            target="_blank"
                          >
                            <FaRegHandshake />
                            <p>Schedule a Meeting</p>
                          </a>
                        )}
                      </Col>
                      <Col md={12}>
                        <i className={classes.disclaimer}>
                          The information provided in this listing has been
                          furnished by the seller or their representative.
                          Business Brokerage Services has not independently
                          verified any of the information and assumes no
                          responsibility for its accuracy or completeness.
                          Buyers are advised to conduct their own due diligence
                          and verify all details before proceeding with any
                          transaction.
                        </i>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </section>
          )}
          {/* Contact Us */}
        </>
      )}
    </div>
  );
};

export default PreviewListing;

function CircleIconWithTitle({ data, link, className }) {
  return (
    <div
      className={[classes.circleIconWithTitle, className && className].join(
        ' ',
      )}
    >
      <div
        onClick={() => {
          if (link) window.open(link);
        }}
      >
        {data?.icon}
      </div>
      <p>{data?.name}</p>
      {data?.subTitle && <span>{data?.subTitle}</span>}
    </div>
  );
}

function IconWithTitleAndDescription({ data, children, className }) {
  return (
    <Row className={[classes.propertyInfoBox, className && className]}>
      <Col md={12}>
        <h3>{data?.title}</h3>
      </Col>
      <Col md={3}>
        <div className={classes.iconDiv}>
          <CircleIconWithTitle
            data={{ icon: data?.icon }}
            className={classes.iconBox}
          />
        </div>
      </Col>
      <Col md={9}>{children}</Col>
      <div className={classes.btmSeparator} />
    </Row>
  );
}
// do not remove this object
const demoData = {
  financingOptions: [
    { name: 'Conventional', key: 'conventional', icon: <RiBankFill /> },
    { name: 'SBA', key: 'sba', icon: <TbBuildingBank /> },
    { name: 'Cash', key: 'cash', icon: <FaDollarSign /> },
  ],
  onlinePresence: [
    { name: 'Google Map', key: 'google-map', icon: <IoLocationSharp /> },
    { name: 'Facebook', key: 'facebook', icon: <BsFacebook /> },
    { name: 'Yelp', key: 'yelp', icon: <FaYelp /> },
    { name: 'MapQuest', key: 'map-quest', icon: <FiMap /> },
    { name: 'Website', key: 'website', icon: <FaInternetExplorer /> },
    { name: 'Instagram', key: 'instagram', icon: <BsInstagram /> },
    { name: 'Drizzly', key: 'drizzly', icon: <MdOutlineLiquor /> },
    {
      name: 'Bing-Location',
      key: 'bing-location',
      icon: <SiMicrosoftbing />,
    },
  ],
};
