import { useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import Loader from '../../../Component/Loader';
import classes from './PdfViewer.module.css';
import { Box, Slider, Stack } from '@mui/material';
import { MdOutlineZoomIn, MdOutlineZoomOut } from 'react-icons/md';
import NoData from '../../../Component/NoData/NoData';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfViewer = ({ pdfUrl, pageNumber = 0, sharedScale, setSharedScale }) => {
  const [numPages, setNumPages] = useState();
  const [scale, setScale] = useState(sharedScale || 40);

  useEffect(() => {
    setSharedScale && setSharedScale(scale);
  }, [scale]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className={classes.pdfViewer}>
      <div className={classes.pdfContainer}>
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<Loader />}
          noData={<NoData />}
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={scale / 40}
          />
        </Document>
      </div>
      <div className={classes.footer}>
        <Box sx={{ width: 300 }}>
          <Stack
            spacing={2}
            direction="row"
            sx={{ alignItems: 'center', mb: 1 }}
          >
            <MdOutlineZoomOut className={classes.icon} size={40} />
            <Slider
              aria-label="Volume"
              value={scale}
              onChange={(e, v) => setScale(v)}
            />
            <MdOutlineZoomIn className={classes.icon} size={40} />
          </Stack>
        </Box>
        <div>
          Page {pageNumber} of {numPages}
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
