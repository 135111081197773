import React, { useEffect } from 'react';
import classes from './AddTaskModal.module.css';
import ModalSkeleton from '../ModalSkeleton';
import { Input } from '../../Component/Input/Input';
import { TextArea } from '../../Component/TextArea';
import { Button } from '../../Component/Button/Button';
import { useState } from 'react';
import { DropDown } from '../../Component/DropDown/DropDown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from '../../utils/timeUtils';
import { Col, Row } from 'react-bootstrap';
import { enUS } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/en';
import TimePicker from 'react-bootstrap-time-picker';

const AddTaskModal = ({
  show,
  setShow,
  handleSubmit,
  type = 'task',
  data,
  optionsArrayStages = [],
  isLoading = false,
  allAdmins,
  isShowProjectDropdown = false,
  setProjectData,
  projectData,
  allCompanies,
  loadingProjects,
}) => {
  const [taskName, setTaskName] = useState(data?.title || '');
  const [assignTo, setAssignTo] = useState([]);
  const [description, setDescription] = useState(data?.description || '');
  const [status, setStatus] = useState('');
  const [noOfDays, setNoOfDays] = useState(data?.noOfDays || '');
  const [deadlineDate, setDeadlineDate] = useState(
    dayjs(data?.deadlineDate || dayjs()),
  );

  const [milliseconds, setMilliseconds] = useState(
    ((deadlineDate) => {
      // debugger;
      const time = deadlineDate ? dayjs(deadlineDate) : dayjs().add(1, 'hour');
      const m = time.get('minute');
      const h = time.get('hour');
      return Math.floor((h * 3600 + m * 60) / 1800) * 1800;
    })(data?.isAdd ? null : dayjs(data?.deadlineDate)),
  );

  useEffect(() => {
    setDeadlineDate(
      deadlineDate
        .set('hour', Math.floor(milliseconds / 3600))
        .set('minute', Math.floor((milliseconds % 3600) / 60)),
    );
  }, [milliseconds]);

  const HandleSubmitData = () => {
    const newAssignToArray =
      assignTo !== '' &&
      assignTo?.map((item, index) => {
        return item?._id;
      });
    if (type == 'task') {
      handleSubmit({
        title: taskName,
        assignedTo: newAssignToArray,
        description,
        type,
        deadlineDate,
        ...(data?.isAdd && { stageId: status?._id }),
      });
    } else {
      handleSubmit({
        title: taskName,
        assignedTo: newAssignToArray,
        description,
        type,
        ...(type == 'template' && { noOfDays: Number(noOfDays) }),
      });
    }
  };

  useEffect(() => {
    if (data?.assignedTo) {
      setAssignTo(data?.assignedTo);
    }
  }, [data]);

  const initialStatus = () => {
    const statusData = optionsArrayStages?.find(
      (item) => item?.name === 'To Do',
    );

    if (statusData) {
      setStatus(statusData);
    }
  };

  useEffect(() => {
    if (optionsArrayStages?.length && !status && data?.isAdd) {
      initialStatus();
    }
  }, [status, optionsArrayStages, data?.isAdd]);

  return (
    <>
      <style>{`
      .MuiFormControl-root{
        width: 100%;
      }
      .MuiFormLabel-root {
        color: var(--placeholder-color) !important;
      }
      .MuiOutlinedInput-notchedOutline {
        box-shadow: 0px 0 5px 2px #0000000d;
        border-width: 0;
        border: none;
        border-radius: 10px;
        box-shadow: 0px 0 5px 2px #0000000d;
      }
      `}</style>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        borderRadius="20px"
        width="734px"
        borderLine={false}
        header={data?.isAdd ? 'Add Task' : 'Edit Task'}
        showCloseIcon={true}
      >
        <div className={classes.add_task_main}>
          {!isShowProjectDropdown && (
            <div className={classes.input_main}>
              <Input
                setter={() => {}}
                value={data?.project}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Project Name'}
              />
            </div>
          )}

          {isShowProjectDropdown && !loadingProjects && (
            <div className={classes.input_main}>
              <DropDown
                disabled={!data?.isAdd}
                isMulti={false}
                setter={(data) => {
                  setAssignTo(data?.assignTo);
                  setProjectData(data);

                  const statusData = data?.stages?.find(
                    (item) => item?.name === 'To Do',
                  );

                  if (!status && statusData) {
                    setStatus(statusData);
                  }
                }}
                value={projectData}
                placeholder={'Select Project'}
                options={allCompanies}
                getOptionLabel={(e) => `${e?.name}`}
                getOptionValue={(e) => `${e?._id}`}
              />
            </div>
          )}

          <div className={classes.input_main}>
            <Input
              setter={setTaskName}
              value={taskName}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Task Name'}
            />
          </div>
          {type == 'template' && (
            <div className={classes.input_main}>
              <DropDown
                isMulti={true}
                setter={setAssignTo}
                value={assignTo}
                options={allAdmins}
                placeholder={'Select Assigned Users'}
                getOptionLabel={(e) =>
                  `${e?.firstName} ${e?.lastName} (${e?.role[0]})`
                }
                getOptionValue={(e) =>
                  `${e?.firstName} ${e?.lastName} (${e?.role[0]})`
                }
              />
            </div>
          )}
          {type == 'task' && (
            <>
              {data?.isAdd && (
                <div className={classes.input_main}>
                  <DropDown
                    setter={setStatus}
                    value={status}
                    placeholder={'Select Task Status'}
                    options={(optionsArrayStages?.length > 0
                      ? optionsArrayStages
                      : projectData?.stages || []
                    ).map((e) => ({
                      ...e,
                      name: e?.name,
                      value: e?.name == 'Pending' ? 'Over Due' : e?.name,
                    }))}
                    optionLabel={'value'}
                    optionValue={'name'}
                  />
                </div>
              )}
              {/* {adminAndbrokers && adminAndbrokers?.length &&  */}
              <div className={classes.input_main}>
                <DropDown
                  isMulti={true}
                  setter={setAssignTo}
                  value={assignTo}
                  placeholder={'Select Assigned Users'}
                  options={allAdmins}
                  getOptionLabel={(e) =>
                    `${e?.firstName} ${e?.lastName} (${e?.role?.[0]})`
                  }
                  getOptionValue={(e) =>
                    `${e?.firstName} ${e?.lastName} (${e?.role?.[0]})`
                  }
                />
              </div>
              {/* } */}
              <div className={classes.input_main}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en"
                  localeText={
                    enUS.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                >
                  <Row>
                    <div style={{ color: 'grey', marginBottom: '0.5em' }}>
                      Deadline *
                    </div>
                    <Col md={6}>
                      <DatePicker
                        value={deadlineDate}
                        onChange={(newValue) => {
                          setDeadlineDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            InputLabelProps={{ shrink: false }}
                            onKeyDown={(e) => e.preventDefault()}
                            {...params}
                          />
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <TimePicker
                        className={classes.BSTimePicker}
                        value={milliseconds}
                        onChange={setMilliseconds}
                      />
                    </Col>
                  </Row>
                </LocalizationProvider>
              </div>
            </>
          )}

          <div className={classes.input_main}>
            <TextArea
              setter={setDescription}
              value={description}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Note'}
            />
          </div>
          {type == 'template' && (
            <div className={classes.input_main}>
              <Input
                setter={setNoOfDays}
                value={noOfDays}
                type={'number'}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'No Of Days'}
              />
            </div>
          )}
          <div className={classes.add_task_btn_main}>
            <Button
              onClick={() => HandleSubmitData()}
              className={classes.add_task_btn}
              label={
                isLoading
                  ? 'Submitting...'
                  : data?.isAdd == null
                    ? 'Add Task'
                    : 'Save'
              }
              disabled={isLoading}
            />
            <Button
              onClick={() => setShow(false)}
              className={classes.add_task_btn}
              label="Close"
              disabled={isLoading}
            />
          </div>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default AddTaskModal;
