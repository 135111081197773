import React, { useState } from 'react';
import { DiCssTricks } from 'react-icons/di';
import { MdDelete } from 'react-icons/md';

import Maps from '../MapAndPlaces';
import AddMoreBtn from '../AddMoreBtn';
import { Input } from '../Input/Input';
import classes from './AddMultiMapInputContainer.module.css';

const AddMultiMapInputContainer = ({
  title = 'Default Title',
  placeholder = 'Enter Text',
  businessPlacesApiAddress,
  setBusinessPlacesApiAddress,
  businessAddressCoordinates,
  setBusinessAddressCoordinates,
  businessAddressDetail,
  setBusinessAddressDetail,
  disabled=false,
}) => {
  const [val, setVal] = useState('');
  const [businessPlacesApiAddressVal, setBusinessPlacesApiAddressSetter] =
    useState('');
  const [businessAddressCoordinatesVal, setBusinessAddressCoordinatesSetter] =
    useState({});
  const [businessAddressDetailVal, setBusinessAddressDetailSetter] = useState(
    {},
  );
  return (
    <div className={classes?.mainContainer}>
      <div
        className={[
          title
            ? classes?.inputAndAddBtnContainer
            : classes?.inputAndAddBtnContainerWithoutTitle,
        ].join(' ')}
      >
        <span className={classes?.titleText}>{title}</span>
        <AddMoreBtn
          onClick={() => {
            if (businessPlacesApiAddressVal !== '') {
              setBusinessPlacesApiAddress((pre) => [
                ...pre,
                businessPlacesApiAddressVal,
              ]);
              setBusinessAddressDetail((pre) => [
                ...pre,
                businessAddressDetailVal,
              ]);
              setBusinessAddressCoordinates((pre) => [
                ...pre,
                businessAddressCoordinatesVal,
              ]);

              setBusinessPlacesApiAddressSetter('');
              setBusinessAddressDetailSetter({});
              setBusinessAddressCoordinatesSetter({});

              setVal('');
            }
          }}
          disabled={disabled}
        />
      </div>
      <Maps
        disabled={disabled}
        setAddress={(value) => {
          setBusinessPlacesApiAddressSetter(value);
        }}
        setCoordinates={(value) => {
          setBusinessAddressCoordinatesSetter(value);
        }}
        setPlaceDetail={(value) => {
          setBusinessAddressDetailSetter(value);
        }}
        type={'Places'}
        loader={<Input disabled={disabled} placeholder={placeholder} type={'text'}/>}
      />
      {businessPlacesApiAddress.map((item, index) => (
        <div
          className={[classes.bulletContainer].join(' ')}
          key={`${index} busHighlight`}
        >
          <DiCssTricks color={'var(--dashboard-main-color)'} size={12} />
          <span>{item}</span>
          <MdDelete
            color={'var(--dashboard-main-color)'}
            size={20}
            onClick={() => {
              if(disabled) return ;
              const newBusinessPlacesApiAddress = [...businessPlacesApiAddress];
              const newBusinessAddressCoordinates = [
                ...businessAddressCoordinates,
              ];
              const newBusinessAddressDetail = [...businessAddressDetail];

              newBusinessPlacesApiAddress.splice(index, 1);
              newBusinessAddressCoordinates.splice(index, 1);
              newBusinessAddressDetail.splice(index, 1);

              setBusinessPlacesApiAddress(newBusinessPlacesApiAddress);
              setBusinessAddressCoordinates(newBusinessAddressCoordinates);
              setBusinessAddressDetail(newBusinessAddressDetail);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default AddMultiMapInputContainer;
