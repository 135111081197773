import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { toast } from 'react-toastify';

import { apiHeader, BaseURL } from '../../config/apiUrl';
import { Get, Patch } from '../../Axios/AxiosFunctions';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import CopyToClipboardButton from '../../Component/ClickCopy';
import { Button } from '../../Component/Button/Button';
import PreviewListing from '../PreviewListing';
import Interests from '../Interests';
import EditListing from '../EditListing';
import NotesList from '../NotesList';
import ListingFolderDetail from '../TeamFolder/ListingFolderDetail';
import TaskManagerAllProjects from '../TaskManager/TaskManagerAllProjects';
import EditEntity from '../Entity/sections/EditEntity';
import TaxReturnConvertor from '../TaxReturnConvertor';
import BviViewer from '../BviViewer';
import AddUserToVipListModal from '../../modals/AddUserToVipListModal';
import classes from './ListingDetail.module.css';

const ListingDetail = () => {
  const { slug } = useParams();
  const superAdminEmail = process.env.REACT_APP_SUPER_ADMIN_EMAIL;
  const {
    user,
    access_token: accessToken,
    company_id: companyId,
  } = useSelector((state) => state.authReducer);
  const isAdmin = user?.role?.some((item) =>
    ['super-admin', 'admin', 'executive', 'hr', 'sales'].includes(item),
  );
  const allowed = [
    'mjn@denverbbs.com',
    'michael@denverbbs.com',
    superAdminEmail,
  ].includes(user?.email);
  const currentDomain = window.location.origin;
  let domain = currentDomain?.includes('bbsadmins')
    ? currentDomain.replace('bbsadmins', 'denverbbs')
    : currentDomain.replace('admin', 'users');

  const [searchParams] = useSearchParams();
  const companyName = decodeURIComponent(searchParams.get('company-name'));
  const tab = searchParams.get('tab');
  const listingID = searchParams.get('id');

  const tabs = [
    'listing',
    'data-room',
    'notes',
    'interests',
    'projects',
    'entity',
    'tr-extraction',
    'bvi',
  ];

  const [tabValue, setTabValue] = React.useState(
    tab && tabs.includes(tab) ? tab : 'listing',
  );
  const [business, setBusiness] = useState(null);
  const [status, setSharedStatus] = useState(searchParams.get('status'));
  const [listingValue, setListingValue] = useState('view');
  const [folderRoot, setFolderRoot] = useState('');
  const [entityId, setEntityId] = useState(null);

  const [isAdding, setIsAdding] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [vipUsers, setVipUsers] = useState(null);

  const [permVip, setPermVip] = useState(isAdmin);

  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onListingChange = (event, newValue) => {
    setListingValue(newValue);
  };

  const getFolderRoot = async () => {
    const response = await Get(
      BaseURL(`data-room/business/${listingID}`),
      accessToken,
    );
    if (response != undefined) {
      let brokers = response?.data?.data?.business?.broker || [];
      brokers = brokers.map((broker) => broker.toString());
      if (brokers.includes(user?._id?.toString())) setPermVip(true);

      setFolderRoot(response?.data?.data?._id);
      setBusiness(response?.data?.data?.business);
      console.log('here', response?.data?.data);
      setEntityId(response?.data?.data?.entity || null);
    }
  };

  const getVipUsers = async () => {
    const url = BaseURL(`business/${listingID}/vip-users`);

    const response = await Get(url, accessToken);
    if (response !== undefined) {
      console.log('vipUsers:', response?.data?.vipUsers);
      setVipUsers(response?.data?.vipUsers?.buyer);
    }
  };

  const addUsersToVipList = async (e) => {
    setIsAdding(true);
    const url = BaseURL(`users/admin/add-to-vip`);
    const body = {
      businessId: listingID,
      ...e,
    };
    const response = await Patch(url, body, apiHeader(accessToken), companyId);
    if (response !== undefined) {
      setIsAdding(false);
      setVipUsers(response?.data?.data);
      toast.success('Vip users updated successfully');
      setIsOpenModal('');
    } else {
      setIsAdding(false);
    }
  };

  useEffect(() => {
    getFolderRoot();
    getVipUsers();
  }, []);

  const isPaidClient =
    localStorage.getItem('testtest') == 'true' ? true : false;

  return (
    <SideBarSkeleton>
      <div className={classes.mainContainer}>
        <div className={classes.listingHead}>
          <h4>{companyName || 'Listing Detail'}</h4>
        </div>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={onTabChange} aria-label="Listing Detail Tab">
                <Tab label="Listing" value="listing" />
                {(status != 'prospect' || business?.owner?.length) && (
                  <Tab label="Data Room" value="data-room" />
                )}
                <Tab label="Notes" value="notes" />
                {status != 'prospect' && (
                  <Tab label="Interests" value="interests" />
                )}
                <Tab label="Projects" value="projects" />
                {entityId && <Tab label="Entity" value="entity" />}
                {(isPaidClient || allowed) && (
                  <Tab label="tr-extraction" value="tr-extraction" />
                )}
                <Tab label="bvi" value="bvi" />
              </TabList>
            </Box>
            <TabPanel value="listing" style={{ padding: '0' }}>
              <div className={classes.toolBar}>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  defaultValue="view"
                  onChange={onListingChange}
                >
                  <FormControlLabel
                    value="view"
                    control={<Radio />}
                    label="View"
                  />
                  <FormControlLabel
                    value="edit"
                    control={<Radio />}
                    label="Edit"
                  />
                </RadioGroup>
                {status != 'prospect' && permVip && (
                  <Button
                    onClick={() => {
                      setIsOpenModal(true);
                    }}
                    label={'Add Buyers To VIP List'}
                    className={classes.btn}
                  />
                )}
                {[
                  'published',
                  'under-contract',
                  'sold',
                  'seller-review',
                  'pocket',
                ].includes(status) &&
                  listingValue == 'view' && (
                    <CopyToClipboardButton
                      text={`${domain}/buy-a-business/${slug}`}
                    />
                  )}
              </div>
              <div>
                {listingValue == 'view' ? (
                  <div className={classes.editContainer}>
                    <PreviewListing />
                  </div>
                ) : (
                  <div className={classes.editContainer}>
                    <EditListing
                      flag={true}
                      setSharedStatus={setSharedStatus}
                    />
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel value="data-room" style={{ padding: '0 0 0 15px' }}>
              <div className={classes.container}>
                <ListingFolderDetail
                  folderRoot={folderRoot}
                  business={listingID}
                />
              </div>
            </TabPanel>
            <TabPanel value="notes" style={{ padding: '0' }}>
              <div className={classes.container}>
                <NotesList slug={slug} status={status} />
              </div>
            </TabPanel>
            {status != 'prospect' && (
              <TabPanel value="interests" style={{ padding: '0' }}>
                <div className={classes.container}>
                  <Interests
                    isShowAll={true}
                    listingID={listingID}
                    companyName={companyName}
                  />
                </div>
              </TabPanel>
            )}
            <TabPanel value="projects" style={{ padding: '0' }}>
              <div className={classes.container}>
                <TaskManagerAllProjects
                  isShowAll={true}
                  listingID={listingID}
                  isShowAddTask
                />
              </div>
            </TabPanel>
            {entityId && (
              <TabPanel value="entity" style={{ padding: '0' }}>
                <div className={classes.container}>
                  <EditEntity _id={entityId} />
                </div>
              </TabPanel>
            )}

            {(allowed || isPaidClient) && (
              <TabPanel value="tr-extraction" style={{ padding: '0' }}>
                <div className={classes.container}>
                  <TaxReturnConvertor
                    listingId={listingID}
                    companyName={companyName}
                  />
                </div>
              </TabPanel>
            )}

            <TabPanel value="bvi" style={{ padding: '0' }}>
              <div className={classes.container}>
                <BviViewer listingId={listingID} />
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      {isOpenModal && (
        <AddUserToVipListModal
          show={isOpenModal}
          setShow={setIsOpenModal}
          onClick={addUsersToVipList}
          data={vipUsers}
          isLoading={isAdding}
        />
      )}
    </SideBarSkeleton>
  );
};

export default ListingDetail;
