import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../Component/Button/Button';
// import PaginationComponent from '../../Component/PaginationComponent';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { apiHeader, BaseURL } from '../../config/apiUrl';
import classes from './AutomatedEmails.module.css';
import TableSkeleton from '../../Component/TableSkeleton';
import NoData from '../../Component/NoData/NoData';
import { BsThreeDots } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Get, Delete, Post } from '../../Axios/AxiosFunctions';
import { Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PoperComponent from '../../Component/PopperComponent';
import AreYouSureModal from '../../modals/AreYouSureModal';
import { toast } from 'react-toastify';
import { LuEye } from 'react-icons/lu';
import EmailPreviewModal from '../../modals/EmailPreviewModal';
import SearchInput from '../../Component/SearchInput';
import useDebounce from '../../CustomHooks/useDebounce';
// import LogoFileUploadModal from '../../modals/LogoFileUploadModal';
import { MSG_ERROR } from '../../utils/contants';

const recordsLimit = 40;

const AutomatedEmails = () => {
  const navigate = useNavigate();

  const { user, access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state?.authReducer,
  );

  const superAdminEmail = process.env.REACT_APP_SUPER_ADMIN_EMAIL;
  const baseApiUrl = BaseURL(`automated-email-template`);

  const [isFetchingTemplates, setIsFetchingTemplates] = useState(true);

  const [page] = useState(1);
  const [templateData, setTemplateData] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [isOpenEmailPreview, setIsOpenEmailPreview] = useState(false);
  const anchorRef = useRef(null);
  const [indexMap, setIndexMap] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState('');
  const [search, setSearch] = useState('');
  // const [isUploading, setIsUploading] = useState(false);
  // const [isLogoUploadModalOpen, setIsLogoUploadModalOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);

  const getAllEmailTemplates = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = `${baseApiUrl}?search=${search}&page=${page}&limit=${recordsLimit}`;

      const response = await Get(url, accessToken, companyId);

      if (response?.status === 200) {
        setTemplateData(response?.data);
      }

      setIsFetchingTemplates(false);
    }
  };

  const handleActionClick = (flag) => {
    if (flag == 'Delete') {
      setOpenDeleteConfirmation(true);
    } else if (flag == 'Edit') {
      console.log('EDIT CLICKED-selected', selectedItem);
      navigate(`/edit-automated-email/${selectedItem?._id}`);
    }
  };

  const handleDelete = async () => {
    const response = await Delete(
      `${baseApiUrl}/${selectedItem?._id}`,
      apiHeader(accessToken),
    );

    if (response?.status === 200) {
      toast.success('Deleted successfully.');
      getAllEmailTemplates();
      setSelectedItem(null);
      setOpenDeleteConfirmation(false);
    } else {
      toast.error(response.data || MSG_ERROR);
    }
  };

  const handleToggle = () => {
    setOpenPopper((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    getAllEmailTemplates();
  }, [accessToken, debouncedSearchTerm]);

  // const handleLogoUpload = async (file) => {
  //   if (!file) {
  //     toast.error('Please select a file to upload');
  //     return;
  //   }

  //   if (file.size > 350 * 1024) {
  //     toast.error('File size exceeds 350KB limit');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('upload', file);

  //   try {
  //     setIsUploading(true);
  //     const response = await Post(
  //       BaseURL('automated-email-template/upload-images'),
  //       formData,
  //       apiHeader(accessToken, 'multipart/form-data'),
  //       companyId
  //     );

  //     if (response && (response.status === 201 || response.status === 200)) {
  //       toast.success(`Upload logo successfully`);
  //       setIsLogoUploadModalOpen(false);
  //     } else {
  //       toast.error('Failed to upload logo');
  //     }
  //   } catch (error) {
  //     console.error('Error uploading logo:', error);
  //     toast.error(error?.response?.data || MSG_ERROR);
  //   } finally {
  //     setIsUploading(false);
  //   }
  // };

  return (
    <>
      <div>
        <SideBarSkeleton>
          <div className={[classes.mainContainer]}>
            <div className={[classes.headerContainer]}>
              <h3>Automated Emails</h3>
              <div className={[classes.inputDiv]}>
                <SearchInput
                  setter={setSearch}
                  value={search}
                  placeholder={'Search'}
                />

                {user?.email === superAdminEmail && <Button
                  label="Add Template"
                  className={classes.addProjectBtn}
                  onClick={() => {
                    navigate('/add-automated-email');
                  }}
                />}
                {/* <Button
                  className={classes.addProjectBtn}
                  onClick={() => setIsLogoUploadModalOpen(true)}
                >
                  Upload Company Logo
                </Button> */}
              </div>
            </div>

            <div className="table100 ver1 m-b-110">
              <div className="table100-head">
                <table>
                  <thead>
                    <tr className="row100 head">
                      <th
                        className="cell100 column1"
                        style={{ width: '5%', textAlign: 'start' }}
                      >
                        S.No
                      </th>
                      <th
                        className="cell100 column1"
                        style={{ width: '15%', textAlign: 'start' }}
                      >
                        Trigger
                      </th>
                      <th
                        className="cell100 column2"
                        style={{ width: '15%', textAlign: 'start' }}
                      >
                        From Email
                      </th>
                      <th
                        className="cell100 column3"
                        style={{ width: '15%', textAlign: 'start' }}
                      >
                        To Email
                      </th>
                      <th
                        className="cell100 column4"
                        style={{ width: '10%', textAlign: 'start' }}
                      >
                        CC
                      </th>
                      <th
                        className="cell100 column5"
                        style={{ width: '10%', textAlign: 'center' }}
                      >
                        Subject
                      </th>
                      <th
                        className="cell100 column6"
                        style={{ width: '10%', textAlign: 'center' }}
                      >
                        Email Body
                      </th>
                      <th
                        className="cell100 column7"
                        style={{ width: '10%', textAlign: 'center' }}
                      >
                        Active
                      </th>
                      <th
                        className="cell100 column9"
                        style={{ width: '8%', textAlign: 'center' }}
                      >
                        Link
                      </th>
                      {user?.email === superAdminEmail && <th
                        className="cell100 column10"
                        style={{ width: '5%', textAlign: 'center' }}
                      >
                        Action
                      </th>}
                    </tr>
                  </thead>
                </table>
              </div>
              {isFetchingTemplates ? (
                <TableSkeleton rowsCount={recordsLimit} colsCount={10} />
              ) : (
                <div className="table100-body js-pscroll ps ps--active-y">
                  <table>
                    <tbody>
                      {templateData?.result?.length > 0 ? (
                        templateData?.result?.map((item, index) => (
                          <tr
                            className="row100 body"
                            style={{
                              backgroundColor:
                                selectedItem?._id == item?._id && '#E5F5FF',
                            }}
                          >
                            <td
                              className="cell100 column1"
                              style={{
                                width: '5%',
                                textAlign: 'start',
                              }}
                            >
                              {page == 1
                                ? index + 1
                                : recordsLimit * (page - 1) + index + 1}
                            </td>
                            <td
                              className="cell100 column1"
                              style={{ width: '15%', textAlign: 'start' }}
                            >
                              {item?.trigger}
                            </td>
                            <td
                              className="cell100 column3"
                              style={{ width: '15%', textAlign: 'start', maxWidth: '15%', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                              {item?.from}
                            </td>
                            <td
                              className="cell100 column4"
                              style={{ width: '15%', textAlign: 'start', maxWidth: '15%', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                              {item?.to}
                            </td>
                            <td
                              className="cell100 column5"
                              style={{ width: '10%', textAlign: 'center', maxWidth: '10%', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                              {item?.cc}
                            </td>
                            <td
                              className="cell100 column6"
                              style={{ width: '10%', textAlign: 'center' }}
                            >
                              <div style={{ position: 'relative' }}>
                                {item?.subject}
                              </div>
                            </td>
                            <td
                              className="cell100 column7"
                              style={{ width: '10%', textAlign: 'center' }}
                            >
                              <div
                                style={{
                                  position: 'relative',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setSelectedItem(item);
                                  setIsOpenEmailPreview(true);
                                }}
                              >
                                <LuEye size={25} />
                              </div>
                            </td>
                            <td
                              className="cell100 column8"
                              style={{ width: '10%', textAlign: 'center' }}
                            >
                              <div style={{ position: 'relative' }}>
                                <Checkbox
                                  checked={item?.isEnabled}
                                  disabled={true}
                                />
                              </div>
                            </td>
                            <td
                              className="cell100 column10"
                              style={{ width: '8%', textAlign: 'center' }}
                            >
                              <div style={{ position: 'relative' }}>
                                {item?.link}
                              </div>
                            </td>

                            {user?.email === superAdminEmail && <td
                              className="cell100 column11"
                              style={{ width: '5%', textAlign: 'center' }}
                            >
                              <div
                                ref={index == indexMap ? anchorRef : null}
                                id="composition-button"
                                aria-controls={
                                  openPopper ? 'composition-menu' : undefined
                                }
                                aria-expanded={openPopper ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={() => {
                                  setSelectedItem(item);
                                  setIndexMap(index);
                                  setTimeout(() => {
                                    handleToggle();
                                  }, 100);
                                }}
                              >
                                <BsThreeDots
                                  size={30}
                                  className={[classes.threeDots]}
                                />
                              </div>
                            </td>}
                          </tr>
                        ))
                      ) : (
                        <NoData text={'No Automated Emails Found'} />
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {/* {templateData?.result?.length > 1 && (
              <div className={[classes.paginationDiv]}>
                <PaginationComponent
                  totalPages={templateData?.totalPages}
                  currentPage={page}
                  setCurrentPage={setPage}
                />
              </div>
            )} */}

            <PoperComponent
              handleClick={handleActionClick}
              open={openPopper}
              setOpen={setOpenPopper}
              anchorRef={anchorRef}
              data={['Edit', 'Delete']}
            />
          </div>
        </SideBarSkeleton>
      </div>

      <AreYouSureModal
        show={openDeleteConfirmation}
        setShow={setOpenDeleteConfirmation}
        subTitle={`Are you sure you want delete this template`}
        isApiCall={false}
        onClick={() => handleDelete()}
      />

      <EmailPreviewModal
        show={isOpenEmailPreview}
        setShow={setIsOpenEmailPreview}
        data={selectedItem?.emailBody}
      />

      {/* <LogoFileUploadModal
        show={isLogoUploadModalOpen}
        setShow={setIsLogoUploadModalOpen}
        onUpload={handleLogoUpload}
        isUploading={isUploading}
      /> */}
    </>
  );
};

export default AutomatedEmails;
