import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  leadProgressOptions,
  leadStatusOptions,
} from '../../constant/commonData';
import useDebounce from '../../CustomHooks/useDebounce';
import { Get } from '../../Axios/AxiosFunctions';
import { BaseURL } from '../../config/apiUrl';
import ModalSkeleton from '../ModalSkeleton';
import { Button } from '../../Component/Button/Button';
import { DropDown } from '../../Component/DropDown/DropDown';
import classes from './InterestsFilterModal.module.css';

const InterestsFilterModal = ({
  isAdmin,
  show,
  setShow,
  setFilter,
  status = { value: 'all', label: 'All' },
  leadProgress = { value: 'all', label: 'All' },
  broker = { _id: 'null', email: 'All' },
  allBrokers = [],
  allListing = [],
  listing = null,
  inheritListing = null,
  inputListing,
  setInputListing,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [selectedLeadProgress, setSelectedLeadProgress] =
    useState(leadProgress);
  const [selectedBroker, setSelectedBroker] = useState(broker);
  const [selectedListing, setSelectedListing] = useState(
    inheritListing?._id ? inheritListing : listing,
  );

  return (
    <div>
      <style>{`
      .modal-content{
        overflow:visible !important;
      }
      `}</style>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        showCloseIcon={true}
        width="700px"
        borderRadius="20px"
        header={'Select Filters For Interests'}
      >
        <div className={classes.addProjectModal_main}>
          <DropDown
            options={[{ label: 'All', value: 'all' }, ...leadStatusOptions]}
            label={'Status'}
            customStyle={{
              marginBottom: '1em',
              minWidth: '150px',
              paddingBlock: '0px',
            }}
            value={selectedStatus}
            setter={(e) => {
              setSelectedStatus(e);
            }}
            optionLabel={'label'}
            optionValue={'value'}
          />
          <DropDown
            options={[{ label: 'All', value: 'all' }, ...leadProgressOptions]}
            label={'Lead progress'}
            customStyle={{
              marginBottom: '1em',
              minWidth: '150px',
              paddingBlock: '0px',
            }}
            value={selectedLeadProgress}
            setter={(e) => {
              setSelectedLeadProgress(e);
            }}
            optionLabel={'label'}
            optionValue={'value'}
          />
          {isAdmin && (
            <DropDown
              options={[{ _id: 'null', name: 'All' }, ...allBrokers]}
              label={'Broker'}
              customStyle={{
                marginBottom: '1em',
                minWidth: '150px',
                paddingBlock: '0px',
              }}
              value={selectedBroker}
              setter={(e) => {
                setSelectedBroker(e);
              }}
              getOptionLabel={(i) =>
                i._id != 'null' ? `${i.firstName} ${i.lastName}` : 'All'
              }
              optionValue={'_id'}
            />
          )}
          <DropDown
            options={allListing}
            label={'Company'}
            placeholder={'Enter the company name'}
            customStyle={{
              marginBottom: '1em',
              minWidth: '150px',
              paddingBlock: '0px',
            }}
            inputValue={inputListing}
            inputSetter={setInputListing}
            value={selectedListing}
            setter={(e) => {
              setSelectedListing(e);
            }}
            optionLabel={'companyName'}
            optionValue={'_id'}
            disabled={inheritListing?._id}
            isClearable={true}
          />
          <Button
            onClick={() =>
              setFilter(
                selectedStatus,
                selectedLeadProgress,
                selectedBroker,
                selectedListing,
              )
            }
            className={classes.btn}
            label={'Set Filter'}
            customStyle={{
              marginTop: '2em',
            }}
          />
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default InterestsFilterModal;
