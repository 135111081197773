import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Button } from '../../Component/Button/Button';
import { Input } from '../../Component/Input/Input';
import ModalSkeleton from '../ModalSkeleton';
import classes from './CreateFinancialProjectModal.module.css';

const CreateFinancialProjectModal = ({
  show = true,
  setShow,
  handleSubmit,
  handleRemoveSubmit,
  data,
  isLoading = false,
}) => {
  const [name, setName] = useState('');

  useEffect(() => {
    data && setName(data?.isFile ? data?.fileName : data?.name);
  }, [data]);

  const HandleSubmitData = () => {
    if (!name) {
      return toast.error(`Project name is required`);
    }

    handleSubmit({ name });
  };

  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="700px"
        borderRadius="20px"
        header={`${data ? 'Rename Project' : 'Create Project'}`}
        showCloseIcon={true}
      >
        <div className={classes.container}>
          <Row className={classes.row}>
            <Col md={12}>
              <Input
                setter={setName}
                value={name}
                placeholder={'Project name'}
                label={'Project name'}
              />
            </Col>
          </Row>
          <div className={classes.inputContainer}>
            <Button
              onClick={() => HandleSubmitData()}
              className={classes.btn}
              label={
                isLoading ? 'Submitting...' : data ? 'Rename' : 'Create Project'
              }
              disabled={isLoading || data?.name == name}
            />
            {data && (
              <Button
                onClick={() => handleRemoveSubmit()}
                className={classes.btn}
                label={'Remove'}
                disabled={isLoading}
              />
            )}
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default CreateFinancialProjectModal;
