import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import useDebounce from '../../CustomHooks/useDebounce';
import { BaseURL } from '../../config/apiUrl';
import { Get } from '../../Axios/AxiosFunctions';
import ModalSkeleton from '../ModalSkeleton';
import { Button } from '../../Component/Button/Button';
import { Input } from '../../Component/Input/Input';
import { DropDown } from '../../Component/DropDown/DropDown';
import { TextArea } from '../../Component/TextArea';
import AddEditMultiValueInputContainer from '../../Component/AddEditMultiValueInputContainer';
import classes from './BVIEditorModal.module.css';

const BVIEditorModal = ({
  show,
  setShow,
  isSaving,
  title,
  data,
  handleSubmit,
}) => {
  const { access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state?.authReducer || {},
  );

  const [owner, setOwner] = useState([]);
  const [allOwners, setAllOwners] = useState([]);
  const [allSellerSearch, setAllSellerSearch] = useState('');
  const sellerDebouncedSearch = useDebounce(allSellerSearch, 1000);

  const [business_address, setBusiness_address] = useState([]);
  const [legal_entity, setLegal_entity] = useState('');
  const [lien, setLien] = useState('');
  const [is_franchise, setIs_franchise] = useState({
    label: 'Choose an option',
    value: 'none',
  });
  const [is_relocatable, setIs_relocatable] = useState({
    label: 'Choose an option',
    value: 'none',
  });
  const [business_phone_number, setBusiness_phone_number] = useState('');
  const [business_website, setBusiness_website] = useState('');
  const [reason_for_sale, setReason_for_sale] = useState('');
  const [business_opportunity, setBusiness_opportunity] = useState('');
  const [business_strength, setBusiness_strength] = useState('');
  const [business_weaknesses, setBusiness_weaknesses] = useState('');
  const [maker_business_unique, setMaker_business_unique] = useState('');
  const [hours_of_operation, setHours_of_operation] = useState('');
  const [business_history, setBusiness_history] = useState('');
  const [purchase_date, setPurchase_date] = useState('');
  const [business_years, setBusiness_years] = useState('');
  const [business_management, setBusiness_management] = useState({
    label: 'Choose an option',
    value: 'none',
  });
  const [week_commitment, setWeek_commitment] = useState('');
  const [responsibility, setResponsibility] = useState('');
  const [replacement, setReplacement] = useState('');
  const [train_week, setTrain_week] = useState('');
  const [after_train_week, setAfter_train_week] = useState('');
  const [intAsset, setIntAsset] = useState('');
  const [certification_for_usiness, setCertification_for_usiness] =
    useState('');
  const [manager_number, setManager_number] = useState('');
  const [manager_information, setManager_information] = useState('');
  const [full_time_employee_number, setFull_time_employee_number] =
    useState('');
  const [full_time_employee_information, setFull_time_employee_information] =
    useState('');
  const [part_time_employee_number, setPart_time_employee_number] =
    useState('');
  const [partTimeEmployeeInfo, setPartTimeEmployeeInfo] = useState('');
  const [hire_timeline, setHire_timeline] = useState('');
  const [turnover_trend, setTurnover_trend] = useState('');
  const [recent_improvements, setRecent_improvements] = useState('');
  const [FFAndE, setFFAndE] = useState('');
  const [prodtion_service, setProduction_service] = useState('');
  const [revenue_percent, setRevenue_percent] = useState('');
  const [production_service_distribute, setProduction_service_distribute] =
    useState('');
  const [supply_inventory, setSupply_inventory] = useState('');
  const [demo_customer, setDemo_customer] = useState('');
  const [strategy, setStrategy] = useState('');
  const [opportunity, setOpportunity] = useState('');
  const [competitors, setCompetitors] = useState('');
  const [industry, setIndustry] = useState('');
  const [building_type, setBuilding_type] = useState('');
  const [square, setSquare] = useState('');
  const [locate_years, setLocate_years] = useState('');
  const [suitability, setSuitability] = useState('');
  const [property_information, setProperty_information] = useState({
    label: 'Choose an option',
    value: 'null',
  });
  const [rent, setRent] = useState('');
  const [lease_term, setLease_term] = useState('');
  const [
    relation_with_landlord_lease_flexibility,
    setrelationshipWithLandlord,
  ] = useState('');
  const [sell_property, setsell_property] = useState({
    label: 'Choose an option',
    value: 'null',
  });
  const [special_zone_requirement, setSpecial_zone_requirement] = useState('');
  const [business_price, setBusiness_price] = useState('');
  const [CRE_price, setCRE_price] = useState('');
  const [inventory_value, setInventory_value] = useState('');
  const [motivation_level, setMotivation_level] = useState({
    label: 'Choose an option',
    value: 'null',
  });
  const [is_interest_in_finance, setIs_interest_in_finance] = useState({
    label: 'Choose an option',
    value: 'null',
  });

  const makeNoteBox = () => {
    const retVal = {
      business_address,
      legal_entity,
      lien,
      is_franchise,
      is_relocatable,
      business_phone_number,
      business_website,
      reason_for_sale,
      business_opportunity,
      business_strength,
      business_weaknesses,
      maker_business_unique,
      hours_of_operation,
      business_history,
      purchase_date,
      business_years,
      business_management,
      week_commitment,
      responsibility,
      replacement,
      train_week,
      after_train_week,
      intAsset,
      certification_for_usiness,
      manager_number,
      manager_information,
      full_time_employee_number,
      full_time_employee_information,
      part_time_employee_number,
      partTimeEmployeeInfo,
      hire_timeline,
      turnover_trend,
      recent_improvements,
      FFAndE,
      prodtion_service,
      revenue_percent,
      production_service_distribute,
      supply_inventory,
      demo_customer,
      strategy,
      opportunity,
      competitors,
      industry,
      building_type,
      square,
      locate_years,
      suitability,
      property_information,
      rent,
      lease_term,
      relation_with_landlord_lease_flexibility,
      sell_property,
      special_zone_requirement,
      business_price,
      CRE_price,
      inventory_value,
      motivation_level,
      is_interest_in_finance,
    };
    let str = JSON.stringify(retVal);
    str = str.replaceAll('_', '-');
    console.log(str);
    return str;
  };

  const parseTempDraft = (data) => {
    if (!data) return;
    const {
      business_address,
      legal_entity,
      lien,
      is_franchise,
      is_relocatable,
      business_phone_number,
      business_website,
      reason_for_sale,
      business_opportunity,
      business_strength,
      business_weaknesses,
      maker_business_unique,
      hours_of_operation,
      business_history,
      purchase_date,
      business_years,
      business_management,
      week_commitment,
      responsibility,
      replacement,
      train_week,
      after_train_week,
      intAsset,
      certification_for_usiness,
      manager_number,
      manager_information,
      full_time_employee_number,
      full_time_employee_information,
      part_time_employee_number,
      partTimeEmployeeInfo,
      hire_timeline,
      turnover_trend,
      recent_improvements,
      FFAndE,
      prodtion_service,
      revenue_percent,
      production_service_distribute,
      supply_inventory,
      demo_customer,
      strategy,
      opportunity,
      competitors,
      industry,
      building_type,
      square,
      locate_years,
      suitability,
      property_information,
      rent,
      lease_term,
      relation_with_landlord_lease_flexibility,
      sell_property,
      special_zone_requirement,
      business_price,
      CRE_price,
      inventory_value,
      motivation_level,
      is_interest_in_finance,
    } = JSON.parse(data?.replaceAll('-', '_'));
    {
      setBusiness_address(business_address || []);
      setLegal_entity(legal_entity);
      setLien(lien || '');
      setIs_franchise(is_franchise);
      setIs_relocatable(is_relocatable);
      setBusiness_phone_number(business_phone_number);
      setBusiness_website(business_website);
      setReason_for_sale(reason_for_sale);
      setBusiness_opportunity(business_opportunity);
      setBusiness_strength(business_strength);
      setBusiness_weaknesses(business_weaknesses);
      setMaker_business_unique(maker_business_unique);
      setHours_of_operation(hours_of_operation);
      setBusiness_history(business_history);
      setPurchase_date(purchase_date);
      setBusiness_years(business_years);
      setBusiness_management(business_management);
      setWeek_commitment(week_commitment);
      setResponsibility(responsibility);
      setReplacement(replacement);
      setTrain_week(train_week);
      setAfter_train_week(after_train_week);
      setIntAsset(intAsset);
      setCertification_for_usiness(certification_for_usiness);
      setManager_number(manager_number);
      setManager_information(manager_information);
      setFull_time_employee_number(full_time_employee_number);
      setFull_time_employee_information(full_time_employee_information);
      setPart_time_employee_number(part_time_employee_number);
      setPartTimeEmployeeInfo(partTimeEmployeeInfo);
      setHire_timeline(hire_timeline);
      setTurnover_trend(turnover_trend);
      setRecent_improvements(recent_improvements);
      setFFAndE(FFAndE);
      setProduction_service(prodtion_service);
      setRevenue_percent(revenue_percent);
      setProduction_service_distribute(production_service_distribute);
      setSupply_inventory(supply_inventory);
      setDemo_customer(demo_customer);
      setStrategy(strategy);
      setOpportunity(opportunity);
      setCompetitors(competitors);
      setIndustry(industry);
      setBuilding_type(building_type);
      setSquare(square);
      setLocate_years(locate_years);
      setSuitability(suitability);
      setProperty_information(property_information);
      setRent(rent);
      setLease_term(lease_term);
      setrelationshipWithLandlord(relation_with_landlord_lease_flexibility);
      setsell_property(sell_property);
      setSpecial_zone_requirement(special_zone_requirement);
      setBusiness_price(business_price);
      setCRE_price(CRE_price);
      setInventory_value(inventory_value);
      setMotivation_level(motivation_level);
      setIs_interest_in_finance(is_interest_in_finance);
    }
  };

  const onSave = async () => {
    if (!owner) {
      return toast.error('Please fill the seller field');
    }
    const bvi = makeNoteBox();
    handleSubmit(owner, bvi);
  };

  useEffect(() => {
    if (data) {
      console.log(data);
      setOwner(data?.seller);
      parseTempDraft(data?.bvi);
    }
  }, [data]);

  useEffect(() => {
    if (!allSellerSearch) {
      setAllOwners([]);
      return;
    }
    Get(
      BaseURL(`users/sellers/all?search=${allSellerSearch}`),
      accessToken,
      companyId,
    ).then((res) => {
      if (Array.isArray(res?.data?.data)) {
        console.log(res?.data?.data?.length);
        setAllOwners(res?.data?.data);
      } else {
        setAllOwners([]);
      }
    });
  }, [sellerDebouncedSearch]);

  return (
    <>
      <style>{`
      .MuiFormControl-root{
        width: 100%;
      }
      .MuiFormLabel-root {
        color: var(--placeholder-color) !important;
      }
      .MuiOutlinedInput-notchedOutline {
        box-shadow: 0px 0 5px 2px #0000000d;
        border-width: 0;
        border: none;
        border-radius: 10px;
        box-shadow: 0px 0 5px 2px #0000000d;
      }
      `}</style>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        borderRadius="20px"
        width="90vw"
        borderLine={false}
        header={title}
        showCloseIcon={true}
      >
        <div className={classes.mainContainer}>
          <div className={classes.headerContainer}>
            <div className={classes.headerInputContainer}>
              <DropDown
                setter={setOwner}
                value={owner}
                inputValue={allSellerSearch}
                inputSetter={setAllSellerSearch}
                placeholder={'Select Seller'}
                options={allOwners}
                optionValue={'_id'}
                getOptionLabel={(option) => {
                  return `${option['firstName']} ${option['lastName']} (${option['email']})`;
                }}
                isClearable={true}
              />
            </div>
            <div className={classes.headerBtnContainer}>
              <Button
                label={isSaving ? 'Uploading...' : 'Submit'}
                disabled={isSaving}
                onClick={onSave}
              />
            </div>
          </div>
          <Row className={classes.mainBody}>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={
                  'Pizza pie inc. Please include corrent punctuation for LLC/Corp'
                }
                value={legal_entity}
                setter={setLegal_entity}
                label={'Legal entity of business:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Do you have any liens on the business? If yes, please provide the type, name of lienholder, and lien amount.'
                }
                value={lien}
                setter={setLien}
                label={'Lien:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12}>
              <div className={classes.input_main}>
                <DropDown
                  value={is_franchise}
                  setter={setIs_franchise}
                  label={'Is the business a franchise?:'}
                  placeholder={'Choose an option'}
                  labelClassName={classes.dropdownLabel}
                  customStyle={{
                    boxShadow: '0px 0 5px 2px #0000000d',
                    borderRadius: '10px',
                    border: 'none',
                  }}
                  options={[
                    { label: 'Choose an option', value: 'none' },
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  optionLabel={'label'}
                  optionValue={'value'}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className={classes.input_main}>
                <DropDown
                  value={is_relocatable}
                  setter={setIs_relocatable}
                  label={'Is the business relocatable?:'}
                  placeholder={'Choose an option'}
                  labelClassName={classes.dropdownLabel}
                  customStyle={{
                    boxShadow: '0px 0 5px 2px #0000000d',
                    borderRadius: '10px',
                    border: 'none',
                  }}
                  options={[
                    { label: 'Choose an option', value: 'none' },
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  optionLabel={'label'}
                  optionValue={'value'}
                />
              </div>
            </Col>
            <Col md={12} className={classes.inputCol}>
              <AddEditMultiValueInputContainer
                title={'Business Address'}
                arrayValue={business_address}
                arraySetter={setBusiness_address}
                placeholder={'Add Business Address'}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'xxx-xxx-xxxx'}
                value={business_phone_number}
                setter={setBusiness_phone_number}
                label={'Business phone number:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'www.abc.com'}
                value={business_website}
                setter={setBusiness_website}
                label={'Business website:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={
                  'Retirement/seeking other business opportunities/etc.'
                }
                value={reason_for_sale}
                setter={setReason_for_sale}
                label={'Reason for sale:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Detailed and extensive description of the business. The more information, the better we understand what you do and how you do it.'
                }
                value={business_opportunity}
                setter={setBusiness_opportunity}
                label={'Business opportunity:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe what you do well and differently than your competition.'
                }
                value={business_strength}
                setter={setBusiness_strength}
                label={'Business strengths:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe where you are vulnerable and where you perceive weaknesses to be.'
                }
                value={business_weaknesses}
                setter={setBusiness_weaknesses}
                label={'Business weaknesses:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe what makes you different from your competition.'
                }
                value={maker_business_unique}
                setter={setMaker_business_unique}
                label={'What makes your business unique?:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'List days and hours of operation (Mon-Fri 9a-10p, Sat 10a-5p, Sun closed).'
                }
                value={hours_of_operation}
                setter={setHours_of_operation}
                label={'Hours of operation:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe in detail the history of your business. From its beginnings to today.'
                }
                value={business_history}
                setter={setBusiness_history}
                label={'Business history:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'Month/Year'}
                value={purchase_date}
                setter={setPurchase_date}
                label={'Date of purchase by current owner:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'Month/Year if known'}
                value={business_years}
                setter={setBusiness_years}
                label={'Total number of years in business:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12}>
              <div className={classes.input_main}>
                <DropDown
                  value={business_management}
                  setter={setBusiness_management}
                  label={'Management of business:'}
                  placeholder={'Choose an option'}
                  labelClassName={classes.dropdownLabel}
                  customStyle={{
                    boxShadow: '0px 0 5px 2px #0000000d',
                    borderRadius: '10px',
                    border: 'none',
                  }}
                  options={[
                    { label: 'Choose an option', value: 'none' },
                    { label: 'Absentee', value: 'Absentee' },
                    { label: 'Semi-Absentee', value: 'Semi-Absentee' },
                    { label: 'Owner Operated', value: 'Owner-Operated' },
                  ]}
                  optionLabel={'label'}
                  optionValue={'value'}
                />
              </div>
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={
                  'Include the weekly time commitment for all owners.'
                }
                value={week_commitment}
                setter={setWeek_commitment}
                label={'Seller weekly time commitment:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  ' Describe in detail what the Seller/Owner does for the business.'
                }
                value={responsibility}
                setter={setResponsibility}
                label={'Seller responsibilities and functions:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe in detail what responsibilities, duties, and processes are needed to replace Seller.'
                }
                value={replacement}
                setter={setReplacement}
                label={'Replacement of Seller:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'Number of weeks'}
                value={train_week}
                setter={setTrain_week}
                label={
                  'Length of time to train Buyer with reasonable skills but no direct knowledge:'
                }
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Number of weeks allotted for training after closing.'
                }
                value={after_train_week}
                setter={setAfter_train_week}
                label={'Transition training period:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe in detail what intangible or intellectual assets are included in the sale (if applicable).'
                }
                value={intAsset}
                setter={setIntAsset}
                label={'Intangible or Intellectual assets included in sale:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={
                  'For example - liquor license, plumbing license, tobacco license, software needed to operate.'
                }
                value={certification_for_usiness}
                setter={setCertification_for_usiness}
                label={
                  'Special skills, certifications, and/or licenses required to operate business:'
                }
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'Number of managers'}
                value={manager_number}
                setter={setManager_number}
                label={'Number of managers:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Please provide first names of managers, their respective compensation, hours worked per week, and duties/responsibilities.'
                }
                value={manager_information}
                setter={setManager_information}
                label={'Manager information:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'Number of full time employees'}
                value={full_time_employee_number}
                setter={setFull_time_employee_number}
                label={'Full time employee number:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Please provide first names of full time employees, their respective compensation, hours worked per week, and duties/responsibilities.'
                }
                value={full_time_employee_information}
                setter={setFull_time_employee_information}
                label={'Full time employee information:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'Part time employees'}
                value={part_time_employee_number}
                setter={setPart_time_employee_number}
                label={'Part time employee number:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Please provide first names of part time employees, their respective compensation, hours worked per week, and duties/responsibilities.'
                }
                value={partTimeEmployeeInfo}
                setter={setPartTimeEmployeeInfo}
                label={'Part time employee information'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe efforts and time it takes to attract/train a new employee.'
                }
                value={hire_timeline}
                setter={setHire_timeline}
                label={'Recruitment and hiring timeline:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Explain trends for your business and the industry in general.'
                }
                value={turnover_trend}
                setter={setTurnover_trend}
                label={'Employee turnover trends:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={'Describe in detail.'}
                value={recent_improvements}
                setter={setRecent_improvements}
                label={
                  'Highlights/recent improvements made to business/premises:'
                }
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe in detail any specialized FF&E. Broker to supply equipment list.'
                }
                value={FFAndE}
                setter={setFFAndE}
                label={'Furniture, fixtures, and equipment (FF&E):'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe in detail what goods and/or services you offer to your customers.'
                }
                value={prodtion_service}
                setter={setProduction_service}
                label={'Products and services:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe what percentage of revenue goes towards different goods/services.'
                }
                value={revenue_percent}
                setter={setRevenue_percent}
                label={'Percentage of revenue:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe how the business distributes products/services.'
                }
                value={production_service_distribute}
                setter={setProduction_service_distribute}
                label={'Distribution of products and services:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={'Describe in detail if applicable.'}
                value={supply_inventory}
                setter={setSupply_inventory}
                label={'Supply chain and inventory processes:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={'Describe in detail.'}
                value={demo_customer}
                setter={setDemo_customer}
                label={'Target demographics and customer overview:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={'Describe in detail.'}
                value={strategy}
                setter={setStrategy}
                label={'Current marketing and advertising strategies:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={'Describe in detail.'}
                value={opportunity}
                setter={setOpportunity}
                label={'Business growth opportunities:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={'Describe in detail.'}
                value={competitors}
                setter={setCompetitors}
                label={'Direct competitors:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={'Describe in detail.'}
                value={industry}
                setter={setIndustry}
                label={'Current industry overview:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'Stand-alone building/strip mall/home based/etc.'}
                value={building_type}
                setter={setBuilding_type}
                label={'Building type:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={
                  'Please provide building size and lot size (if applicable)'
                }
                value={square}
                setter={setSquare}
                label={'Square Footage:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'Number of years'}
                value={locate_years}
                setter={setLocate_years}
                label={'Years at current location:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe in detail what is done well/challenges at current location.'
                }
                value={suitability}
                setter={setSuitability}
                label={'Suitability of location:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12}>
              <div className={classes.input_main}>
                <DropDown
                  value={property_information}
                  setter={setProperty_information}
                  label={'Property information'}
                  placeholder={'Choose an option'}
                  labelClassName={classes.dropdownLabel}
                  customStyle={{
                    boxShadow: '0px 0 5px 2px #0000000d',
                    borderRadius: '10px',
                    border: 'none',
                  }}
                  options={[
                    { label: 'Choose an option', value: 'null' },
                    { label: 'Owned', value: 'Owned' },
                    { label: 'Leased', value: 'Leased' },
                    { label: 'NA', value: 'NA' },
                  ]}
                  optionLabel={'label'}
                  optionValue={'value'}
                />
              </div>
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={
                  'Include separate amounts for base rent, property taxes, property insurance, and CAM'
                }
                value={rent}
                setter={setRent}
                label={'Rent: Base + CAM/Triple Net/All Inclusive:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'Describe in detail the length remaining/ expiration on the current term and any details on options to extend the lease. '
                }
                value={lease_term}
                setter={setLease_term}
                label={'Lease term/Options:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={
                  'How is your relationship with landlord and how flexible they are about lease assignment and lease extention?'
                }
                value={relation_with_landlord_lease_flexibility}
                setter={setrelationshipWithLandlord}
                label={'Relationship with landlord & lease flexibility:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12}>
              <div className={classes.input_main}>
                <DropDown
                  value={sell_property}
                  setter={setsell_property}
                  label={'If CRE is owned, do you wish to sell the property?:'}
                  placeholder={'Choose an option'}
                  labelClassName={classes.dropdownLabel}
                  customStyle={{
                    boxShadow: '0px 0 5px 2px #0000000d',
                    borderRadius: '10px',
                    border: 'none',
                  }}
                  options={[
                    {
                      label: 'Choose an option',
                      value: 'null',
                    },
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  optionLabel={'label'}
                  optionValue={'value'}
                />
              </div>
            </Col>
            <Col md={12} className={classes.inputCol}>
              <TextArea
                rows={1}
                placeholder={'Describe in detail if applicable.'}
                value={special_zone_requirement}
                setter={setSpecial_zone_requirement}
                label={'Special zoning requirements:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                className={classes.textAreaBox}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={'What do you feel your business is worth?'}
                value={business_price}
                setter={setBusiness_price}
                label={'Business price:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={
                  'What do you feel your CRE is worth (if applicable)?'
                }
                value={CRE_price}
                setter={setCRE_price}
                label={'CRE Price:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12} className={classes.inputCol}>
              <Input
                placeholder={
                  'How much inventory do you have (wholesale value)?'
                }
                value={inventory_value}
                setter={setInventory_value}
                label={'Inventory Value:'}
                labelStyle={{
                  color: 'var(--dashboard-main-color)',
                  textTransform: 'none',
                  fontFamily: 'Open-Sans-bold',
                  fontSize: '16px',
                }}
                customStyle={{
                  boxShadow: '0px 0 5px 2px #0000000d',
                  borderRadius: '10px',
                  border: 'none',
                }}
                customClass={classes.placeholderText}
              />
            </Col>
            <Col md={12}>
              <div className={classes.input_main}>
                <DropDown
                  value={motivation_level}
                  setter={setMotivation_level}
                  label={
                    'What is your motivation to sell your business? (1 not motivated, 5 extremely motivated):'
                  }
                  placeholder={'Choose an option'}
                  labelClassName={classes.dropdownLabel}
                  customStyle={{
                    boxShadow: '0px 0 5px 2px #0000000d',
                    borderRadius: '10px',
                    border: 'none',
                  }}
                  options={[
                    {
                      label: 'Choose an option',
                      value: 'null',
                    },
                    { label: '5', value: 5 },
                    { label: '4', value: 4 },
                    { label: '3', value: 3 },
                    { label: '2', value: 2 },
                    { label: '1', value: 1 },
                  ]}
                  optionLabel={'label'}
                  optionValue={'value'}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className={classes.input_main}>
                <DropDown
                  value={is_interest_in_finance}
                  setter={setIs_interest_in_finance}
                  label={'Are you able to/interested in seller financing?:'}
                  placeholder={'Choose an option'}
                  labelClassName={classes.dropdownLabel}
                  customStyle={{
                    boxShadow: '0px 0 5px 2px #0000000d',
                    borderRadius: '10px',
                    border: 'none',
                  }}
                  options={[
                    {
                      label: 'Choose an option',
                      value: 'null',
                    },
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  optionLabel={'label'}
                  optionValue={'value'}
                />
              </div>
            </Col>
          </Row>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default BVIEditorModal;
