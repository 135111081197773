import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { BaseURL } from '../../../config/apiUrl';
import { Get } from '../../../Axios/AxiosFunctions';
import FolderAndFileBox from '../../../Component/FolderAndFileBox';
import Loader from '../../../Component/Loader';
import NoData from '../../../Component/NoData/NoData';
import SearchInput from '../../../Component/SearchInput';
import { Button } from '../../../Component/Button/Button';
import classes from './ListingList.module.css';

const ListingList = ({ status, onBack }) => {
  const { access_token: token, company_id: companyId } = useSelector(
    (state) => state?.authReducer,
  );
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);

  const [listingFolders, setListingFolders] = useState([]);
  const [folders, setFolders] = useState([]);

  const getListingFolders = async () => {
    const url = BaseURL(
      `data-room${status === 'all' ? '' : `?status=${status}`}`,
    );
    setLoading(true);
    const response = await Get(url, token, companyId);
    if (response !== undefined) {
      setListingFolders(response?.data?.data?.folders);
      setFolders(response?.data?.data?.folders);
    }
    setLoading(false);
  };

  const handleSearch = (searchValue) => {
    setSearch(searchValue);

    const value = searchValue?.trim()?.toLowerCase();

    const data = listingFolders?.filter((data) =>
      `${data?.business?.companyName || data?.name}`
        ?.toLowerCase()
        ?.includes(value),
    );

    setFolders(data);
  };

  useEffect(() => {
    if (token) {
      getListingFolders();
    }
  }, [token]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.foldersDiv}>
        <div className={classes.headerContainer}>
          <h4>Documents</h4>
          <div className={classes.btnContainer}>
            <Button label={'Back'} onClick={onBack} />
            <SearchInput
              setter={(e) => handleSearch(e)}
              value={search}
              placeholder={'Search'}
            />
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className={classes.mainFoldersDiv}>
            {folders && !folders?.length && <NoData text="No Folders Found" />}

            {folders && folders?.length > 0 && (
              <div className={classes.folderBox}>
                {folders?.map((item) => (
                  <div className={classes.folderInnerBox}>
                    <FolderAndFileBox
                      key={item?.id}
                      data={item}
                      onClick={() => {
                        navigate(
                          `/documents/listing/${item?._id}/${item?.business?._id}`,
                        );
                      }}
                      allowDelete={false}
                      allowEdit={false}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingList;
