import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { MdPercent, MdSend } from 'react-icons/md';
import { BiDollar } from 'react-icons/bi';
import { RiRobot2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

import {
  apiHeader,
  BaseURL,
  formRegEx,
  formRegExReplacer,
} from '../../config/apiUrl';
import {
  amountTypeOptions,
  createListingstatusOptions,
  leadSourceOptions,
  onlinePresenceOptions,
} from '../../constant/commonData';
import {
  changeStringList,
  changeStringToList,
  numberInputValidator,
  parseNoteBox,
} from '../../utils/functions';
import { Get, Patch, Post, Delete } from '../../Axios/AxiosFunctions';
import { CsvToJsonConvertion } from '../../Helper/CsvToJsonConvertion';
import useDebounce from '../../CustomHooks/useDebounce';
import Loader from '../../Component/Loader';
import { Radio } from '../../Component/Radio/Radio';
import AddMoreBtn from '../../Component/AddMoreBtn';
import { Input } from '../../Component/Input/Input';
import { TextArea } from '../../Component/TextArea';
import { Button } from '../../Component/Button/Button';
import UploadCSVBox from '../../Component/UploadCsvBox';
import UploadImageBox from '../../Component/UploadImageBox';
import { DropDown } from '../../Component/DropDown/DropDown';
import { Checkbox } from '../../Component/Checkbox/Checkbox';
import AddMultiItemYearWithList from '../../Component/AddMultiItemYearWithList';
import AddMultiMapInputContainer from '../../Component/AddMultiMapnputContainer';
import AddMultiValueInputContainer from '../../Component/AddMultiValueInputContainer';
import AddMultiValueWithMultipleInput from '../../Component/AddMultiValueWithMultipleInput';
import AddMultiValueInputWithDropDownContainer from '../../Component/AddMultiValueInputWithDropDownContainer';
import AreYouSureModal from '../../modals/AreYouSureModal';
import BVIModal from '../../modals/BVIModal';
import CIMConfirmModal from '../../modals/CIMConfirmModal';
import classes from './EditListing.module.css';

const EdiListing = ({ flag = false, setSharedStatus }) => {
  const navigate = useNavigate();
  const { access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state?.authReducer || {},
  );

  const slug = useParams()?.slug;
  const [searchParams] = useSearchParams();

  const [allOwners, setAllOwners] = useState([]);
  const [allBuyer, setAllBuyer] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allIndustries, setAllIndustries] = useState([]);
  const [allAdminBroker, setAllAdminBroker] = useState([]);
  const [allEntities, setAllEntities] = useState([]);

  const [allSellerSearch, setAllSellerSearch] = useState('');
  const sellerDebouncedSearch = useDebounce(allSellerSearch, 1000);

  const [allBuyerSearch, setAllBuyerSearch] = useState('');
  const buyerDebouncedSearch = useDebounce(allBuyerSearch, 1000);

  const [probability, setProbability] = useState(0);

  const [commission, setCommission] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [applicableCommission, setApplicableCommission] = useState(0);

  const [realEstateCommission, setRealEstateCommission] = useState(0);
  const [realEstatePercentage, setRealEstatePercentage] = useState(0);
  const [realEstateApplicableCommission, setRealEstateApplicableCommission] =
    useState(0);

  const [listingData, setListingData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState(null);
  const [listingId, setListingId] = useState('');
  const [entity, setEntity] = useState(null);
  // For Places Api
  const [bAddr, setBAddr] = useState('');
  const [businessAddress, setBusinessAddress] = useState([]);
  const [businessAddressCoordinates, setBusinessAddressCoordinates] = useState(
    [],
  );
  const [locationCounter, setLocationCounter] = useState(1);
  const [businessAddressDetail, setBusinessAddressDetail] = useState([]);
  const [businessOpportunity, setBusinessOpportunity] = useState('');
  const [realState, setRealState] = useState([]);
  const [cashFlow, setCashFlow] = useState('');
  const [inventory, setInventory] = useState('');
  const [salesRevenue, setSalesRevenue] = useState('');
  const [ebitda, setEbitda] = useState('');
  const [fullTimeEmployee, setFullTimeEmployee] = useState('');
  const [businessLocation, setBusinessLocation] = useState('');
  const [partTimeEmployee, setPartTimeEmployee] = useState('');
  const [employeesDescription, setEmployeesDescription] = useState('');
  const [businessPlacesApiAddress, setBusinessPlacesApiAddress] = useState([]);
  const [ownerInvolvment, setOwnerInvolvment] = useState('');
  const [reasonForSelling, setReasonForSelling] = useState('');
  const [dummyDescription, setDummyDescription] = useState('');
  const [realDescription, setRealDescription] = useState('');
  const [busniessHighlightText, setBusniessHighlightText] = useState('');
  const [businessHighlights, setBusinessHighlights] = useState([]);
  const [onlinePresenceInputState, setOnlinePresenceInputState] = useState({
    key: '',
    link: '',
  });
  const [onlinePresence, setOnlinePresence] = useState([]);

  const [prosText, setProsText] = useState('');
  const [pros, setPros] = useState([]);
  const [consText, setConsText] = useState('');
  const [cons, setCons] = useState([]);
  const [propertyInformation, setPropertyInformation] = useState({
    title: '',
    description: '',
    leaseRate: '',
    leaseInformation: '',
  });

  const [hoursOfOperationText, setHoursOfOperationText] = useState({
    days: '',
    hours: '',
  });
  const [hoursOfOperation, setHoursOfOperation] = useState([]);
  const [
    hoursOfOperationOpportunityDescription,
    setHoursOfOperationOpportunityDescription,
  ] = useState('');
  const [financingOptions, setFinancingOptions] = useState([]);
  const [recentImprovements, setRecentImprovements] = useState([]);
  const [financialCSV, setFinancialCSV] = useState([{}, {}]);
  const [convertedFinancialCSV, setConvetedFinancialCSV] = useState([{}, {}]);
  const [financialDescription, setFinancialDescription] = useState('');
  const [financialImages, setFinancialImages] = useState([{}, {}, {}]);
  const [financialTitle, setFinancialTitle] = useState('');
  const [demographics, setDemographics] = useState('');
  const [demographicTitle, setDemographicTitle] = useState('');
  const [galleryImages, setGalleryImages] = useState([{}, {}, {}, {}, {}]);
  const [pictureDemo, setPictureDemo] = useState(null);
  const [assignBroker, setAssignBroker] = useState([]);
  const [soldDate, setSoldDate] = useState('');
  const [soldPrice, setSoldPrice] = useState(0);
  const [assignBuyer, setAssignBuyer] = useState([]);

  const [companyName, setCompanyName] = useState('');
  const [amountTypeAndValue, setAmountTypeAndValue] = useState({
    ...amountTypeOptions[0],
    amount: 0,
    info1: '',
    info2: 0,
  });
  const [buildingSf, setBuildingSf] = useState('');
  const [category, setCategory] = useState([]);
  const [owner, setOwner] = useState([]);
  const [autoNDA, setAutoNDA] = useState('');

  // state

  // For Deleting Images
  const [deletingImages, setDeletingImages] = useState({
    financialsAnalysis: [],
    images: [],
    financialsCSVImages: [],
    deletedFinancialsCSVImages: [],
    isDeleting: false,
  });
  // Financial Options
  const [financialsMediaType, setFinancialsMediaType] = useState('Images');
  const [financialsCSVImages, setFinancialsCSVImages] = useState([{}, {}]);
  const [isSaving, setIsSaving] = useState(false);
  const [leadSource, setLeadSource] = useState({});

  const [order, setOrder] = useState('');
  const [isFeatured, setIsFeatured] = useState('');

  // New State End
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isOpenBVIModal, setIsOpenBVIModal] = useState(false);

  const [isGeneratingCim, setIsGeneratingCim] = useState(false);
  const [cimData, setCimData] = useState(null);
  const [showCIMConfirmModal, setShowCIMConfirmModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState('');

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const [strengths, setStrengths] = useState([]);
  const [weaknesses, setWeaknesses] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [threats, setThreats] = useState([]);

  const makeNoteBox = (str) => {
    try {
      const notes = JSON.parse(str);
      let retVal = '';
      const keys = Object.keys(notes);
      Object.values(notes).forEach((e, i) => {
        if (e.value) retVal += `${keys[i]}: "${e.value}"\n`;
        else retVal += `${keys[i]}: "${e}"\n`;
      });
      return retVal;
    } catch (e) {
      return '';
    }
  };

  // For Api's States
  const [isPosting, setIsPosting] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState('');
  const [isUpdating, setUpdating] = useState('');

  // Geting data with the slug
  const getData = async () => {
    const url = BaseURL(`business/with-slug/${slug}`);
    setIsLoading(true);
    const response = await Get(url, accessToken, companyId);
    setIsLoading(false);
    if (response?.data?.data?.business) {
      const newData = response?.data?.data?.business;
      setListingData(newData);
      console.log(response?.data?.data);
      setTitle(newData?.title);
      const s = createListingstatusOptions?.find(
        (item) => item?.value == newData?.status,
      );
      setStatus(s);
      setSoldPrice(newData?.soldPrice || 0);
      if (newData?.soldDate) {
        const formattedDate = newData?.soldDate?.split('T')[0];
        setSoldDate(formattedDate);
      }
      setInventory(newData?.inventory);
      setCashFlow(newData?.cashFlow);
      setRealState(
        Array.isArray(newData?.industry)
          ? newData?.industry
          : newData?.industry
            ? [newData?.industry]
            : newData?.industry,
      );
      setBusinessOpportunity(newData?.businessOpportunity);
      setListingId(newData?.refId);
      setFullTimeEmployee(newData?.fullTimeEmployees);
      setPartTimeEmployee(newData?.partTimeEmployees);
      setEmployeesDescription(newData?.employeesDescription);
      setSalesRevenue(newData?.grossSales);
      setEbitda(newData?.ebitda);
      setRealDescription(newData?.description);
      setBuildingSf(newData?.buildingSF);
      setDummyDescription(newData?.dummyDescription);

      setProbability(newData?.probability);
      setCommission(newData?.commission);
      setPercentage(newData?.percentage);
      setRealEstateCommission(newData?.realEstateCommission);
      setRealEstatePercentage(newData?.realEstatePercentage);

      setAmountTypeAndValue({
        ...amountTypeOptions?.find(
          (item) => item?.value == newData?.amountType,
        ),
        amount:
          newData?.amountType == 'rent'
            ? newData?.monthlyRent
            : newData?.amountType == 'real-estate'
              ? newData?.realEstate
              : newData?.businessLocation,
        info1:
          newData?.amountType == 'rent'
            ? newData?.leaseInformation
            : newData?.amountType == 'real-estate'
              ? newData?.realEstateInformation
              : '',
        info2:
          newData?.amountType == 'rent'
            ? newData?.leaseRate
            : newData?.amountType == 'real-estate'
              ? newData?.realEstatePrice
              : 0,
      });
      setOwnerInvolvment(newData?.ownerInvolvment);
      setOnlinePresence(newData?.thirdPartyPresence);
      setPros(newData?.pros);
      setCons(newData?.cons);
      setHoursOfOperation(newData?.hoursOfOperation);
      setFinancingOptions(
        newData?.financingOptions?.map((item) => ({ label: item })),
      );
      setReasonForSelling(newData?.reason);
      setCompanyName(newData?.companyName);
      setEntity(newData?.entity);
      setCategory(newData?.category || []);
      setBusinessHighlights(newData?.businessHighlights);
      setAssignBroker(newData?.broker);
      setLeadSource(
        leadSourceOptions?.find((item) => item?.value == newData?.leadSource),
      );
      setBusinessLocation(newData?.businessLocation);
      setPictureDemo(newData?.dummyImage);
      setOwner(newData?.owner || []);
      setPropertyInformation(newData?.propertyInformation);
      setFinancialImages(newData?.financialsAnalysis);
      setFinancialTitle(newData?.financialTitle || 'Financial Analysis');
      setFinancialDescription(newData?.financialsDescription);
      setRecentImprovements(newData?.recentImprovements);
      setDemographics(newData?.demographics);
      setDemographicTitle(
        newData?.demographicTitle || 'Demographics for Colorado',
      );
      if (typeof newData?.businessAddress == 'string') {
        setBusinessAddress([newData?.businessAddress]);
      } else if (!newData?.businessAddress?.length) {
        setBusinessAddress([]);
      } else {
        setBusinessAddress(newData?.businessAddress);
      }
      console.log('newData?.googleMapAddress', newData?.googleMapAddress);
      if (typeof newData?.googleMapAddress == 'string') {
        setBusinessPlacesApiAddress([newData?.googleMapAddress]);
        setLocationCounter(1);
      } else if (!newData?.googleMapAddress?.length) {
        setBusinessPlacesApiAddress([]);
        setLocationCounter(1);
      } else {
        setBusinessPlacesApiAddress(newData?.googleMapAddress);
        setLocationCounter(newData?.googleMapAddress?.length);
      }
      setGalleryImages(newData?.images);
      setHoursOfOperationOpportunityDescription(
        newData?.hoursOfOperationOpportunity,
      );
      setFinancialsCSVImages(newData?.financialsCSVImages);
      setFinancialsMediaType(
        newData?.financialsCSVImages?.length > 0 ? 'Images' : 'CSVs',
      );
      let addressCoordinates = [];
      if (newData?.location?.type) {
        addressCoordinates.push({
          lat: newData?.location?.coordinates[1],
          lng: newData?.location?.coordinates[0],
        });
      } else {
        newData?.location?.map((item) => {
          addressCoordinates.push({
            lat: item?.coordinates[1],
            lng: item?.coordinates[0],
          });
        });
      }
      setBusinessAddressCoordinates(addressCoordinates);
      setStrengths(newData?.strengths || []);
      setWeaknesses(newData?.weaknesses || []);
      setOpportunities(newData?.opportunities || []);
      setThreats(newData?.threats || []);

      const zip = (...arrays) => {
        const length = Math.min(...arrays.map((arr) => arr.length));
        return Array.from({ length }, (_, i) => arrays.map((arr) => arr[i]));
      };

      const city =
        typeof newData?.city == 'string' ? [newData?.city] : newData?.city;
      const country =
        typeof newData?.country == 'string'
          ? [newData?.country]
          : newData?.country;
      const state =
        typeof newData?.state == 'string' ? [newData?.state] : newData?.state;

      const addressDetails = zip(city || [], country || [], state || []).map(
        ([city, country, state]) => ({
          city: city || '',
          country: country || '',
          state: state || '',
        }),
      );

      console.log('addressDetails', addressDetails);

      setBusinessAddressDetail(addressDetails);
      setAutoNDA(
        newData?.autoNdaApprove
          ? 'Do you want to allow auto nda approve to this listing?'
          : '',
      );
      setOrder(newData?.order);
      setIsFeatured(newData?.isFeatured ? 'Featured' : '');
      setFinancialCSV([
        newData?.financialsCSV1?.map((item) => ({
          column1: item?.column1,
          column2: item?.column2,
          column3: item?.column3,
          column4: item?.column4,
          column5: item?.column5,
          column6: item?.column6,
        })),
        newData?.financialsCSV2?.map((item) => ({
          column1: item?.column1,
          column2: item?.column2,
          column3: item?.column3,
          column4: item?.column4,
          column5: item?.column5,
          column6: item?.column6,
        })),
      ]);

      setConvetedFinancialCSV([
        newData?.financialsCSV1?.map((item) => ({
          column1: item?.column1,
          column2: item?.column2,
          column3: item?.column3,
          column4: item?.column4,
          column5: item?.column5,
          column6: item?.column6,
        })),
        newData?.financialsCSV2?.map((item) => ({
          column1: item?.column1,
          column2: item?.column2,
          column3: item?.column3,
          column4: item?.column4,
          column5: item?.column5,
          column6: item?.column6,
        })),
      ]);
      console.log({ noteBox: newData?.noteBox });
      if (Array.isArray(newData?.noteBox)) {
        setNotes(newData?.noteBox);
      } else {
        if (newData?.owner?.length && newData?.noteBox) {
          setNotes([
            {
              seller: newData?.owner[0],
              bvi: newData?.noteBox,
            },
          ]);
        }
      }
      if (newData?.buyer) {
        setAssignBuyer(newData?.buyer);
      }
    }
  };

  const validateCIM = async () => {
    const valuation = convertedFinancialCSV.flat();
    let msg = 'Are you sure you wnat to submit without';
    let flag = 0;
    if (!valuation?.length) {
      msg += ' Financials CSV';
      flag++;
    }
    if (!onlinePresence?.find((ele) => ele?.key == 'website')) {
      if (flag > 0) msg += ',';
      msg += ' Website';
      flag++;
    }
    if (businessAddress?.length == 0) {
      if (flag > 0) msg += ',';
      msg += ' Business Address';
    }
    if (flag > 0) {
      msg += '?';
      setShowConfirm(msg);
      return;
    }
    generateCIM();
  };

  const generateCIM = async () => {
    const bvi = parseNoteBox(listingData?.noteBox[0]?.bvi);
    setIsGeneratingCim(true);
    const valuation = convertedFinancialCSV.flat();
    const url = BaseURL('business/admin/generate-cim');
    const body = {
      listingId: listingData?._id,
      companyName: listingData?.companyName,
      valuation,
      bvi,
      businessAddress,
      website: onlinePresence?.find((ele) => ele?.key == 'website'),
    };
    const res = await Post(url, body, apiHeader(accessToken));
    setIsGeneratingCim(false);
    setShowConfirm('');
    if (res?.data?.message?.content) {
      try {
        let raw = res?.data?.message?.content;
        const startIndex = raw?.indexOf('{');
        const endIndex = raw?.lastIndexOf('}') + 1;

        if (startIndex === -1 || endIndex === -1) {
          return toast.error(`Failed to generate CIM!`);
        }
        raw = raw.slice(startIndex, endIndex).replaceAll('\n', '');

        console.log(raw);
        const data = JSON.parse(raw);
        setShowCIMConfirmModal(true);
        setCimData(data);
        toast.success(`CIM generated successfully!`);
      } catch (error) {
        console.log('error', error);
        console.log('data', res?.data?.message?.content);
        toast.error(`CIM format is invalid!`);
      }
    } else {
      toast.error(`Failed to generate CIM!`);
    }
  };

  const onCimConfirm = (data) => {
    setCimData(data);
    toast.success(`CIM successfully set up!`);
    setRealDescription(data?.realDescription);
    setDummyDescription(data?.dummyDescription);
    setBusinessHighlights([...data?.highlight]);
    setPros([...data?.pros]);
    setCons([...data?.cons]);
    setOwnerInvolvment(data?.ownerInvolvment);
    setEmployeesDescription(data?.employeesDescription);
    setStrengths(data?.strengths);
    setWeaknesses(data?.weaknesses);
    setOpportunities(data?.opportunities);
    setThreats(data?.threats);
    setDemographicTitle(data?.demographicTitle);
    setDemographics(data?.demographics);
    setShowCIMConfirmModal(false);
    setBusinessOpportunity(data?.businessOpportunity);
    setInventory(data?.inventory);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const y = (percentage * businessOpportunity) / 100;
    setApplicableCommission(commission > y ? commission : y);
  }, [businessOpportunity, commission, percentage]);

  useEffect(() => {
    const y = (realEstatePercentage * Number(amountTypeAndValue?.amount)) / 100;
    setRealEstateApplicableCommission(
      realEstateCommission > y ? realEstateCommission * 1 : y,
    );
  }, [amountTypeAndValue, realEstateCommission, realEstatePercentage]);

  useEffect(() => {
    if (!allSellerSearch) {
      setAllOwners([]);
      return;
    }
    Get(
      BaseURL(`users/sellers/all?search=${allSellerSearch}`),
      accessToken,
      companyId,
    ).then((res) => {
      if (Array.isArray(res?.data?.data)) {
        console.log(res?.data?.data?.length);
        setAllOwners(res?.data?.data);
      } else {
        setAllOwners([]);
      }
    });
  }, [sellerDebouncedSearch]);

  useEffect(() => {
    if (!allBuyerSearch) {
      setAllBuyer([]);
      return;
    }
    Get(
      BaseURL(`users/buyers/all?search=${allBuyerSearch}`),
      accessToken,
      companyId,
    ).then((res) => {
      if (Array.isArray(res?.data?.data)) {
        console.log(res?.data?.data?.length);
        setAllBuyer(res?.data?.data);
      } else {
        setAllBuyer([]);
      }
    });
  }, [buyerDebouncedSearch]);

  useEffect(() => {
    const fetchBrokers = Get(
      BaseURL('users/brokers/all'),
      accessToken,
      companyId,
    );
    const fetchCategories = Get(
      BaseURL('categories/admin/all'),
      accessToken,
      companyId,
    );
    const fetchIndustries = Get(
      BaseURL('industries/admin/all'),
      accessToken,
      companyId,
    );
    const fetchEntities = Get(BaseURL(`entity`), accessToken, companyId);

    fetchBrokers
      .then((response) => {
        if (Array.isArray(response.data.data.data)) {
          setAllAdminBroker(response.data.data.data);
        } else if (typeof response.data.data.data === 'string') {
          toast.error(response.data.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching brokers:', error);
        toast.error('An error occurred while fetching brokers');
      });

    fetchCategories
      .then((response) => {
        if (Array.isArray(response?.data?.data)) {
          setAllCategories(response?.data?.data);
        } else if (typeof response.data.data === 'string') {
          toast.error(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
        toast.error('An error occurred while fetching categories');
      });

    fetchIndustries
      .then((response) => {
        if (Array.isArray(response?.data?.data)) {
          setAllIndustries(response?.data?.data);
        } else if (typeof response.data.data === 'string') {
          toast.error(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching industries:', error);
        toast.error('An error occurred while fetching industries');
      });

    fetchEntities
      .then((response) => {
        if (Array.isArray(response?.data?.data)) {
          setAllEntities(response?.data?.data);
        } else if (typeof response?.data?.data === 'string') {
          toast.error(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching entities:', error);
        toast.error('An error occurred while fetching entities');
      });
  }, []);

  // For Upload Images
  const uploadImages = async (updatedSlug) => {
    const url = BaseURL('business/update-images');
    const filesArray = {
      ...(galleryImages?.findIndex((item) => typeof item == 'object') !==
        -1 && {
        images: galleryImages?.filter((item) => typeof item == 'object'),
      }),
      ...(financialImages?.findIndex((item) => typeof item == 'object') !==
        -1 && {
        financialsAnalysis: financialImages?.filter(
          (item) => typeof item == 'object',
        ),
      }),
      ...(financialsCSVImages?.findIndex((item) => typeof item == 'object') !==
        -1 && { financialsCSVImages }),
      ...(deletingImages?.financialsAnalysis?.length > 0 && {
        deletedFinancialsAnalysis: deletingImages?.financialsAnalysis,
      }),
      ...(deletingImages?.images?.length > 0 && {
        deletedImages: deletingImages?.images,
      }),
      ...(deletingImages?.deletedFinancialsCSVImages?.length > 0 && {
        deletedFinancialsCSVImages: deletingImages?.deletedFinancialsCSVImages,
      }),
    };
    let formData = new FormData();

    for (let key in filesArray) {
      filesArray[key]?.map((item) => {
        if (key === 'deletedFinancialsAnalysis') {
          formData.append(
            deletingImages.financialsAnalysis?.length > 1
              ? `${key}`
              : `${key}[]`,
            item,
          );
        } else if (key === 'deletedImages') {
          formData.append(
            deletingImages.images?.length > 1 ? `${key}` : `${key}[]`,
            item,
          );
        } else if (key === 'deletedFinancialsCSVImages') {
          formData.append(
            deletingImages.deletedFinancialsCSVImages?.length > 1
              ? `${key}`
              : `${key}[]`,
            item,
          );
        } else formData.append(`${key}`, item);
      });
    }

    formData.append('dummyImage', pictureDemo);
    formData.append('slug', updatedSlug);

    const res = await Patch(
      url,
      formData,
      apiHeader(accessToken, true),
      companyId,
    );
    setDeletingImages({
      financialsAnalysis: [],
      images: [],
      deletedFinancialsCSVImages: [],
      isDeleting: false,
    });
    return res;
  };

  const editListing = async (isPost = false) => {
    if (!owner) {
      return toast.error('Please fill the seller field');
    }
    if (!['prospect', 'broker-review'].includes(status?.value)) {
      if (
        Number(businessOpportunity) != 0 &&
        Number(applicableCommission) == 0
      ) {
        return toast.error('Please fill the commission field');
      }
      if (
        amountTypeAndValue?.value == 'real-estate' &&
        Number(amountTypeAndValue?.amount) != 0 &&
        Number(realEstateApplicableCommission) == 0
      ) {
        return toast.error('Please fill the real estate commission field');
      }
    }
    const postedStatus = {
      prospect: 'broker-review',
      'broker-review': 'seller-review',
    };

    const url = BaseURL('business/update');

    let params = {
      status: isPost ? postedStatus[status?.value] : status?.value,
      category: category?.map((ele) => ele?._id),
    };
    console.log(isPost, params, status);
    isPost &&
      setStatus({
        value: postedStatus[status?.value],
        label: postedStatus[status?.value],
      });
    setSharedStatus(isPost ? postedStatus[status?.value] : status?.value);

    if (status?.value == 'under-contract') {
      if (soldDate == '' || soldDate == null) {
        return toast.error(`Please fill the close date field`);
      }
      if (!soldPrice || soldPrice == '' || soldPrice == null) {
        return toast.error(`Please fill the UC price field`);
      }
      if (!assignBuyer || assignBuyer?.length == 0) {
        return toast.error(`Please choose buyer`);
      }
      if (soldPrice < 0) {
        return toast.error(`The UC price should be greater than 0`);
      }
    }

    if (status?.value == 'sold') {
      if (soldDate == '' || soldDate == null) {
        return toast.error(`Please fill the sold date field`);
      }
      if (!soldPrice || soldPrice == '' || soldPrice == null) {
        return toast.error(`Please fill the sold price field`);
      }
      if (!assignBuyer || assignBuyer?.length == 0) {
        return toast.error(`Please choose buyer`);
      }
      if (soldPrice < 0) {
        return toast.error(`The sold price should be greater than 0`);
      }
    }

    // Array Validations
    console.log(assignBroker);
    let array = {
      broker: assignBroker?.map((item) => item?._id),
    };

    let buyerArray = {
      buyer: assignBuyer?.map((item) => item?._id),
    };

    for (let key in array) {
      if (key == 'recentImprovements') {
        for (let a in array[key])
          if (array[key][a]?.features?.length == 0)
            return toast.error(`Please fill the recent improvements features`);
      }
      if (array[key]?.length == 0) {
        return toast.error(
          `Please fill the ${key
            .replace(formRegEx, formRegExReplacer)
            .toLowerCase()}`,
        );
      }
    }

    let longitude = [];
    let latitude = [];
    let country = [];
    let state = [];
    let city = [];
    console.log('businessAddressCoordinates', businessAddressCoordinates);
    console.log('businessAddressDetail', businessAddressDetail);
    businessAddressCoordinates?.forEach((item) => {
      item?.lng && longitude.push(item?.lng);
      item?.lng && latitude.push(item?.lat);
    });
    businessAddressDetail?.forEach((item) => {
      item?.country && country.push(item?.country);
      item?.state && state.push(item?.state);
      item?.city && city.push(item?.city);
    });

    // For Form Data
    params = {
      ...params,
      ...array,
      ...buyerArray,
      propertyInformation,
      longitude,
      latitude,
      country,
      state,
      city,
      ...(financialsMediaType !== 'Images' && {
        financialsCSV1: convertedFinancialCSV[0],
        financialsCSV2: convertedFinancialCSV[1],
      }),
      ...(financialsMediaType !== 'Images'
        ? {
            financialsCSV1: convertedFinancialCSV[0],
            financialsCSV2: convertedFinancialCSV[1],
          }
        : {
            financialsCSV1: [],
            financialsCSV2: [],
          }),
      slug: slug,
      noteBox: notes,
    };

    console.log(typeof realEstateCommission);

    params = {
      ...params,
      probability: probability || 0,
      commission: commission || 0,
      percentage: percentage || 0,
      applicableCommission: applicableCommission || 0,
      realEstateCommission: realEstateCommission || 0,
      realEstatePercentage: realEstatePercentage || 0,
      realEstateApplicableCommission: realEstateApplicableCommission || 0,
    };

    params = {
      ...params,
      // title added
      title,
      autoNdaApprove: autoNDA == '' ? false : true,
      fullTimeEmployees: Number(fullTimeEmployee),
      partTimeEmployees: Number(partTimeEmployee),
      employeesDescription,
      googleMapAddress: businessPlacesApiAddress,
      owner: owner?.map((item) => item?._id),
      // New Changes
      refId: listingId,
      soldDate: soldDate,
      soldPrice: Number(soldPrice),
      businessAddress,
      buildingSF: buildingSf,
      companyName,
      entity: entity?._id,
      ownerInvolvment,
      reason: reasonForSelling,
      dummyDescription,
      description: realDescription,
      financialsDescription: financialDescription,
      industry: realState?.map((item) => item?._id),
      pros,
      cons,
      businessHighlights,
      thirdPartyPresence: onlinePresence,
      hoursOfOperation,
      recentImprovements,
      leadSource: leadSource?.value,
      // financialsCSVImages,
      financingOptions: financingOptions?.map((item) => item?.label),
      amountType: amountTypeAndValue?.value,
      ...(amountTypeAndValue?.value == 'rent'
        ? {
            monthlyRent: Number(amountTypeAndValue?.amount),
            leaseInformation: String(amountTypeAndValue?.info1),
            leaseRate: String(amountTypeAndValue?.info2),
          }
        : amountTypeAndValue?.value == 'real-estate'
          ? {
              realEstate: Number(amountTypeAndValue?.amount),
              realEstateInformation: String(amountTypeAndValue?.info1),
              realEstatePrice: String(amountTypeAndValue?.info2),
            }
          : {
              businessLocation: String(amountTypeAndValue?.amount),
            }),
      cashFlow: Number(cashFlow),
      inventory: Number(inventory),
      grossSales: Number(salesRevenue),
      ebitda: Number(ebitda),
      businessOpportunity: Number(businessOpportunity),
      hoursOfOperationOpportunity: hoursOfOperationOpportunityDescription,
      order: Number(order),
      isFeatured: isFeatured == '' ? false : true,
      demographicTitle,
      demographics,
      financialTitle,
      strengths: strengths?.filter((e) => e),
      weaknesses: weaknesses?.filter((e) => e),
      opportunities: opportunities?.filter((e) => e),
      threats: threats?.filter((e) => e),
    };

    isPost ? setIsPosting(true) : setIsSaving(true);
    const response = await Patch(
      url,
      params,
      apiHeader(accessToken),
      companyId,
    );
    isPost ? setIsPosting(false) : setIsSaving(false);

    if (
      response?.response?.status == 400 ||
      response?.response?.status == 500
    ) {
      if (response?.response?.data?.message) {
        toast.error(response?.response?.data?.message[0]);
      }
    } else {
      (galleryImages?.findIndex((item) => typeof item == 'object') !== -1 ||
        financialImages?.findIndex((item) => typeof item == 'object') !== -1 ||
        (typeof pictureDemo == 'object') !== -1 ||
        (financialsMediaType == 'Images' &&
          financialsCSVImages?.findIndex((item) => typeof item == 'object') !==
            -1) ||
        deletingImages?.isDeleting) &&
        (await uploadImages(response?.data?.slug));
      toast.success('Listing updated successfully');
      if (
        listingData?.companyName != companyName ||
        listingData?.title != title
      ) {
        navigate('/listings?tab=all');
      }
      setListingData(response?.data);
    }
  };

  const removeBusiness = async (item) => {
    const url = BaseURL(`business/${listingData?.refId}`);
    setUpdating('delete');
    await Delete(url, null, apiHeader(accessToken));
    setUpdating('');
    setIsOpenModal('');
    navigate('/listings?tab=all');
  };

  const sendDataRoomEmail = async () => {
    const url = BaseURL('business/admin/send-dataroom-email');
    const body = {
      business: listingData?._id,
    };
    setIsSendingEmail(true);
    const response = await Post(url, body, apiHeader(accessToken), companyId);
    setIsSendingEmail(false);
    if (response?.data) {
      toast.success('Sent email successfully.');
      return;
    }
    toast.error('Failed to send email.');
  };

  return (
    <>
      <div className={flag && classes.fContainerMain}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={classes.main_heading}>
              <h4>Edit Listing</h4>
              <div style={{ display: 'flex', gap: '5px' }}>
                {['prospect', 'broker-review'].includes(status?.value) && (
                  <Button
                    label={
                      isSendingEmail ? 'Sending...' : 'Send Data Room Email'
                    }
                    disabled={isSendingEmail}
                    className={classes.submit_btn}
                    onClick={sendDataRoomEmail}
                    leftIcon={
                      <MdSend size={25} style={{ marginRight: '10px' }} />
                    }
                  />
                )}
                <Checkbox
                  setValue={(e) => setIsFeatured(e == 'Featured')}
                  value={isFeatured ? 'Featured' : ''}
                  label={'Featured'}
                />
                <Checkbox
                  value={autoNDA ? 'Automated' : ''}
                  setValue={(e) => setAutoNDA(e == 'Automated')}
                  label={'Automated'}
                />
                {[
                  'prospect',
                  'broker-review',
                  'seller-review',
                  'published',
                ].includes(status?.value) && (
                  <Button
                    label={isGeneratingCim ? 'Wait...' : 'CIM'}
                    disabled={isSaving || isPosting || isGeneratingCim}
                    className={classes.submit_btn}
                    onClick={validateCIM}
                    leftIcon={
                      <RiRobot2Line size={25} style={{ marginRight: '10px' }} />
                    }
                  />
                )}
                <Button
                  label={isSaving ? 'Wait...' : 'Update'}
                  disabled={isSaving || isPosting || isGeneratingCim}
                  className={classes.submit_btn}
                  onClick={() => editListing(false)}
                />
                {['prospect', 'broker-review'].includes(status?.value) && (
                  <Button
                    onClick={() => editListing(true)}
                    label={isPosting ? 'Wait...' : 'Post'}
                    className={classes.submit_btn}
                    disabled={isSaving || isPosting || isGeneratingCim}
                  />
                )}
                <Button
                  className={classes.submit_btn}
                  label={'Delete'}
                  onClick={() => {
                    setIsOpenModal('delete');
                  }}
                  disabled={isSaving || isPosting || isGeneratingCim}
                />
              </div>
            </div>
            <Row className={flag && classes.fBody}>
              <Row
                style={{
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  border: '2px solid red',
                  borderRadius: '10px',
                }}
              >
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <Input
                      disabled={status?.value?.includes('lost')}
                      setter={setCompanyName}
                      value={companyName}
                      label={'Company/DBA'}
                      placeholder={'Enter Company/DBA'}
                      // type={"number"}
                    />
                  </div>
                </Col>
                <Col xl={3} lg={3} sm={6}>
                  <div className={classes.input_main}>
                    <DropDown
                      setter={setStatus}
                      value={status}
                      label={'Status'}
                      placeholder={'Status'}
                      options={
                        ['prospect', 'broker-review'].includes(status?.value)
                          ? []
                          : createListingstatusOptions.slice(2)
                      }
                      optionLabel={'label'}
                      optionValue={'value'}
                    />
                  </div>
                </Col>
                <Col xl={3} lg={3} sm={6}>
                  <div className={classes.input_main}>
                    <Input
                      disabled={status?.value?.includes('lost')}
                      setter={(v) =>
                        setProbability(
                          numberInputValidator(probability, v, 0, 100),
                        )
                      }
                      value={probability}
                      rightIcon={<MdPercent style={{ marginRight: '10px' }} />}
                      placeholder={`Enter the probability`}
                      label={`Probability`}
                      type={'number'}
                    />
                  </div>
                </Col>
                {['sold', 'under-contract'].includes(status?.value) && (
                  <Col xl={4} lg={4} sm={12}>
                    <div className={classes.input_main}>
                      <Input
                        disabled={status?.value?.includes('lost')}
                        setter={setSoldDate}
                        value={soldDate}
                        label={`${status?.value == 'sold' ? 'Sold' : 'Close'} Date`}
                        placeholder={`Enter ${status?.value == 'sold' ? 'Sold' : 'Close'} Date`}
                        type={'date'}
                      />
                    </div>
                  </Col>
                )}
                {['sold', 'under-contract'].includes(status?.value) && (
                  <Col xl={4} lg={4} sm={12}>
                    <div className={classes.input_main}>
                      <Input
                        disabled={status?.value?.includes('lost')}
                        setter={setSoldPrice}
                        value={soldPrice}
                        placeholder={`Enter ${status?.value == 'sold' ? 'Sold' : 'UC'} Price`}
                        label={`${status?.value == 'sold' ? 'Sold' : 'UC'} Price`}
                        type={'number'}
                      />
                    </div>
                  </Col>
                )}
                {['sold', 'under-contract'].includes(status?.value) && (
                  <Col xl={4} lg={4} sm={12}>
                    <div className={classes.input_main}>
                      <DropDown
                        disabled={status?.value?.includes('lost')}
                        setter={setAssignBuyer}
                        value={assignBuyer}
                        inputValue={allBuyerSearch}
                        inputSetter={setAllBuyerSearch}
                        label={'Buyer'}
                        placeholder={'Buyer'}
                        options={allBuyer?.filter((item) => {
                          const exx = assignBuyer?.find(
                            (e) => e?._id == item?._id,
                          );
                          if (!exx) {
                            return item;
                          }
                        })}
                        optionValue={'_id'}
                        isMulti={true}
                        getOptionLabel={(option) => {
                          return `${option['firstName']} ${option['lastName']}(${option['email']})`;
                        }}
                      />
                    </div>
                  </Col>
                )}
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <DropDown
                      disabled={status?.value?.includes('lost')}
                      setter={setAssignBroker}
                      value={assignBroker}
                      label={'Broker'}
                      placeholder={'Select Broker'}
                      options={allAdminBroker}
                      optionValue={'_id'}
                      isMulti={true}
                      getOptionLabel={(option) => {
                        return `${option['firstName']} ${option['lastName']}`;
                      }}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <DropDown
                      disabled={status?.value?.includes('lost')}
                      setter={setOwner}
                      value={owner}
                      label={'Seller'}
                      inputValue={allSellerSearch}
                      inputSetter={setAllSellerSearch}
                      placeholder={'Select Seller'}
                      options={allOwners?.filter((item) => {
                        const exists = owner?.find((e) => e?._id === item?._id);
                        return !exists;
                      })}
                      optionValue={'_id'}
                      isMulti={true}
                      getOptionLabel={(option) => {
                        return `${option['firstName']} ${option['lastName']} (${option['email']})`;
                      }}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <DropDown
                      disabled={status?.value?.includes('lost')}
                      setter={setCategory}
                      value={category}
                      label={'Category'}
                      placeholder={'Select Category'}
                      options={allCategories}
                      optionLabel={'name'}
                      optionValue={'_id'}
                      isMulti={true}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <DropDown
                      disabled={status?.value?.includes('lost')}
                      setter={setRealState}
                      value={realState}
                      placeholder={'Select Industry'}
                      label={'Industry'}
                      options={allIndustries}
                      optionLabel={'name'}
                      optionValue={'_id'}
                      isMulti={true}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <Input
                      disabled={status?.value?.includes('lost')}
                      setter={setListingId}
                      value={listingId}
                      placeholder={'Ref ID'}
                      type={'text'}
                      label={'Ref ID'}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <DropDown
                      disabled={status?.value?.includes('lost')}
                      setter={(e) => setLeadSource(e)}
                      value={leadSource}
                      placeholder={'Select'}
                      label={'Lead Source'}
                      options={leadSourceOptions}
                      optionLabel={'label'}
                      optionValue={'value'}
                      isClearable={true}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <Input
                      disabled={status?.value?.includes('lost')}
                      setter={setOrder}
                      value={order}
                      label={'View Order'}
                      placeholder={'Enter View Order'}
                      type={'number'}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <Input
                      disabled={status?.value?.includes('lost')}
                      setter={setTitle}
                      value={title}
                      placeholder={'Enter Listing Headline'}
                      label={'Listing Headline'}
                    />
                  </div>
                </Col>
              </Row>
              <Col xl={6} lg={6} sm={12}>
                <AddMultiValueInputContainer
                  title={'Business Address'}
                  inputValue={bAddr}
                  inputSetter={setBAddr}
                  arrayValue={businessAddress}
                  arraySetter={setBusinessAddress}
                  placeholder={'Add Business Address'}
                  disabled={status?.value?.includes('lost')}
                />
              </Col>
              <Col xl={6} lg={6} sm={12}>
                <AddMultiMapInputContainer
                  title={'Business Location'}
                  placeholder={'Add Business Location'}
                  businessPlacesApiAddress={businessPlacesApiAddress}
                  businessAddressCoordinates={businessAddressCoordinates}
                  businessAddressDetail={businessAddressDetail}
                  setBusinessPlacesApiAddress={setBusinessPlacesApiAddress}
                  setBusinessAddressCoordinates={setBusinessAddressCoordinates}
                  setBusinessAddressDetail={setBusinessAddressDetail}
                  disabled={status?.value?.includes('lost')}
                />
              </Col>
              <Col xl={3} lg={3} sm={12} style={{ marginTop: '10px' }}>
                <div className={classes.input_main}>
                  <Input
                    disabled={status?.value?.includes('lost')}
                    setter={setBusinessOpportunity}
                    value={businessOpportunity}
                    placeholder={'Business Opportunity Price'}
                    label={'Business Opportunity Price'}
                    type={'number'}
                  />
                </div>
              </Col>
              <Col xl={3} lg={3} sm={12} style={{ marginTop: '10px' }}>
                <div className={classes.input_main}>
                  <Input
                    disabled={status?.value?.includes('lost')}
                    setter={setCashFlow}
                    value={cashFlow}
                    placeholder={'Enter Cash Flow'}
                    label={'Cash Flow'}
                    type={'number'}
                  />
                </div>
              </Col>
              <Col xl={2} lg={2} sm={12} style={{ marginTop: '10px' }}>
                <div className={classes.input_main}>
                  <Input
                    disabled={status?.value?.includes('lost')}
                    setter={setInventory}
                    value={inventory}
                    placeholder={'Enter Inventory'}
                    label={'Inventory'}
                    type={'number'}
                  />
                </div>
              </Col>
              <Col xl={2} lg={2} sm={12} style={{ marginTop: '10px' }}>
                <div className={classes.input_main}>
                  <Input
                    disabled={status?.value?.includes('lost')}
                    setter={setSalesRevenue}
                    value={salesRevenue}
                    placeholder={'Enter Sales Revenue'}
                    label={'Sales Revenue'}
                    type={'number'}
                  />
                </div>
              </Col>

              <Col xl={2} lg={2} sm={12} style={{ marginTop: '10px' }}>
                <div className={classes.input_main}>
                  <Input
                    disabled={status?.value?.includes('lost')}
                    setter={setEbitda}
                    value={ebitda}
                    label={'EBITDA'}
                    placeholder={'Enter EBITDA'}
                    type={'number'}
                  />
                </div>
              </Col>

              <Col xl={3} lg={3} sm={6}>
                <div className={classes.input_main}>
                  <Input
                    disabled={status?.value?.includes('lost')}
                    setter={setCommission}
                    value={commission}
                    rightIcon={<BiDollar style={{ marginRight: '10px' }} />}
                    placeholder={`Enter the commission`}
                    label={`Commmission`}
                    type={'number'}
                  />
                </div>
              </Col>
              <Col xl={3} lg={3} sm={6}>
                <div className={classes.input_main}>
                  <Input
                    disabled={status?.value?.includes('lost')}
                    setter={(v) =>
                      setPercentage(numberInputValidator(percentage, v, 0, 100))
                    }
                    value={percentage}
                    rightIcon={<MdPercent style={{ marginRight: '10px' }} />}
                    placeholder={`Enter the Percentage`}
                    label={`Percentage`}
                    type={'number'}
                  />
                </div>
              </Col>
              <Col xl={3} lg={3} sm={6}>
                <div className={classes.input_main}>
                  <Input
                    value={applicableCommission}
                    disabled={status?.value?.includes('lost')}
                    setter={setApplicableCommission}
                    rightIcon={<BiDollar style={{ marginRight: '10px' }} />}
                    label={`Applicable Commission`}
                    type={'number'}
                  />
                </div>
              </Col>

              <Col xl={6} lg={6} sm={12}>
                <div className={classes.input_main}>
                  <DropDown
                    disabled={status?.value?.includes('lost')}
                    setter={(e) =>
                      setAmountTypeAndValue((prev) => ({ ...prev, ...e }))
                    }
                    value={amountTypeAndValue}
                    placeholder={'Select'}
                    label={'Real Estate'}
                    options={amountTypeOptions}
                    optionLabel={'label'}
                    optionValue={'value'}
                  />
                </div>
              </Col>

              <Col xl={6} lg={6} sm={12}>
                <div className={classes.input_main}>
                  <DropDown
                    setter={setEntity}
                    value={entity}
                    placeholder={'Select Entity'}
                    options={allEntities}
                    optionLabel={'name'}
                    optionValue={'_id'}
                    label={'Entity'}
                  />
                </div>
              </Col>

              {amountTypeAndValue?.value === 'real-estate' && (
                <>
                  <Col xl={3} lg={3} sm={6}>
                    <div className={classes.input_main}>
                      <Input
                        disabled={status?.value?.includes('lost')}
                        setter={setRealEstateCommission}
                        value={realEstateCommission}
                        rightIcon={<BiDollar style={{ marginRight: '10px' }} />}
                        placeholder={`Enter the commission`}
                        label={`Real Estate Commission`}
                        type={'number'}
                      />
                    </div>
                  </Col>
                  <Col xl={3} lg={3} sm={6}>
                    <div className={classes.input_main}>
                      <Input
                        disabled={status?.value?.includes('lost')}
                        setter={(v) =>
                          setRealEstatePercentage(
                            numberInputValidator(
                              realEstatePercentage,
                              v,
                              0,
                              100,
                            ),
                          )
                        }
                        value={realEstatePercentage}
                        rightIcon={
                          <MdPercent style={{ marginRight: '10px' }} />
                        }
                        placeholder={`Enter the Percentage`}
                        label={`Real Estate Percentage`}
                        type={'number'}
                      />
                    </div>
                  </Col>
                  <Col xl={3} lg={3} sm={6}>
                    <div className={classes.input_main}>
                      <Input
                        value={realEstateApplicableCommission}
                        disabled={status?.value?.includes('lost')}
                        setter={setRealEstateApplicableCommission}
                        rightIcon={<BiDollar style={{ marginRight: '10px' }} />}
                        label={`Applicable Commission`}
                        type={'number'}
                      />
                    </div>
                  </Col>
                </>
              )}
              <Col lg={12} md={12} sm={12}>
                <span className={classes?.titleText}>Property Information</span>
              </Col>
              {amountTypeAndValue?.value == 'location' && (
                <Col xl={12} lg={12} sm={12}>
                  <div className={classes.input_main}>
                    <Input
                      disabled={status?.value?.includes('lost')}
                      setter={(e) =>
                        setAmountTypeAndValue((prev) => ({
                          ...prev,
                          amount: e,
                        }))
                      }
                      value={amountTypeAndValue?.amount || ''}
                      placeholder={`Enter Location`}
                      // label={amountTypeAndValue?.value}
                      type={'string'}
                    />
                  </div>
                </Col>
              )}
              {amountTypeAndValue?.value !== 'location' && (
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <Input
                      disabled={status?.value?.includes('lost')}
                      setter={(e) =>
                        setAmountTypeAndValue((prev) => ({ ...prev, info2: e }))
                      }
                      value={amountTypeAndValue?.info2}
                      placeholder={`Enter ${amountTypeAndValue?.value == 'rent' ? 'Lease Rate' : 'Real Estate Price'}`}
                      // label={`${amountTypeAndValue?.value == 'rent' ? 'Lease Rate' : 'Real Estate Price'}`}
                      type={'string'}
                    />
                  </div>
                </Col>
              )}
              {amountTypeAndValue?.value !== 'location' && (
                <Col xl={6} lg={6} sm={12}>
                  <div className={classes.input_main}>
                    <Input
                      disabled={status?.value?.includes('lost')}
                      setter={(e) =>
                        setAmountTypeAndValue((prev) => ({ ...prev, info1: e }))
                      }
                      value={amountTypeAndValue?.info1}
                      placeholder={`Enter ${amountTypeAndValue?.value == 'rent' ? 'Lease' : 'Real Estate'} Information`}
                      // label={`${amountTypeAndValue?.value == 'rent' ? 'Lease' : 'Real Estate'} Information`}
                      type="string"
                    />
                  </div>
                </Col>
              )}
              {amountTypeAndValue?.value !== 'location' && (
                <Col lg={6} md={6} sm={12}>
                  <div className={classes.input_main}>
                    <Input
                      disabled={status?.value?.includes('lost')}
                      setter={(e) =>
                        setAmountTypeAndValue((prev) => ({
                          ...prev,
                          amount: e,
                        }))
                      }
                      value={amountTypeAndValue?.amount || ''}
                      placeholder={`Enter ${amountTypeAndValue?.value == 'rent' ? 'Rent' : 'Real Estate'}`}
                      label={
                        amountTypeAndValue?.value == 'real-estate'
                          ? 'Real Estate Price'
                          : 'Monthly Rent'
                      }
                      type="string"
                    />
                  </div>
                </Col>
              )}
              <Col
                lg={amountTypeAndValue?.value !== 'location' ? 6 : 12}
                md={amountTypeAndValue?.value !== 'location' ? 6 : 12}
                sm={12}
              >
                {amountTypeAndValue?.value == 'location' && (
                  <div className={classes.input_main}>
                    <Input
                      disabled={status?.value?.includes('lost')}
                      setter={setBuildingSf}
                      value={buildingSf}
                      placeholder={
                        amountTypeAndValue?.value !== 'location' &&
                        'Enter Building Square Feet'
                      }
                      type={
                        amountTypeAndValue?.value !== 'location'
                          ? 'number'
                          : 'string'
                      }
                      label={
                        amountTypeAndValue?.value == 'location'
                          ? 'Statistics'
                          : 'Building Square Feet'
                      }
                    />
                  </div>
                )}
              </Col>

              <Col xl={6} lg={6} sm={12}>
                <div className={classes.input_main}>
                  <Input
                    disabled={status?.value?.includes('lost')}
                    setter={setFullTimeEmployee}
                    value={fullTimeEmployee}
                    placeholder={'Enter Full Time Employee'}
                    label={'Full Time Employee'}
                    type={'number'}
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} sm={12}>
                <div className={classes.input_main}>
                  <Input
                    disabled={status?.value?.includes('lost')}
                    setter={setPartTimeEmployee}
                    value={partTimeEmployee}
                    placeholder={'Enter Part Time Employee'}
                    label={'Part Time Employee'}
                    type={'number'}
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} sm={12}>
                <div className={classes.input_main}>
                  <TextArea
                    disabled={status?.value?.includes('lost')}
                    setter={setEmployeesDescription}
                    value={employeesDescription}
                    placeholder={'Enter Employees Description'}
                    label={'Employees Description'}
                    type={'text'}
                    rows={2}
                  />
                </div>
              </Col>

              <Col xl={12} lg={12} sm={12}>
                <div className={classes.input_main}>
                  <DropDown
                    disabled={status?.value?.includes('lost')}
                    setter={setFinancingOptions}
                    value={financingOptions}
                    label={'Financing Options'}
                    placeholder={'Select Financing Options'}
                    options={[
                      { label: 'SBA' },
                      { label: 'Cash' },
                      { label: 'Conventional' },
                      { label: 'Owner Financing' },
                    ]}
                    optionLabel={'label'}
                    optionValue={'label'}
                    isMulti={true}
                  />
                </div>
              </Col>

              <Col md={12} className={classes.swotContainer}>
                <div className={classes.swotTitle}>SWOT Analysis</div>
                <Row className={classes.swotBody}>
                  <Col lg={6} sm={12}>
                    <div className={classes.inputMain}>
                      <TextArea
                        label={'Strengths'}
                        value={changeStringList(strengths)}
                        setter={(v) => setStrengths(changeStringToList(v))}
                        labelStyle={{ color: 'black' }}
                        customStyle={{ backgroundColor: '#8ED973' }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} sm={12}>
                    <div className={classes.inputMain}>
                      <TextArea
                        label={'Weaknesses'}
                        value={changeStringList(weaknesses)}
                        setter={(v) => setWeaknesses(changeStringToList(v))}
                        labelStyle={{ color: 'black' }}
                        customStyle={{ backgroundColor: '#FF7C80' }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} sm={12}>
                    <div className={classes.inputMain}>
                      <TextArea
                        label={'Opportunities'}
                        value={changeStringList(opportunities)}
                        setter={(v) => setOpportunities(changeStringToList(v))}
                        labelStyle={{ color: 'black' }}
                        customStyle={{ backgroundColor: '#F9C7AC' }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} sm={12}>
                    <div className={classes.inputMain}>
                      <TextArea
                        label={'Threats'}
                        value={changeStringList(threats)}
                        setter={(v) => setThreats(changeStringToList(v))}
                        labelStyle={{ color: 'black' }}
                        customStyle={{ backgroundColor: '#8ED973' }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={6} sm={12} style={{ marginTop: '15px' }}>
                <AddMultiValueInputContainer
                  title={'Pros'}
                  inputValue={prosText}
                  inputSetter={setProsText}
                  arrayValue={pros}
                  arraySetter={setPros}
                  placeholder={'Add Pros'}
                  disabled={status?.value?.includes('lost')}
                />
              </Col>
              <Col xl={6} lg={6} sm={12} style={{ marginTop: '15px' }}>
                <AddMultiValueInputContainer
                  title={'Cons'}
                  inputValue={consText}
                  inputSetter={setConsText}
                  arrayValue={cons}
                  arraySetter={setCons}
                  placeholder={'Add Cons'}
                  disabled={status?.value?.includes('lost')}
                />
              </Col>
              <Col lg={12} md={12} sm={12} style={{ marginTop: '15px' }}>
                <AddMultiValueInputContainer
                  title={'Highlights'}
                  inputValue={busniessHighlightText}
                  inputSetter={setBusniessHighlightText}
                  arrayValue={businessHighlights}
                  arraySetter={setBusinessHighlights}
                  placeholder={'Add Business Highlights'}
                  disabled={status?.value?.includes('lost')}
                />
              </Col>
              <Col lg={12} md={12} sm={12} style={{ marginTop: '15px' }}>
                <AddMultiValueInputWithDropDownContainer
                  title={'Third Party Presence'}
                  inputValue={onlinePresenceInputState}
                  inputSetter={setOnlinePresenceInputState}
                  arrayValue={onlinePresence}
                  arraySetter={setOnlinePresence}
                  inputPlaceholder={'Enter Url'}
                  dropDownPlaceholder={'Select Platform'}
                  dropDownValueKey={'key'}
                  inputValueKey={'link'}
                  dropDownoptions={onlinePresenceOptions?.filter((item) => {
                    const exx = onlinePresence?.find(
                      (e) => e?.key == item?.value,
                    );
                    if (!exx) {
                      return item;
                    }
                  })}
                  disabled={status?.value?.includes('lost')}
                />
              </Col>
              <Col lg={7} sm={12} style={{ marginTop: '15px' }}>
                <AddMultiValueWithMultipleInput
                  title={'Hours Of Operation'}
                  inputValue={hoursOfOperationText}
                  inputSetter={setHoursOfOperationText}
                  arrayValue={hoursOfOperation}
                  arraySetter={setHoursOfOperation}
                  firstValueKey={'days'}
                  secondValueKey={'hours'}
                  firstPlaceholder={'Enter Days'}
                  secondPlaceholder={'Enter Timings'}
                  disabled={status?.value?.includes('lost')}
                />
              </Col>
              <Col lg={5} sm={12} style={{ marginTop: '20px' }}>
                <div className={classes.textArea_main}>
                  <TextArea
                    placeholder={'Enter Description'}
                    label={'Hours Of Operation Opportunity'}
                    disabled={status?.value?.includes('lost')}
                    setter={setHoursOfOperationOpportunityDescription}
                    value={hoursOfOperationOpportunityDescription}
                    rows={1}
                  />
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className={classes.textArea_main}>
                  <TextArea
                    placeholder={'Enter Owner Involement'}
                    disabled={status?.value?.includes('lost')}
                    setter={setOwnerInvolvment}
                    value={ownerInvolvment}
                    rows={5}
                  />
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className={classes.textArea_main}>
                  <TextArea
                    placeholder={'Enter Reason For Selling'}
                    disabled={status?.value?.includes('lost')}
                    setter={setReasonForSelling}
                    value={reasonForSelling}
                    rows={5}
                  />
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className={classes.textArea_main}>
                  <TextArea
                    placeholder={'Description Demo'}
                    disabled={status?.value?.includes('lost')}
                    setter={setDummyDescription}
                    value={dummyDescription}
                    rows={5}
                  />
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className={classes.textArea_main}>
                  <TextArea
                    placeholder={'Enter Real Description'}
                    disabled={status?.value?.includes('lost')}
                    setter={setRealDescription}
                    value={realDescription}
                    rows={5}
                  />
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} style={{ marginTop: '10px' }}>
                <AddMultiItemYearWithList
                  title={'Recent Improvement'}
                  arrayValue={recentImprovements}
                  arraySetter={setRecentImprovements}
                  disabled={status?.value?.includes('lost')}
                />
              </Col>

              <Col sm={12} style={{ marginTop: '10px' }}>
                <label className={classes.label}>
                  What do you want to upload in the financials section?
                </label>
                <div className={classes.financialsMediaType}>
                  <Radio
                    value={financialsMediaType}
                    setValue={(e) => {
                      setFinancialsMediaType(e);
                      setDeletingImages({
                        ...deletingImages,
                        deletedFinancialsCSVImages: [],
                        isDeleting: true,
                      });
                    }}
                    label={'Images'}
                  />
                  <Radio
                    value={financialsMediaType}
                    setValue={(e) => {
                      setFinancialsMediaType(e);
                      setDeletingImages({
                        ...deletingImages,
                        deletedFinancialsCSVImages: financialsCSVImages,
                        isDeleting: true,
                      });
                    }}
                    label={'CSVs'}
                  />
                </div>
              </Col>

              <Col md={12}>
                {financialsMediaType == 'Images' ? (
                  <>
                    <div className={classes?.titleContainer}>
                      <span className={classes?.titleText}>
                        Financials (Images)
                      </span>
                      <AddMoreBtn
                        onClick={() => {
                          if (financialsCSVImages?.length < 6) {
                            setFinancialsCSVImages((pre) => [...pre, {}]);
                          } else {
                            toast.info(
                              'Maximum 6 images can upload on Financial Images',
                            );
                          }
                        }}
                        disabled={status?.value?.includes('lost')}
                      />
                    </div>
                    <div className={classes.galleryImages}>
                      {financialsCSVImages?.map((item, i) => (
                        <div
                          className={classes.galleryImageDiv}
                          disabled={status?.value?.includes('lost')}
                        >
                          <UploadImageBox
                            hideDeleteIcon
                            state={item}
                            disabled={status?.value?.includes('lost')}
                            setter={(e) => {
                              if (
                                ![
                                  'image/jpeg',
                                  'image/png',
                                  'image/jpg',
                                ].includes(e.type)
                              ) {
                                return toast.error(
                                  'Please upload a valid image. [jpg and png formats only]',
                                );
                              }
                              // max size 100MB
                              if (e?.size / 1024 / 1024 > 100)
                                return toast.error(
                                  'Please upload a valid image. [Max size: 100MB]',
                                );
                              const newImages = [...financialsCSVImages];
                              newImages[i] = e;
                              setFinancialsCSVImages(newImages);
                            }}
                            onDelete={() => {
                              const newImages = [...financialsCSVImages];
                              newImages.splice(i, 1);
                              setFinancialsCSVImages(newImages);
                              if (typeof item !== 'object') {
                                setDeletingImages({
                                  ...deletingImages,
                                  deletedFinancialsCSVImages: [
                                    ...deletingImages?.financialsCSVImages,
                                    item,
                                  ],
                                  isDeleting: true,
                                });
                              }
                            }}
                            onEdit={() => {
                              if (typeof item !== 'object') {
                                setDeletingImages({
                                  ...deletingImages,
                                  deletedFinancialsCSVImages: [
                                    ...deletingImages?.financialsCSVImages,
                                    item,
                                  ],
                                  isDeleting: true,
                                });
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes?.titleContainer}>
                      <span className={classes?.titleText}>
                        Financials (CSV)
                      </span>
                      <AddMoreBtn
                        onClick={() => {
                          if (financialCSV?.length < 2) {
                            setFinancialCSV((pre) => [...pre, {}]);
                          } else {
                            toast.info(
                              'Maximum 2 files can upload on Financial CSV',
                            );
                          }
                        }}
                        disabled={status?.value?.includes('lost')}
                      />
                    </div>
                    <div
                      className={classes.csvContainer}
                      disabled={status?.value?.includes('lost')}
                    >
                      {financialCSV?.map((item, i) => {
                        return (
                          <div className={classes.csvFileDiv}>
                            <UploadCSVBox
                              className={classes.csvBox}
                              state={item}
                              isJson={
                                item?.length > 0 && item[0]?.column1 && true
                              }
                              i={i}
                              edit={true}
                              disabled={status?.value?.includes('lost')}
                              setter={async (e) => {
                                console.log('before', e);
                                if (!['text/csv'].includes(e.type)) {
                                  return toast.error(
                                    'Please upload a csv file',
                                  );
                                }
                                // max size 100MB
                                if (e?.size / 1024 / 1024 > 100)
                                  return toast.error(
                                    'Please upload a valid image. [Max size: 100MB]',
                                  );

                                const newCsvs = [...financialCSV];
                                newCsvs.splice(i, 1, e);
                                setFinancialCSV(newCsvs);
                                const newData = [...convertedFinancialCSV];
                                newCsvs?.map((item, i) => {
                                  CsvToJsonConvertion(item, (data) => {
                                    newData[i] = data;
                                    setConvetedFinancialCSV(newData);
                                  });
                                });
                              }}
                              onDelete={() => {
                                const newCsvs = [...financialCSV];
                                newCsvs.splice(i, 1);
                                setFinancialCSV(newCsvs);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </Col>

              <Col sm={12} style={{ marginTop: '15px' }}>
                <div className={classes.textArea_main}>
                  <TextArea
                    placeholder={'Enter Financials Description'}
                    label={'Financials Description'}
                    disabled={status?.value?.includes('lost')}
                    setter={setFinancialDescription}
                    value={financialDescription}
                    rows={4}
                  />
                </div>
              </Col>

              <Col md={12} style={{ marginTop: '15px' }}>
                <div className={classes?.titleContainer}>
                  <span className={classes?.imagesTitleText}>
                    Picture (Demo)
                  </span>
                </div>
                <div className={classes.galleryImages}>
                  <div className={classes.galleryImageDiv}>
                    <UploadImageBox
                      disabled={status?.value?.includes('lost')}
                      setter={(e) => {
                        if (
                          !['image/jpeg', 'image/png', 'image/jpg'].includes(
                            e.type,
                          )
                        ) {
                          return toast.error(
                            'Please upload a valid image. [jpg and png formats only]',
                          );
                        }
                        // max size 100MB
                        if (e?.size / 1024 / 1024 > 100)
                          return toast.error(
                            'Please upload a valid image. [Max size: 100MB]',
                          );
                        setPictureDemo(e);
                      }}
                      state={pictureDemo}
                      onDelete={() => {}}
                      onEdit={() => {}}
                    />
                  </div>
                </div>
              </Col>
              <Col md={12} style={{ marginTop: '15px' }}>
                <div className={classes?.titleContainer}>
                  <div className={classes?.titleEdit}>
                    <div className={classes?.imagesTitleText}>
                      Financial Analysis
                    </div>
                    <div style={{ width: '25em' }}>
                      <Input
                        disabled={status?.value?.includes('lost')}
                        setter={setFinancialTitle}
                        value={financialTitle}
                        placeholder={'Enter Title'}
                      />
                    </div>
                    <AddMoreBtn
                      disabled={status?.value?.includes('lost')}
                      onClick={() => {
                        if (financialImages?.length < 10) {
                          setFinancialImages((pre) => [...pre, {}]);
                        } else {
                          toast.info(
                            'Maximum 10 images can upload on Financial Analysis',
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={classes.galleryImages}>
                  {financialImages?.map((item, i) => {
                    return (
                      <div className={classes.galleryImageDiv}>
                        <UploadImageBox
                          hideDeleteIcon
                          state={item}
                          disabled={status?.value?.includes('lost')}
                          setter={(e) => {
                            if (
                              ![
                                'image/jpeg',
                                'image/png',
                                'image/jpg',
                              ].includes(e.type)
                            ) {
                              return toast.error(
                                'Please upload a valid image. [jpg and png formats only]',
                              );
                            }
                            // max size 100MB
                            if (e?.size / 1024 / 1024 > 100)
                              return toast.error(
                                'Please upload a valid image. [Max size: 100MB]',
                              );
                            const newImages = [...financialImages];
                            newImages.splice(i, 1, e);
                            setFinancialImages(newImages);
                          }}
                          onDelete={() => {
                            const newImages = [...financialImages];
                            newImages.splice(i, 1);
                            if (typeof item !== 'object') {
                              setDeletingImages({
                                ...deletingImages,
                                financialsAnalysis: [
                                  ...deletingImages?.financialsAnalysis,
                                  financialImages[i],
                                ],
                                isDeleting: true,
                              });
                            }
                            setFinancialImages(newImages);
                          }}
                          onEdit={() => {
                            if (typeof item !== 'object') {
                              setDeletingImages({
                                ...deletingImages,
                                financialsAnalysis: [
                                  ...deletingImages?.financialsAnalysis,
                                  financialImages[i],
                                ],
                                isDeleting: true,
                              });
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </Col>
              <Col md={12} style={{ marginTop: '15px' }}>
                <div className={classes?.titleContainer}>
                  <div className={classes?.titleEdit}>
                    <div className={classes?.imagesTitleText}>Demographic</div>
                    <div style={{ width: '27em' }}>
                      <Input
                        disabled={status?.value?.includes('lost')}
                        setter={setDemographicTitle}
                        value={demographicTitle}
                        placeholder={'Enter Title'}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.input_main}>
                  <TextArea
                    disabled={status?.value?.includes('lost')}
                    setter={setDemographics}
                    value={demographics}
                    label={'Demographics'}
                    placeholder={'Enter Demographics'}
                    rows={5}
                  />
                </div>
              </Col>

              <Col md={12} style={{ marginTop: '15px' }}>
                <div className={classes?.titleContainer}>
                  <span className={classes?.imagesTitleText}>
                    Picture (Gallery)
                  </span>
                  <AddMoreBtn
                    disabled={status?.value?.includes('lost')}
                    onClick={() => {
                      if (galleryImages?.length < 10) {
                        setGalleryImages((pre) => [...pre, {}]);
                      } else {
                        toast.info('Maximum 10 images can upload on Gallery');
                      }
                    }}
                  />
                </div>
                <div className={classes.galleryImages}>
                  {galleryImages?.map((item, i) => {
                    return (
                      <div className={classes.galleryImageDiv}>
                        <UploadImageBox
                          hideDeleteIcon
                          state={item}
                          disabled={status?.value?.includes('lost')}
                          setter={(e) => {
                            if (
                              ![
                                'image/jpeg',
                                'image/png',
                                'image/jpg',
                              ].includes(e.type)
                            ) {
                              return toast.error(
                                'Please upload a valid image. [jpg and png formats only]',
                              );
                            }
                            // max size 100MB
                            if (e?.size / 1024 / 1024 > 100)
                              return toast.error(
                                'Please upload a valid image. [Max size: 100MB]',
                              );
                            const newImages = [...galleryImages];
                            newImages.splice(i, 1, e);
                            setGalleryImages(newImages);
                          }}
                          onDelete={() => {
                            const newImages = [...galleryImages];
                            newImages.splice(i, 1);
                            if (typeof item !== 'object') {
                              setDeletingImages({
                                ...deletingImages,
                                images: [
                                  ...deletingImages?.images,
                                  galleryImages[i],
                                ],
                                isDeleting: true,
                              });
                            }
                            setGalleryImages(newImages);
                          }}
                          onEdit={() => {
                            if (typeof item !== 'object') {
                              setDeletingImages({
                                ...deletingImages,
                                images: [
                                  ...deletingImages?.images,
                                  galleryImages[i],
                                ],
                                isDeleting: true,
                              });
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </Col>

              {isOpenModal == 'delete' && (
                <AreYouSureModal
                  subTitle={`Do you want to delete this listing?`}
                  show={isOpenModal == 'delete'}
                  setShow={setIsOpenModal}
                  onClick={removeBusiness}
                  isApiCall={isUpdating == 'delete'}
                />
              )}

              {showConfirm && (
                <AreYouSureModal
                  subTitle={showConfirm}
                  show={showConfirm}
                  setShow={setShowConfirm}
                  onClick={generateCIM}
                  isApiCall={isGeneratingCim}
                />
              )}

              {isOpenBVIModal && (
                <BVIModal
                  show={isOpenBVIModal}
                  setShow={setIsOpenBVIModal}
                  noteBox={selectedNote}
                />
              )}

              {showCIMConfirmModal && (
                <CIMConfirmModal
                  show={showCIMConfirmModal}
                  setShow={setShowCIMConfirmModal}
                  data={cimData}
                  onConfirm={onCimConfirm}
                />
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default EdiListing;
