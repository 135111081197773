import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MdAdd, MdRemove } from 'react-icons/md';
import { toast } from 'react-toastify';

import { apiHeader, BaseURL } from '../../config/apiUrl';
import { Delete, Get, Patch, Post } from '../../Axios/AxiosFunctions';
import NoteCard from '../../Component/NoteCard';
import Loader from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';
import AreYouSureModal from '../../modals/AreYouSureModal';
import classes from './NotesList.module.css';

const NotesList = () => {
  const {
    user,
    access_token: accessToken,
    company_id: companyId,
  } = useSelector((state) => state?.authReducer || {});

  const isSuperAdmin = user?.role?.includes('super-admin');

  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [message, setMessage] = useState('');
  const [removeID, setRemoveID] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  const getNotes = async () => {
    setLoading(true);
    const response = await Get(
      BaseURL(`document-note/all`),
      accessToken,
      companyId,
    );
    setLoading(false);

    if (response?.data?.data) {
      console.log(response?.data?.data);
      setNotes(response?.data?.data || []);
    }
  };

  const addNote = async () => {
    if (!message) return;
    const response = await Post(
      BaseURL(`document-note`),
      {
        message: message,
      },
      apiHeader(accessToken),
      companyId,
    );
    if (response?.data) {
      setMessage('');
      setShowEdit(false);
      getNotes();
      toast.success('Added successfully.');
    }
  };

  const updateNotes = async (item) => {
    setLoading(true);
    const response = await Patch(
      BaseURL(`document-note/${item?._id}`),
      { message },
      apiHeader(accessToken),
      companyId,
    );
    setLoading(false);

    if (response?.data) {
      toast.success('Note updated successfully');
      getNotes();
      setMessage('');
      setShowEdit(false);
    }
  };

  const onRemove = async (_id) => {
    setLoading(true);
    const response = await Delete(
      BaseURL(`document-note/${_id}`),
      null,
      apiHeader(accessToken),
    );
    setLoading(false);

    if (response?.data) {
      toast.success('Removed successfully.');
      getNotes();
      setRemoveID(null);
    }
  };

  const onRemoveAll = async () => {
    setLoading(true);
    const response = await Delete(
      BaseURL(`document-note`),
      null,
      apiHeader(accessToken),
      companyId,
    );
    setLoading(false);

    if (response) {
      toast.success('All Removed successfully.');
      setNotes([]);
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <>
      <div className={[classes.mainContainer]}>
        <div className={[classes.headerContainer]}>
          <div className={classes.btnMain}>
            {!showEdit && (
              <>
                {isSuperAdmin && notes?.length != 0 && (
                  <div
                    onClick={() => {
                      setRemoveID(null);
                      setIsOpenModal(true);
                    }}
                    className={classes.btnDiv}
                  >
                    <MdRemove /> Remove All Notes
                  </div>
                )}
                <div
                  onClick={() => setShowEdit(true)}
                  className={classes.btnDiv}
                  label={``}
                  disabled={loading}
                >
                  <MdAdd /> Add Note
                </div>
              </>
            )}
          </div>
        </div>
        {showEdit && (
          <NoteCard
            creator={`${user?.firstName} ${user?.lastName}`}
            message={message}
            date={new Date().toUTCString()}
            isEdit={true}
            setMessage={setMessage}
            onClose={() => {
              setShowEdit(false);
              setMessage('');
              setSelectedNote(null);
            }}
            sending={loading}
            submit={() =>
              selectedNote ? updateNotes(selectedNote) : addNote()
            }
            showEditButton={false}
          />
        )}
        {loading ? (
          <Loader />
        ) : !notes?.length ? (
          <NoData />
        ) : (
          <div>
            {notes.map((note) => (
              <NoteCard
                creator={`${note?.creator.firstName} ${note?.creator.lastName}`}
                message={note?.message}
                date={note?.createdAt}
                isRemove={isSuperAdmin}
                onRemove={() => {
                  setRemoveID(note?._id);
                  setIsOpenModal(true);
                }}
                onEdit={() => {
                  setMessage(note?.message);
                  setSelectedNote(note);
                  setShowEdit(true);
                }}
                showEditButton={true}
              />
            ))}
          </div>
        )}
      </div>
      {isOpenModal && (
        <AreYouSureModal
          isApiCall={false}
          setShow={setIsOpenModal}
          show={isOpenModal}
          onClick={() => {
            if (removeID) onRemove(removeID);
            else onRemoveAll();
            setIsOpenModal(false);
          }}
          subTitle={
            removeID
              ? `Do you want to remove this note?`
              : 'Do you want to remove all of your notes?'
          }
        />
      )}
    </>
  );
};

export default NotesList;
