import React, { useEffect, useRef, useState } from 'react';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { maxHeight, styled } from '@mui/system';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { MdAdd, MdArrowBack, MdLockClock, MdClose } from 'react-icons/md';
import { RiExpandLeftFill } from 'react-icons/ri';

import { IconButton } from '@mui/material';
import { MdEdit } from 'react-icons/md';

import { moment } from '../../utils/timeUtils';
import { TextArea } from '../TextArea';
import classes from './NotesDetail.module.css';
import { Link } from 'react-router-dom';
import { stringToColor } from '../../utils/functions';

const NotesDetailPopup = ({ anchor, userId, notes, onAdd, onClose, onEdit, selectedNote }) => {
  const open = Boolean(anchor);
  const id = open ? 'notes-detail-popup' : undefined;

  const [message, setMessage] = useState(selectedNote?.text || '');
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (!anchor) setFlag(false);
  }, [anchor]);

  const divRef = useRef(null);

  const handleMouseDown = (e) => {
    const div = divRef.current;
    const startX = e?.clientX;
    const startY = e?.clientY;
    const startWidth = div?.offsetWidth;
    const startHeight = div?.offsetHeight;

    const onMouseMove = (e) => {
      const newWidth = startWidth - (e.clientX - startX);
      const newHeight = startHeight + (e.clientY - startY);
      div.style.width = `${newWidth}px`;
      div.style.height = `${newHeight}px`;
    };

    const onMouseUp = () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };


  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (!flag) {
          setFlag(true);
        } else {
          setFlag(false);
          onClose();
        }
      }}
    >
      <BasePopup
        id={id}
        open={open}
        anchor={anchor}
        placement="left"
        style={{ zIndex: '100' }}
      >
        <PopupBody>
          <div className={classes.container}>
            <div className={classes.header}>
              <MdClose className={classes.closeBtn} onClick={onClose} />
            </div>
            <div ref={divRef} className={classes.content}>
              {notes &&
                notes.map((note, index) => (
                  <div className={classes.card} key={`${index}`}>
                    <div className="d-flex justify-content-between" width="100%">
                      <div
                        className={classes.cardHeader}
                        style={{ color: stringToColor(`${note?.writer}`) }}
                      >
                        {`${note?.writer}`}
                      </div>
                      <IconButton
                        IconButton
                        onClick={() => {
                          onEdit(note);
                          setMessage(note?.text);
                        }}
                        sx={{ height: 30, width: 30 }}
                        size="small"
                      >
                        <MdEdit fontSize="medium" />
                      </IconButton>
                    </div>
                    <div className={classes.cardContent}>{note?.text}</div>
                    <div className={classes.cardFooter}>
                      <div className={classes.timeContainer}>
                        <MdLockClock />{' '}
                        {moment(note?.date).format('MM-DD-YYYY hh:mm')}
                      </div>
                      <div className={classes.companyContainer}>
                        {note?.listing ? note?.listing?.companyName : ''}
                      </div>
                    </div>
                  </div>
                ))}

              <div
                onMouseDown={handleMouseDown}
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  width: "20px",
                  height: "20px",
                  cursor: "nesw-resize",
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: 'space-between',
                  gap: '1px',
                  flexDirection: 'column',
                  transform: "rotate(45deg)"
                }}
              >
                <div style={{ border: '6px solid transparent', width: "20px" }} />
                <div style={{ borderBottom: '1px solid black', width: "14px" }} />
                <div style={{ borderBottom: '1px solid black', width: "10px" }} />
              </div>

            </div>
            {/* <RiExpandLeftFill className={classes.expandIcon}/> */}
            <div>
              <TextArea
                rows={1}
                value={message}
                placeholder={'New Note Here'}
                setter={setMessage}
                customStyle={{ minHeight: '50px', maxHeight: '150px' }}
              />
            </div>
            <div className={classes.footer}>
              <Link
                className={[
                  'ellipsis1Line emailLink',
                  classes.btnContainer,
                ].join(' ')}
                to={`/contacts/${userId}`}
              >
                <MdArrowBack />{' '}
                <div style={{ textTransform: 'none' }}>Contacts</div>
              </Link>
              <div
                className={[
                  classes.btnContainer,
                  message || classes.btnDisable,
                ].join(' ')}
                onClick={() => message && (onAdd(message), setMessage(''))}
              >
                {!selectedNote ? "Add Note" : "Update Note"}
              </div>
            </div>
          </div>
        </PopupBody>
      </BasePopup>
    </ClickAwayListener>
  );
};

export default NotesDetailPopup;

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const PopupBody = styled('div')(
  ({ theme }) => `
  width: max-content;
  padding: 12px 16px;
  margin: 8px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  box-shadow: ${
    theme.palette.mode === 'dark'
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`
  };
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  z-index: 1;
`,
);

const Button = styled('button')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: ${blue[500]};
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid ${blue[500]};
  box-shadow: 0 2px 4px ${
    theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.5)'
      : 'rgba(0, 127, 255, 0.5)'
  }, inset 0 1.5px 1px ${blue[400]}, inset 0 -2px 1px ${blue[600]};

  &:hover {
    background-color: ${blue[600]};
  }

  &:active {
    background-color: ${blue[700]};
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
    outline: none;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      background-color: ${blue[500]};
    }
  }
`,
);
