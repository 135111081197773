import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import {
  MdCheck,
  MdCreateNewFolder,
  MdDelete,
  MdEdit,
  MdImportExport,
  MdSearch,
  MdSmsFailed,
  MdUpload,
} from 'react-icons/md';

import { Delete, Get, Patch, Post } from '../../Axios/AxiosFunctions';
import { apiHeader, BaseURL, apiUrl } from '../../config/apiUrl';
import dayjs, { moment } from '../../utils/timeUtils';
import NoData from '../../Component/NoData/NoData';
import { Button } from '../../Component/Button/Button';
import { DropDown } from '../../Component/DropDown/DropDown';
import Loader from '../../Component/Loader';
import AreYouSureModal from '../../modals/AreYouSureModal';
import TaxReturnViewModal from '../../modals/TaxReturnViewModal';
import CreateFinancialProjectModal from '../../modals/CreateFinancialProjectModal';
import ImportTaxReturnModal from '../../modals/ImportTaxReturnModal';
import classes from './TaxReturnConvertor.module.css';

const TaxReturnConvertor = ({ listingId, companyName }) => {
  const navigate = useNavigate();
  const socket = useRef(null);
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer,
  );
  const inputRef = useRef(null);

  const [financialData, setFinancialData] = useState([]);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [listing, setListing] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [showProjectModal, setShowProjectModal] = useState(null);
  const [showSure, setShowSure] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [anchorProjectEl, setAnchorProjectEl] = useState(null);
  const handleProjectClick = (event) => {
    setAnchorProjectEl(event.currentTarget);
  };
  const handleProjectClose = () => {
    setAnchorProjectEl(null);
  };

  const [anchorFileEl, setAnchorFileEl] = useState(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const handleFileClick = (event) => {
    setAnchorFileEl(event.currentTarget);
  };
  const handleFileClose = () => {
    setAnchorFileEl(null);
  };

  const getFinancialData = async () => {
    const url = BaseURL(`financial/projects/business/${listingId}`);
    const response = await Get(url, accessToken);
    if (Array.isArray(response?.data?.data)) {
      const projects = response?.data?.data || [];
      setFinancialData(projects);
      projects?.length && setProject(projects[0]);
    }
  };

  const handleCreateProject = async ({ name }) => {
    const url = BaseURL('financial/project');
    const params = {
      listingId,
      name,
    };
    setLoading(true);
    const response = await Post(url, params, apiHeader(accessToken));
    setLoading(false);
    if (response?.data?.data) {
      const pro = response?.data?.data;
      toast.success('Project created successfully');
      setShowProjectModal(null);
      setProject(pro);
      setFinancialData((prev) => [pro, ...prev]);
    }
  };

  const handleEditProject = async ({ name }) => {
    const url = BaseURL(`financial/project/${project?._id}`);
    const params = { name };
    setLoading(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setLoading(false);
    if (response?.data?.data) {
      const pro = response?.data?.data;
      toast.success('Project saved successfully');
      setShowProjectModal(null);
      setProject(pro);
      setFinancialData((prev) =>
        prev.map((ele) => (ele?._id == pro?._id ? pro : ele)),
      );
    }
  };

  const handleRemoveProject = async () => {
    const url = BaseURL(`financial/project/${project?._id}`);

    setLoading(true);
    const response = await Delete(url, apiHeader(accessToken));
    setLoading(false);
    if (response?.data?.data) {
      toast.success('Project removed successfully');
      setShowProjectModal(null);
      setFinancialData((prev) =>
        prev.filter((ele) => ele?._id != project?._id),
      );
      setProject(null);
    }
  };

  const uploadFiles = async (files) => {
    files = Array.from(files);

    if (files?.length == 0) return toast.error('File is reuqired');

    const url = BaseURL(`financial/project/${project?._id}/add-tax-returns`);

    const formData = new FormData();
    files?.map((item) => formData.append('file', item));
    setLoading(true);
    const response = await Post(url, formData, apiHeader(accessToken));
    setLoading(false);
    if (response?.data?.data) {
      toast.success('File upload successfully');
      const pro = response?.data?.data;
      setProject(pro);
      setFinancialData((prev) =>
        prev?.map((ele) => (ele?._id == pro?._id ? pro : ele)),
      );
    }
  };

  const setImportFiles = async (files) => {
    if (!files.length) return toast.error('File is reuqired');

    const url = BaseURL(`financial/project/${project._id}/import-tax-returns`);
    const body = {
      files,
    };
    setLoading(true);
    const response = await Post(url, body, apiHeader(accessToken));
    setLoading(false);
    if (response?.data?.data) {
      toast.success('Files imported successfully');
      const pro = response?.data?.data;
      setProject(pro);
      setFinancialData((prev) =>
        prev?.map((ele) => (ele?._id == pro?._id ? pro : ele)),
      );
      setShowImportModal(false);
    }
  };

  const handleParsedData = ({ project: pro }) => {
    console.log('socket', pro);
    if (!pro) return;
    setFinancialData((prev) =>
      prev?.map((ele) => (ele?._id == pro?._id ? pro : ele)),
    );
    setProject((prev) => (prev?._id == pro?._id ? pro : prev));
  };

  const removeFile = async () => {
    if (!selectedItem) return;

    const url = BaseURL(
      `financial/${project?._id}/tax-return/${selectedItem?._id}`,
    );
    setLoading(true);
    const response = await Delete(url, apiHeader(accessToken));
    setLoading(false);
    if (response?.data?.data) {
      toast.success('File removed successfully');
      const taxReturns = project?.taxReturns?.filter(
        (ele) => ele?._id != selectedItem?._id,
      );
      setProject({ ...project, taxReturns });
      setFinancialData((prev) =>
        prev?.map((ele) =>
          ele?._id == project?._id ? { ...project, taxReturns } : ele,
        ),
      );
      setSelectedItem(null);
    }
    setShowSure(false);
  };

  const onSave = async (_id, sheet, data) => {
    console.log(_id, sheet, data);
    const temp = selectedItem;
    temp.parsedData[sheet] = {
      ...temp?.parsedData[sheet],
      data,
    };
    const taxReturns = listing?.map((ele) => (ele?._id == _id ? temp : ele));

    const url = BaseURL(`financial/project/${project?._id}/parsed-data`);
    const body = {
      taxReturns,
    };
    setLoading(true);
    const response = await Patch(url, body, apiHeader(accessToken));
    setLoading(false);
    if (response?.data?.data) {
      const pro = response?.data?.data;
      setFinancialData((prev) =>
        prev?.map((ele) => (ele?._id == pro?._id ? pro : ele)),
      );
      setProject(pro);
      setListing(pro?.taxReturns);
      setSelectedItem(temp);
      toast.success('Successfully updated');
    }
  };

  useEffect(() => {
    getFinancialData();

    // setup websocket
    if (!socket.current) {
      socket.current = io(apiUrl, {
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 30000,
        reconnectionDelayMax: 60000,
      });
    }

    if (socket.current && user?._id) {
      socket.current.emit('join', { id: user._id });

      socket.current.on('convertor', handleParsedData);

      return () => {
        socket.current.off('convertor');
      };
    }
  }, [user?._id]);

  const downloadFile = async () => {
    const url = BaseURL(`financial/project/${project?._id}/download`);
    setIsDownloading(true);
    const response = await Get(
      url,
      accessToken,
      undefined,
      true,
      undefined,
      'blob',
    );
    setIsDownloading(false);
    if (response?.data) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      const fileName = `${companyName}-${project?.name}.xlsx`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error('No Data Found');
    }
  };

  useEffect(() => {
    setListing(project?.taxReturns || []);
  }, [project]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = BaseURL('company/logo');
        const headers = apiHeader();
        const response = await Post(url, {}, headers);
        localStorage.setItem('testtest', response?.data?.isPaidClient);
        !response?.data?.isPaidClient && navigate('/');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <style>{`
      .table100 {
        overflow-x:scroll !important;
        padding-top: 0px;
      }
      .table100-head {
        min-width: 900px;
        position: relative;
      }
      .table100-body {
        min-width: 900px;
        max-height: calc(100vh - 390px);
        min-height: 200px;
        margin-top: 3px;
        overflow: auto;
      }
      .table100-body td {
        padding-block: 18px;
      }
      .column1{
        padding-left: 16px;
      }
      .table100.ver1 .table100-body tr {
        margin:15px;
      }
      .table100 .table100-head tr {
        margin: 0 15px;
      }
      `}</style>
      <div className={classes.listingHead}>
        <h4>Financial</h4>
        <div className={classes.btnContainer}>
          <div className={classes.inputMain}>
            <DropDown
              setter={setProject}
              value={project}
              placeholder={'Projects'}
              options={financialData}
              optionLabel={'name'}
              optionValue={'_id'}
              customStyle={{ padding: '0px 0px 4px 0px', fontSize: '14px' }}
              disabled={loading || isDownloading}
            />
          </div>
          <Button onClick={handleProjectClick} label={'Project'} />
          <Menu
            id="basic-menu"
            anchorEl={anchorProjectEl}
            open={Boolean(anchorProjectEl)}
            onClose={handleProjectClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={() => {
                setShowProjectModal('create');
                handleProjectClose();
              }}
              disabled={loading || isDownloading}
            >
              <ListItemIcon>
                <MdCreateNewFolder size={20} />
              </ListItemIcon>
              <ListItemText>Create</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowProjectModal('edit');
                handleProjectClose();
              }}
              disabled={loading || isDownloading || !project}
            >
              <ListItemIcon>
                <MdEdit size={20} />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </Menu>

          <Button onClick={handleFileClick} label={'File'} />
          <Menu
            id="basic-menu"
            anchorEl={anchorFileEl}
            open={Boolean(anchorFileEl)}
            onClose={handleFileClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={() => {
                inputRef.current.click();
                handleFileClose();
              }}
              disabled={loading || isDownloading || !project}
            >
              <ListItemIcon>
                <MdUpload size={20} />
              </ListItemIcon>
              <ListItemText>Upload</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowImportModal(true);
                handleFileClose();
              }}
              disabled={loading || isDownloading || !project}
            >
              <ListItemIcon>
                <MdImportExport size={20} />
              </ListItemIcon>
              <ListItemText>Import</ListItemText>
            </MenuItem>
          </Menu>
          <input
            hidden
            multiple
            type={'file'}
            accept={'.pdf'}
            ref={inputRef}
            onChange={(e) => uploadFiles(e.target.files)}
          />
          <Button
            onClick={() => {
              downloadFile();
            }}
            label={'Download'}
            disabled={loading || isDownloading || !project}
          />
        </div>
      </div>
      <div className="table100 ver1 m-b-110">
        <div className="table100-head">
          <table>
            <thead>
              <tr className="row100 head">
                <th
                  className="cell100 column1"
                  style={{ width: '45%', textAlign: 'left' }}
                >
                  File Name
                </th>
                <th
                  className={`cell100 column2`}
                  style={{
                    width: '20%',
                    textAlign: 'left',
                  }}
                >
                  Date & Time
                </th>
                <th
                  className="cell100 column2"
                  style={{ width: '15%', textAlign: 'left' }}
                >
                  Status
                </th>
                <th
                  className="cell100 column2"
                  style={{ width: '20%', textAlign: 'left' }}
                >
                  Action
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="table100-body js-pscroll ps ps--active-y">
          <table>
            <tbody>
              {loading ? (
                <Loader />
              ) : listing?.length == 0 ? (
                <NoData text={'No Data'} />
              ) : (
                listing?.map((item, index) => (
                  <tr
                    key={`${index}`}
                    className={`row100 body`}
                    style={{ cursor: 'pointer' }}
                  >
                    <td
                      className={`cell100 column1`}
                      style={{ width: '45%', textAlign: 'left' }}
                    >
                      <div
                        className={`${classes.noteContainer}`}
                        title={`${item?.fileName}`}
                      >
                        {`${item?.fileName}`}
                      </div>
                    </td>
                    <td
                      className={`cell100 column2 `}
                      style={{ width: '20%', textAlign: 'left' }}
                    >
                      {moment(item?.updatedAt || new Date()).format(
                        'MM/DD/YYYY, hh:mm',
                      )}
                    </td>
                    <td
                      className={`cell100 column2 `}
                      style={{ width: '15%', textAlign: 'left' }}
                    >
                      {item?.status == 'success' ? (
                        <MdCheck size={25} className={`${classes.icon}`} />
                      ) : item?.status == 'error' ? (
                        <MdSmsFailed
                          size={25}
                          className={`${classes.icon} ${classes.warningIcon}`}
                          title={item?.message}
                        />
                      ) : (
                        <div
                          className="spinner-border text-primary spinner-border-sm"
                          role="status"
                        ></div>
                      )}
                    </td>
                    <td
                      className={`cell100 column2`}
                      style={{ width: '20%', textAlign: 'left' }}
                    >
                      <div className={classes.btnContainer}>
                        <MdDelete
                          size={25}
                          className={`${classes.icon}`}
                          onClick={() => {
                            if (item?.status == 'pending') {
                              toast.warn(`Can't remove while processing`);
                              return;
                            }
                            setSelectedItem(item);
                            setShowSure(true);
                          }}
                        />
                        <MdSearch
                          size={25}
                          className={`${classes.icon}`}
                          onClick={() => {
                            if (item?.status == 'pending') {
                              toast.warn(`Can't show while processing`);
                              return;
                            } else if (item?.status != 'success') {
                              toast.warn(
                                `Parsing is failed. Hover status icon.`,
                              );
                              return;
                            }
                            setSelectedItem(item);
                            setShowDetail(true);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {showProjectModal && (
        <CreateFinancialProjectModal
          isLoading={loading}
          setShow={setShowProjectModal}
          data={showProjectModal == 'create' ? null : project}
          handleSubmit={
            showProjectModal == 'create'
              ? handleCreateProject
              : handleEditProject
          }
          handleRemoveSubmit={handleRemoveProject}
        />
      )}

      {showSure && (
        <AreYouSureModal
          show={showSure}
          setShow={setShowSure}
          subTitle={`Remove this File?`}
          onClick={removeFile}
        />
      )}

      {showDetail && (
        <TaxReturnViewModal
          loading={loading}
          show={showDetail}
          setShow={setShowDetail}
          file={selectedItem}
          onSave={onSave}
        />
      )}

      {showImportModal && (
        <ImportTaxReturnModal
          show={showImportModal}
          listingId={listingId}
          setShow={setShowImportModal}
          setImportFiles={setImportFiles}
        />
      )}
    </>
  );
};

export default TaxReturnConvertor;
