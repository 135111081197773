import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Badge from '@mui/material/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { BsThreeDots } from 'react-icons/bs';

import {
  apiHeader,
  BaseURL,
  formRegEx,
  formRegExReplacer,
  imageUrl,
  recordsLimit,
} from '../../../config/apiUrl';
import { moment } from '../../../utils/timeUtils';
import { setProjectsFilter } from '../../../store/common/commonSlice';
import { Delete, Get, Patch, Post } from '../../../Axios/AxiosFunctions';
import { Button } from '../../../Component/Button/Button';
import PaginationComponent from '../../../Component/PaginationComponent';
import useDebounce from '../../../CustomHooks/useDebounce';
import SearchInput from '../../../Component/SearchInput';
import PoperComponent from '../../../Component/PopperComponent';
import TableSkeleton from '../../../Component/TableSkeleton';
import NoData from '../../../Component/NoData/NoData';
import AreYouSureModal from '../../../modals/AreYouSureModal';
import AddProjectModal from '../../../modals/AddProjectModal';
import AddTaskModal from '../../../modals/AddTaskModal';
import GeneralFilterModal from '../../../modals/GeneralFilterModal';
import classes from './TaskManagerAllProjects.module.css';

const useStyles = makeStyles((theme) => ({
  badge: {
    minWidth: 15,
    height: 15,
    backgroundColor: '#ff0000',
  },
}));

const getNewestProject = (projects) => {
  if (!Array.isArray(projects) || projects.length === 0) {
    return null; // Return null if the array is empty or not an array
  }

  // Sort the projects based on the `createdAt` field in descending order (most recent first)
  const sortedProjects = projects.sort((a, b) => {
    const dateA = new Date(a.createdAt); // Convert `createdAt` to Date object
    const dateB = new Date(b.createdAt); // Convert `createdAt` to Date object

    // Compare dates: If `dateA` is more recent than `dateB`, it should come first
    return dateB - dateA; // This will return a positive number if dateA is earlier than dateB
  });

  return sortedProjects[0]; // Return the most recent project
};

const TaskManagerAllProjects = ({
  entityId,
  listingID = '',
  isShowAll = false,
  isArchive = false,
  status,
  isShowAddTask = false,
  selectProject = null,
}) => {
  const anchorRef = useRef(null);
  const {
    access_token: accessToken,
    user,
    company_id: companyId,
  } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  const { projectsFilter } = useSelector((state) => state?.commonReducer);
  const muiClasses = useStyles();
  const isAdmin = user?.role?.some((r) =>
    ['admin', 'super-admin', 'broker', 'executive', 'hr', 'sales'].includes(r),
  );

  const [projectsList, setProjectsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);

  const [openPopper, setOpenPopper] = useState(false);
  const [indexMap, setIndexMap] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const [addProjectModal, setAddProjectModal] = useState(false);
  const [isOpenAddTaskModal, setIsOpenAddTaskModal] = useState(false);
  const [deleteProjectConfirm, setDeleteProjectConfirm] = useState(false);
  const [archiveProjectConfirm, setArchiveProjectConfirm] = useState(false);
  const [submitloading, setSubmitLoading] = useState(false);
  // get Business Loader
  const [businessProjects, setBusinessProjects] = useState([]);
  const [assignees, setAssignees] = useState([]);

  const [allBrokers, setAllBrokers] = useState([]);
  const [projectData, setProjectData] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);

  const addUrl = BaseURL(`projects`);

  const getBusinessProjects = async () => {
    if (!isAdmin) return;
    const url = BaseURL(`business/admin/all?status=project`);
    const response = await Get(url, accessToken, companyId);
    if (Array.isArray(response?.data?.business)) {
      setBusinessProjects(response?.data?.business);
    }
  };

  const getAllProjects = async (
    pageNumber,
    st = status ? [{ value: status }] : projectsFilter?.status,
    br = projectsFilter?.broker?._id,
  ) => {
    if (status == 'all') {
      st = [
        { value: 'under-contract' },
        { value: 'published' },
        { value: 'sold' },
        { value: 'lost' },
        { value: 'off-market' },
      ];
    }
    const url = `${addUrl}?page=${pageNumber}&limit=${recordsLimit}&status=${encodeURIComponent(st?.map((ele) => ele?.value))}&entity=${entityId}&business=${listingID}&search=${search}&broker=${br}&isArchive=${isArchive}`;

    setLoading(true);
    const response = await Get(url, accessToken, companyId);
    if (response !== undefined) {
      console.log(
        ' projects => ',
        response?.data?.data?.project,
        response?.data?.data?.totalCount,
      );
      setProjectsList(response?.data?.data?.project);
      setTotalPages(Math.ceil(response?.data?.data?.totalCount / recordsLimit));

      setProjectData(getNewestProject(response?.data?.data?.project));
    }
    setLoading(false);
  };

  const getAssignees = async () => {
    const url = BaseURL(`users/get-all-admins`);
    setLoading(true);
    const response = await Get(url, accessToken, companyId);
    if (!!response) setAssignees((_prev) => [..._prev, response?.data?.data]);
  };

  useEffect(() => {
    getAllProjects(page);
  }, [page]);

  useEffect(() => {
    if (page == 1) getAllProjects(1);
    else setPage(1);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getBusinessProjects();
    getAssignees();
    getAdmins();
    getAllDropdownProjects();
  }, []);

  const handleAddProject = async (params, st) => {
    for (let key in params) {
      if (
        params[key] == '' ||
        params[key] == null ||
        params[key] == undefined
      ) {
        return toast.error(
          `Please provide the ${key
            .replace(formRegEx, formRegExReplacer)
            .toLowerCase()}`,
        );
      }
    }
    setSubmitLoading(true);

    const response = await Post(
      addUrl,
      params,
      apiHeader(accessToken),
      companyId,
    );
    if (response?.data?.data) {
      toast.success(`Project Added to ${st}.`);

      if (page == 1) {
        if (!status || st == status) {
          const oldData = [...projectsList];
          oldData.unshift(response?.data?.data);
          setProjectsList(oldData);
          setProjectData(getNewestProject(oldData));
        }
      } else {
        setPage(1);
      }
      setAddProjectModal(false);
    }
    setSubmitLoading(false);
  };

  const handleDeleteProject = async () => {
    const url = `${addUrl}/${selectedItem?.slug}`;
    setSubmitLoading(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    if (response?.data?.data) {
      const oldData = [...projectsList];
      oldData.splice(indexMap, 1);
      setProjectsList(oldData);
      setProjectData(getNewestProject(oldData));
      toast.success(`Project Deleted Successfully.`);
      setOpenPopper(false);
      setDeleteProjectConfirm(false);
      setIndexMap(null);
      setSelectedItem(null);
    }
    setSubmitLoading(false);
  };

  const handleArchiveProject = async () => {
    const url = `${addUrl}/${selectedItem?.slug}/archive`;
    setSubmitLoading(true);
    const response = await Patch(url, {}, apiHeader(accessToken));
    if (response?.data?.data) {
      const oldData = [...projectsList];
      oldData.splice(indexMap, 1);
      setProjectsList(oldData);
      setProjectData(getNewestProject(oldData));
      toast.success(`Project Archived Successfully.`);
      setOpenPopper(false);
      setArchiveProjectConfirm(false);
      setIndexMap(null);
      setSelectedItem(null);
    }
    setSubmitLoading(false);
  };

  const handleEditProject = async (params) => {
    for (let key in params) {
      if (
        params[key] == '' ||
        params[key] == null ||
        params[key] == undefined
      ) {
        return toast.error(
          `Please provide the ${key
            .replace(formRegEx, formRegExReplacer)
            .toLowerCase()}`,
        );
      }
    }
    const url = `${addUrl}/${selectedItem?.slug}`;
    setSubmitLoading(true);
    const data = {
      ...params,
      taskId: selectedItem?._id,
    };

    const response = await Patch(url, data, apiHeader(accessToken));
    if (response !== undefined) {
      toast.success('Project updated successfully!');
      if (page != 1) setPage(1);
      else getAllProjects(1);
      setIndexMap(null);
      setSelectedItem(null);
      setAddProjectModal(false);
      setOpenPopper(false);
    }
    setSubmitLoading(false);
  };

  const handleToggle = () => {
    setOpenPopper((prevOpen) => !prevOpen);
  };

  const handleActionClick = (flag) => {
    if (flag == 'Delete') {
      setDeleteProjectConfirm(true);
    } else if (flag == 'Edit') {
      setAddProjectModal(true);
    } else {
      setArchiveProjectConfirm(true);
    }
  };

  // useEffect(() => {
  //   if (!openPopper) setSelectedItem(null);
  // }, [openPopper]);

  // Add Task

  const getAllDropdownProjects = async (search = '') => {
    const addUrl = BaseURL(`projects/task-projects`);
    const url = `${addUrl}?&search=${search}`;

    const response = await Get(url, accessToken, companyId);
    if (response !== undefined) {
      const projects = response?.data?.data?.project;

      setAllCompanies(projects.filter((project) => project?.business));
    }
  };

  const getAdmins = async () => {
    const response = await Get(
      BaseURL(
        `users/get-admins?roles=${encodeURIComponent(['super-admin', 'admin', 'broker'])}`,
      ),
      accessToken,
      companyId,
      false,
    );
    if (response) {
      setAllBrokers(response?.data?.data);
    }
  };

  const HandleAddTaskSubmit = async (data) => {
    const params = { ...data, project: projectData?._id };
    for (let key in params) {
      if (key === 'description') {
        continue; // Skip this iteration
      }
      if (
        params[key] == '' ||
        params[key] == null ||
        params[key] == undefined
      ) {
        return toast.error(
          `Please provide the ${key
            .replace(formRegEx, formRegExReplacer)
            .toLowerCase()}`,
        );
      }
    }
    // params.description = description;
    const addTaskUrl = BaseURL('projects/add-task');
    setSubmitLoading(true);

    const response = await Post(
      addTaskUrl,
      params,
      apiHeader(accessToken),
      companyId,
    );
    if (response !== undefined) {
      // setLoading(true);
      toast.success(`Task created Successfully.`);
      // handleSearch(company, broker, page, owner, false);
      // setNoTask(false);
      // setShow(false);
      setIsOpenAddTaskModal(false);
    }
    setSubmitLoading(false);
  };

  const assignedTo = allBrokers?.filter(
    (blocker) => blocker?._id === user?._id,
  );

  return (
    <>
      <style>
        {(isShowAll
          ? `
      .table100-body {
        max-height: 3000px!important;
      }
      `
          : `
      .table100-body {
        height: calc(100vh - 335px);
      }
      `) +
          `
      .table100-body td {
        padding-block: 18px;
      }
      .column1{
        padding-left: 16px;
      }
      .table100 .table100-body tr {
        margin: 15px;
      }
      .table100 .table100-head tr {
        margin: 0 15px;
      }
      .table100-head, .table100-body {
        min-width: 1024px;
        width: 100%;
      }
      .table100 {
        overflow-x:scroll !important;
      }
      `}
      </style>
      <div>
        <div className={[classes.headerContainer]}>
          <h4>
            {isAdmin
              ? 'projects'
              : isArchive
                ? 'expired projects'
                : 'assigned projects'}
          </h4>
          <div className={[classes.inputDiv]}>
            <SearchInput
              setter={setSearch}
              value={search}
              placeholder={'Search'}
            />
            {!listingID && (
              <Badge
                badgeContent={
                  (projectsFilter?.status && projectsFilter?.status?.length) ||
                  (projectsFilter?.broker &&
                    projectsFilter?.broker?._id != 'null')
                    ? ' '
                    : 0
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                classes={{ badge: muiClasses.badge }}
              >
                <Button
                  label="Filter"
                  onClick={() => {
                    setShowFilterModal(true);
                  }}
                />
              </Badge>
            )}
            {isAdmin && (!status || status == 'all') && (
              <Button
                label="Add Project"
                onClick={() => {
                  setSelectedItem(null);
                  setAddProjectModal(true);
                }}
              />
            )}
            {isShowAddTask && (
              <Button
                label="Add Task"
                onClick={() => {
                  setSelectedItem(null);
                  setIsOpenAddTaskModal(true);
                }}
              />
            )}
          </div>
        </div>

        <div className="table100 ver1 m-b-110">
          <div className="table100-head">
            <table>
              <thead>
                <tr className="row100 head">
                  <th
                    className="cell100 column1"
                    style={{ width: '20%', textAlign: 'left' }}
                  >
                    Project Name
                  </th>
                  <th
                    className="cell100 column2"
                    style={{ width: '35%', textAlign: 'left' }}
                  >
                    Listing
                  </th>
                  <th
                    className="cell100 column3"
                    style={{ width: '15%', textAlign: 'left' }}
                  >
                    Assigned Member
                  </th>
                  <th
                    className="cell100 column4"
                    style={{ width: '10%', textAlign: 'center' }}
                  >
                    Date Created
                  </th>
                  <th
                    className="cell100 column4"
                    style={{ width: '10%', textAlign: 'center' }}
                  >
                    Status
                  </th>
                  <th
                    className="cell100 column5"
                    style={{ width: '10%', textAlign: 'center' }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          {loading ? (
            <TableSkeleton rowsCount={recordsLimit} colsCount={6} />
          ) : (
            <div className="table100-body js-pscroll ps ps--active-y">
              <table>
                <tbody>
                  {projectsList?.length > 0 ? (
                    projectsList?.map((item, index) => (
                      <tr
                        key={index}
                        className="row100 body"
                        style={{
                          backgroundColor:
                            selectedItem?._id == item?._id && '#E5F5FF',
                        }}
                      >
                        <td
                          className={`cell100 column1`}
                          style={{ width: '20%', textAlign: 'left' }}
                        >
                          {isArchive ? (
                            <div
                              className={classes.linkText}
                              onClick={() => selectProject(item?._id)}
                            >
                              {item?.name}
                            </div>
                          ) : (
                            <Link
                              to={`/task-manager/${item?.slug}`}
                              className={classes.linkText}
                            >
                              {item?.name}
                            </Link>
                          )}
                        </td>
                        <td
                          className="cell100 column2"
                          style={{
                            width: '35%',
                            textAlign: 'left',
                          }}
                        >
                          <Link
                            className={classes.linkText}
                            to={`/listing-detail/${item?.business?.slug}?tab=projects&company-name=${encodeURIComponent(item?.business?.companyName)}&id=${item?.business?._id}&status=${item?.business?.status}`}
                          >
                            {item?.business?.companyName}
                          </Link>
                        </td>
                        <td
                          className="cell100 column3"
                          style={{ width: '15%', textAlign: 'left' }}
                        >
                          <div style={{ position: 'relative' }}>
                            {item?.assignTo?.slice(0, 3)?.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className={[classes.teamMemberImgDiv]}
                                  style={{
                                    left:
                                      i == 1 ? '35px' : i == 2 ? '70px' : '0px',
                                  }}
                                >
                                  <img
                                    src={imageUrl(item?.photo)}
                                    alt={`${item?.firstName} ${item?.lastName}`}
                                    title={`${item?.firstName} ${item?.lastName}`}
                                  />
                                </div>
                              );
                            })}
                            {item?.assignTo?.length > 3 && (
                              <span className={[classes.countMember]}>
                                +{item?.assignTo?.length - 3}
                              </span>
                            )}
                          </div>
                        </td>
                        <td
                          className="cell100 column4"
                          style={{ width: '10%', textAlign: 'center' }}
                        >
                          {moment(item?.createdAt).format('L')}
                        </td>
                        <td
                          className="cell100 column4"
                          style={{ width: '10%', textAlign: 'center' }}
                        >
                          <p
                            className={`${classes.status} ${
                              classes[
                                `color-${
                                  (item?.business?.status == 'prospect' &&
                                    'red') ||
                                  (item?.business?.status == 'broker-review' &&
                                    'brown') ||
                                  (item?.business?.status == 'seller-review' &&
                                    'gray') ||
                                  (item?.business?.status == 'pocket' &&
                                    'pink') ||
                                  (item?.business?.status == 'published' &&
                                    'green') ||
                                  (item?.business?.status == 'under-contract' &&
                                    'orange') ||
                                  (item?.business?.status == 'sold' &&
                                    'blue') ||
                                  (item?.business?.status == 'lost' &&
                                    'black') ||
                                  (item?.business?.status == 'off-market' &&
                                    'yellow')
                                }`
                              ]
                            }`}
                          >
                            {item?.business?.status}
                          </p>
                        </td>
                        <td
                          className="cell100 column5"
                          style={{ width: '10%', textAlign: 'center' }}
                        >
                          <div
                            ref={index == indexMap ? anchorRef : null}
                            id="composition-button"
                            aria-controls={
                              openPopper ? 'composition-menu' : undefined
                            }
                            aria-expanded={openPopper ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={() => {
                              setSelectedItem(item);
                              setIndexMap(index);
                              setTimeout(() => {
                                handleToggle();
                              }, 100);
                            }}
                          >
                            <BsThreeDots
                              size={30}
                              className={[classes.threeDots]}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoData text={'No Project Found'} />
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className={[classes.paginationDiv]}>
          <PaginationComponent
            totalPages={totalPages}
            currentPage={page}
            setCurrentPage={setPage}
          />
        </div>
      </div>

      {showFilterModal && (
        <GeneralFilterModal
          show={showFilterModal}
          setShow={setShowFilterModal}
          isStatus={!status}
          status={projectsFilter?.status}
          broker={projectsFilter?.broker}
          allBrokers={allBrokers}
          setFilter={(st, broker) => {
            dispatch(
              setProjectsFilter({
                ...(!status ? { status: st } : { ...projectsFilter }),
                broker,
              }),
            );
            if (page == 1)
              getAllProjects(1, status ? [{ value: status }] : st, broker?._id);
            else setPage(1);
            setShowFilterModal(false);
          }}
        />
      )}

      {addProjectModal && (
        <AddProjectModal
          handleSubmit={
            selectedItem == null ? handleAddProject : handleEditProject
          }
          show={addProjectModal}
          setShow={setAddProjectModal}
          data={selectedItem}
          isLoading={submitloading}
          selectedListing={listingID}
          selectedListings={
            entityId
              ? businessProjects?.filter((ele) => ele?.entity == entityId)
              : businessProjects
          }
          assignees={assignees}
        />
      )}

      {isOpenAddTaskModal && (
        <AddTaskModal
          handleSubmit={HandleAddTaskSubmit}
          allAdmins={allBrokers}
          show={isOpenAddTaskModal}
          setShow={() => {
            // setSelectedTask(null);
            setIsOpenAddTaskModal(false);
            // setProjectData(null);
          }}
          data={{
            // ...selectedTask?.item,
            project: projectData?.name,
            isAdd: true,
            assignedTo,
          }}
          optionsArrayStages={projectData?.stages}
          isLoading={submitloading}
          isShowProjectDropdown={true}
          setProjectData={setProjectData}
          projectData={projectData}
          allCompanies={allCompanies}
          loadingProjects={loading}
        />
      )}

      {deleteProjectConfirm && (
        <AreYouSureModal
          show={deleteProjectConfirm}
          setShow={setDeleteProjectConfirm}
          subTitle={`Do you want to delete ${selectedItem?.name} Project`}
          onClick={handleDeleteProject}
          isApiCall={submitloading}
        />
      )}

      {archiveProjectConfirm && (
        <AreYouSureModal
          show={archiveProjectConfirm}
          setShow={setArchiveProjectConfirm}
          subTitle={`Do you want to archive ${selectedItem?.name} Project`}
          onClick={handleArchiveProject}
          isApiCall={submitloading}
        />
      )}

      <PoperComponent
        handleClick={handleActionClick}
        open={openPopper}
        setOpen={setOpenPopper}
        anchorRef={anchorRef}
        data={['Edit', ...(isArchive ? [] : ['Archive']), 'Delete']}
      />
    </>
  );
};

export default TaskManagerAllProjects;
