import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import ModalSkeleton from '../ModalSkeleton';
import { Button } from '../../Component/Button/Button';
import { DropDown } from '../../Component/DropDown/DropDown';
import classes from './AddSignatureModal.module.css';

const AddSignatureModal = ({
  isOpen,
  setIsOpen,
  recipients = [],
  setReciptents,
  sellers = [],
  buyers = [],
  brokers = [],
  thirdPartyContacts = [],
}) => {
  const options = {
    Buyer: buyers,
    Seller: sellers,
    Broker: brokers,
    '3rd-party': thirdPartyContacts,
  };

  const [role, setRole] = useState({ value: 'Buyer', label: 'Buyer' });
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(null);
  }, [role]);

  return (
    <>
      <ModalSkeleton
        show={isOpen}
        setShow={setIsOpen}
        borderRadius="20px"
        width="650px"
        borderLine={false}
        header={'Select Signature Field'}
        showCloseIcon={true}
      >
        <Row className="gy-3">
          <Col md={4}>
            <DropDown
              value={role}
              placeholder={`Role`}
              options={[
                { value: 'Buyer', label: 'Buyer' },
                { value: 'Seller', label: 'Seller' },
                { value: 'Broker', label: 'Broker' },
                { value: '3rd-party', label: '3rd-party' },
              ]}
              optionLabel={'label'}
              optionValue={'value'}
              label={`Role`}
              setter={setRole}
            />
          </Col>
          <Col md={6}>
            <DropDown
              value={user}
              placeholder={`User`}
              options={options?.[role?.value]}
              optionLabel={'name'}
              optionValue={'_id'}
              label={`User`}
              setter={setUser}
            />
          </Col>
          <Col md={2}>
            <Button
              type="button"
              label="Add"
              className={classes.addBtn}
              onClick={() => {
                if (recipients.find((item) => item?.id == user?._id))
                  return toast.error('User already added');
                setReciptents([
                  ...recipients,
                  {
                    send_email: true,
                    send_email_delay: 0,
                    id: user?._id,
                    name: user?.name,
                    email: user?.email,
                    subject: 'Contract Agreement for Signature by SignWell',
                    message: 'Hello, Please review agreement and do signature',
                    role: role?.value,
                  },
                ]);
                setUser(null);
              }}
            />
          </Col>
          <hr className={classes.spliter} />
          {recipients?.map((recipient, index) => (
            <>
              <Col md={10}>
                <DropDown
                  value={options?.[recipient?.role]?.find(
                    (item) => item?._id == recipient?.id,
                  )}
                  placeholder={`${recipient?.role} Name`}
                  options={options?.[recipient?.role]}
                  optionLabel={'name'}
                  optionValue={'_id'}
                  label={`${recipient?.role} Name`}
                  setter={(item) => {
                    setReciptents(
                      recipients.map((item, ind) => {
                        if (ind === index) {
                          return {
                            send_email: true,
                            send_email_delay: 0,
                            id: recipient?._id,
                            name: recipient?.name,
                            email: recipient?.email,
                            subject:
                              'Contract Agreement for Signature by SignWell',
                            message:
                              'Hello, Please review agreement and do signature',
                            role: recipient?.role,
                          };
                        }
                        return item;
                      }),
                    );
                  }}
                />
              </Col>
              <Col md={2}>
                <Button
                  type="button"
                  label="Remove"
                  className={classes.removeBtn}
                  onClick={() => {
                    setReciptents(recipients.filter((_, ind) => ind !== index));
                  }}
                />
              </Col>
            </>
          ))}
        </Row>
      </ModalSkeleton>
    </>
  );
};

export default AddSignatureModal;
