import React, { useState } from 'react';
import { DiCssTricks } from 'react-icons/di';
import { MdDelete } from 'react-icons/md';
import AddMoreBtn from '../AddMoreBtn';
import { Input } from '../Input/Input';
import classes from './AddEditMultiValueInputContainer.module.css';
import { array } from 'prop-types';

const AddEditMultiValueInputContainer = ({
  title = 'Default Title',
  arrayValue,
  arraySetter,
  placeholder = 'Enter Text',
  type = 'text',
  subDelete = undefined,
  autoFocus,
  disabled = false,
}) => {
  const [inputValue, inputSetter] = useState();

  return (
    <div className={classes?.mainContainer}>
      <div
        className={[
          title
            ? classes?.inputAndAddBtnContainer
            : classes?.inputAndAddBtnContainerWithoutTitle,
        ].join(' ')}
      >
        <span className={classes?.titleText}>{title}</span>
        <AddMoreBtn
          onClick={() => {
            if (inputValue !== '') {
              arraySetter((pre) => [...pre, inputValue]);
              inputSetter('');
            }
          }}
          disabled={disabled}
        />
      </div>
      <Input
        autoFocus={autoFocus}
        setter={inputSetter}
        value={inputValue}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
      />
      {arrayValue.map((item, index) => (
        <div className={classes.inputBoxContainer}>
          <Input
            setter={(value) => {
              arraySetter((prev) => [
                ...prev.slice(0, index),
                value,
                ...prev.slice(index + 1),
              ]);
            }}
            value={item}
            type={type}
            disabled={disabled}
            leftIcon={
              <DiCssTricks color={'var(--dashboard-main-color)'} size={12} />
            }
            rightIcon={
              <MdDelete
                color={'var(--dashboard-main-color)'}
                size={20}
                onClick={() => {
                  if (disabled) return;
                  if (subDelete !== undefined) {
                    subDelete(index);
                  } else {
                    arraySetter(arrayValue.filter((_, i) => i !== index));
                  }
                }}
              />
            }
          />
        </div>
      ))}
    </div>
  );
};

export default AddEditMultiValueInputContainer;
