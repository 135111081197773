import React from 'react';
import { FaPlus } from 'react-icons/fa';
import classes from './AddMoreBtn.module.css';

const AddMoreBtn = ({ onClick = () => {}, disabled = false }) => {
  return (
    <div className={classes?.addMoreBtnContainer} onClick={() => !disabled && onClick()}>
      <FaPlus color={'white'} size={12} /> <span>Add More</span>
    </div>
  );
};

export default AddMoreBtn;
