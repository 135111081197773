import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Button } from '../../Component/Button/Button';
import { DropDown } from '../../Component/DropDown/DropDown';
import ModalSkeleton from '../ModalSkeleton';
import classes from './AddUserToVipList.module.css';
import { toast } from 'react-toastify';
import { BaseURL } from '../../config/apiUrl';
import { Get } from '../../Axios/AxiosFunctions';
import useDebounce from '../../CustomHooks/useDebounce';

function AddUserToVipListModal({ show, setShow, isLoading, data, onClick }) {
  const { access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state.authReducer,
  );

  const [users, setUsers] = useState([]);
  const [removeUser, setRemoveUser] = useState('');

  const [usersOptions, setUserOptions] = useState([]);

  const [userSearch, setUserSearch] = useState('');
  const userSearchDebounce = useDebounce(userSearch, 1000);

  const getBuyers = async () => {
    const url = BaseURL(
      `users/buyers/all?search=${userSearch}`,
    );
    const response = await Get(url, accessToken, companyId);
    if (response !== undefined) {
      setUserOptions(response?.data?.data || []);
    }
  }
  useEffect(() => {
    if (data) {
      setUsers(data?.buyer || []);
    }
  }, []);

  useEffect(() => {
    if (!userSearch) {
      setUserOptions([]);
      return;
    }
    getBuyers();
  }, [userSearchDebounce]);

  const onChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
        if (removeUser == '') {
          setRemoveUser(actionMeta?.removedValue?._id);
          setUsers(newValue);
          return;
        } else {
          return toast.error(
            'Sorry! You can not remove multiple users at a time.',
          );
        }
    }

    setUsers(newValue);
  };

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      width="600px"
      borderRadius="20px"
      showCloseIcon={true}
      header={'Add Buyers To VIP List'}
    >
      <div className={classes.container}>
        <Row className={classes.row}>
          <Col md={12}>
            <DropDown
              inputValue={userSearch}
              inputSetter={setUserSearch}
              onChange={onChange}
              value={users}
              options={usersOptions}
              optionValue={'_id'}
              getOptionLabel={(e) => `${e.email}(${e.firstName} ${e.lastName})`}
              placeholder={'Press Enter to Search'}
              isMulti={true}
              label={'Choose Buyers'}
              // disabled={usersLoading}
            />
          </Col>
        </Row>
        <div className={classes.btn_main}>
          <Button
            onClick={() =>
              onClick({
                users: users.filter((e) => e?.contact),
                removeUser,
              })
            }
            className={classes.btn}
            label={isLoading ? 'Adding...' : 'Add'}
            disabled={isLoading}
          />
        </div>
      </div>
    </ModalSkeleton>
  );
}

export default AddUserToVipListModal;
