import React, { useState, useEffect } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import ModalSkeleton from '../ModalSkeleton';
import PdfViewer from './secttions/PdfViewer';
import EditableTable from './secttions/EditableTable';
import classes from './TaxReturnViewModal.module.css';
import { mediaUrl } from '../../config/apiUrl';

const TaxReturnViewModal = ({ loading, show, setShow, file, onSave }) => {
  const [sheet, setSheet] = useState('income');
  const [page, setPage] = useState(0);

  const onSheetChange = (event, newValue) => {
    setSheet(newValue);
  };

  useEffect(() => {
    setPage(file?.parsedData[sheet]?.page || 0);
  }, [sheet]);

  return (
    <>
      <style>{`
      .MuiFormControl-root{
        width: 100%;
      }
      .MuiFormLabel-root {
        color: var(--placeholder-color) !important;
      }
      .MuiOutlinedInput-notchedOutline {
        box-shadow: 0px 0 5px 2px #0000000d;
        border-width: 0;
        border: none;
        border-radius: 10px;
        box-shadow: 0px 0 5px 2px #0000000d;
      }
      `}</style>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        borderRadius="20px"
        borderLine={false}
        width={'100vw'}
        header={'Detail View'}
        showCloseIcon={true}
      >
        <RadioGroup
          row
          name="row-radio-buttons-group"
          defaultValue="income"
          onChange={onSheetChange}
        >
          <FormControlLabel value="income" control={<Radio />} label="Income" />
          <FormControlLabel
            value="balance"
            control={<Radio />}
            label="Balance"
          />
          <FormControlLabel value="cgs" control={<Radio />} label="CGS" />
          <FormControlLabel
            value="deduction"
            control={<Radio />}
            label="Deduction"
          />
        </RadioGroup>
        <div className={classes.mainBody}>
          <PdfViewer pdfUrl={mediaUrl(file?.fileId)} pageNumber={page} />
          <EditableTable
            loading={loading}
            tableData={file?.parsedData[sheet]?.data}
            sheet={sheet}
            setTableData={(data) => onSave(file?._id, sheet, data)}
          />
        </div>
      </ModalSkeleton>
    </>
  );
};

export default TaxReturnViewModal;
