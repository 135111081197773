import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MdAdd, MdRemove } from 'react-icons/md';
import { toast } from 'react-toastify';

import { apiHeader, BaseURL } from '../../config/apiUrl';
import { Get, Patch } from '../../Axios/AxiosFunctions';
import NoteCard from '../../Component/NoteCard';
import Loader from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';
import AreYouSureModal from '../../modals/AreYouSureModal';
import classes from './NotesList.module.css';

const NotesList = ({ slug, status }) => {
  const { user, access_token: accessToken } = useSelector(
    (state) => state?.authReducer || {},
  );

  const isSuperAdmin = user?.role?.includes('super-admin');

  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState('');
  const [removeID, setRemoveID] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  const syncWithDB = async () => {
    const response = await Get(
      BaseURL(`business/notes/${slug}?status=${status}`),
      accessToken,
    );
    return response?.data?.data?.notes || [];
  };

  const getNotes = async () => {
    setLoading(true);

    const response = await Get(
      BaseURL(`business/notes/${slug}?status=${status}`),
      accessToken,
    );

    if (response != undefined) {
      console.log(response?.data?.data?.notes);
      setNotes(response?.data?.data?.notes || []);
    }

    setLoading(false);
  };

  const updateNotes = async (notes) => {
    setSending(true);
    const response = await Patch(
      BaseURL(`business/notes`),
      {
        status,
        slug,
        notes,
      },
      apiHeader(accessToken),
    );

    if (response != undefined) {
      setNotes(response?.data?.notes);
      setSending(false);
      return true;
    }
    setSending(false);
    return false;
  };

  const onRemove = async (_id) => {
    const ns = await syncWithDB();
    const newNotes = ns.filter((note) => note._id != _id);
    const ret = await updateNotes(newNotes);

    if (ret) {
      toast.success('Removed successfully.');
    }
  };

  const onRemoveAll = async () => {
    const newNotes = [];
    const ret = await updateNotes(newNotes);

    if (ret) {
      toast.success('All Removed successfully.');
    }
  };

  const onSubmitNote = async () => {
    const ns = await syncWithDB();

    if (!selectedNote) {
      const newNotes = [
        {
          creator: user?._id,
          message: message,
        },
        ...ns,
      ];
      const ret = await updateNotes(newNotes);

      if (ret) {
        setMessage('');
        setShowEdit(false);
        toast.success('Added successfully.');
      }
    } else {
      const updatedNotes = notes?.map((note) => {
        if (note?._id === selectedNote?._id) {
          note.message = message || '';
          note.updatedAt = new Date();
        }

        return note;
      });

      const res = await updateNotes(updatedNotes);

      if (res) {
        setSelectedNote(null);
        setMessage('');
        setShowEdit(false);
        toast.success('Note updated successfully.');
      }
    }
  };

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <>
      <div className={[classes.mainContainer]}>
        <div className={[classes.headerContainer]}>
          <div className={classes.btnMain}>
            {!showEdit && (
              <>
                {isSuperAdmin && (
                  <div
                    onClick={() => {
                      setRemoveID(null);
                      setIsOpenModal(true);
                    }}
                    className={classes.btnDiv}
                    disabled={notes?.length == 0}
                  >
                    <MdRemove /> Remove All Notes
                  </div>
                )}
                <div
                  onClick={() => setShowEdit(true)}
                  className={classes.btnDiv}
                  label={``}
                  disabled={sending || loading}
                >
                  <MdAdd /> Add Note
                </div>
              </>
            )}
          </div>
        </div>
        {showEdit && (
          <NoteCard
            creator={`${user?.firstName} ${user?.lastName}`}
            message={message}
            date={new Date().toUTCString()}
            isEdit={true}
            setMessage={setMessage}
            onClose={() => {
              setShowEdit(false);
              setMessage('');
              setSelectedNote(null);
            }}
            sending={sending}
            submit={onSubmitNote}
            showEditButton={false}
          />
        )}
        {loading ? (
          <Loader />
        ) : !notes?.length ? (
          <NoData />
        ) : (
          <div>
            {notes.map((note) => (
              <NoteCard
                creator={`${note?.creator.firstName} ${note?.creator.lastName}`}
                message={note?.message}
                date={note?.createdAt}
                isRemove={isSuperAdmin}
                onRemove={() => {
                  setRemoveID(note?._id);
                  setIsOpenModal(true);
                }}
                onEdit={() => {
                  setMessage(note?.message);
                  setSelectedNote(note);
                  setShowEdit(true);
                }}
                showEditButton={true}
              />
            ))}
          </div>
        )}
      </div>
      {isOpenModal && (
        <AreYouSureModal
          isApiCall={false}
          setShow={setIsOpenModal}
          show={isOpenModal}
          onClick={() => {
            if (removeID) onRemove(removeID);
            else onRemoveAll();
            setIsOpenModal(false);
          }}
          subTitle={
            removeID
              ? `Do you want to remove this note?`
              : 'Do you want to remove all of your notes?'
          }
        />
      )}
    </>
  );
};

export default NotesList;
