import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Button } from '../../Component/Button/Button';
import { DropDown } from '../../Component/DropDown/DropDown';
import { Input } from '../../Component/Input/Input';
import ModalSkeleton from '../ModalSkeleton';
import classes from './AddProjectModal.module.css';

const AddProjectModal = ({
  show,
  setShow,
  handleSubmit,
  data,
  isLoading = false,
  selectedListing = null,
  selectedListings = [],
  assignees = [],
}) => {
  const [projectName, setProjectName] = useState('');
  const [listingName, setListingName] = useState('');
  const [assignTo, setAssignTo] = useState([]);

  useEffect(() => {
    if (!!data) {
      let listObject = selectedListings.find(
        (x) => x?._id == data?.business?._id,
      );
      setProjectName(data?.name);
      setListingName(listObject);
      setAssignTo(data?.assignTo);
    } else if (!!selectedListing) {
      let listObject = selectedListings.find((x) => x?._id == selectedListing);
      setListingName(listObject);
    }
  }, [data]);

  useEffect(() => {
    if (selectedListing) {
      let listObject = selectedListings.find((x) => x?._id == selectedListing)

      let listBrokers = [];

      listBrokers = assignees?.[0]?.filter((item) =>
        listObject?.broker?.includes(item?._id),
      );

      setAssignTo(listBrokers);
    }
  }, [selectedListing]);

  const HandleSubmitData = () => {
    const newAssignToArray = assignTo?.map((item) => item?._id);

    handleSubmit({
      name: projectName,
      assignTo: newAssignToArray,
      business: listingName?._id,
    }, listingName?.status);
  };

  return (
    <>
      <style>{`
        .modal-content{
          overflow:visible !important;
        }
      `}</style>
      <div>
        <ModalSkeleton
          show={show}
          setShow={setShow}
          width="700px"
          borderRadius="20px"
          header={data == null ? 'Add Project' : 'Edit Project'}
          showCloseIcon={true}
        >
          <div className={classes.addProjectModal_main}>
            <Row className={classes.addProject_row}>
              <Input
                setter={setProjectName}
                value={projectName}
                placeholder={'Project Name'}
              />
              <DropDown
                setter={(e) => {
                  setListingName(e);

                  let listBrokers = [];

                  listBrokers = assignees?.[0]?.filter((item) =>
                    e?.broker?.includes(item?._id),
                  );

                  setAssignTo(listBrokers);
                }}
                value={listingName}
                placeholder={'Listing Name'}
                options={selectedListings}
                optionLabel={'companyName'}
                optionValue={'companyName'}
                disabled={selectedListing}
              />
              <DropDown
                setter={setAssignTo}
                value={assignTo}
                isMulti
                placeholder={'Assigned To'}
                options={assignees[0]}
                getOptionLabel={(option) =>
                  option?.firstName + ' ' + option?.lastName
                }
                getOptionValue={(option) =>
                  option?.firstName + ' ' + option?.lastName
                }
              />
            </Row>
            <div className={classes.btn_main}>
              <Button
                onClick={() => HandleSubmitData()}
                className={classes.btn}
                label={
                  isLoading
                    ? 'Submitting...'
                    : data == null
                      ? 'Add Project'
                      : 'Edit Project'
                }
                disabled={isLoading}
              />
            </div>
          </div>
        </ModalSkeleton>
      </div>
    </>
  );
};

export default AddProjectModal;
