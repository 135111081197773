import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { MdClose } from 'react-icons/md';

import { moment } from '../../utils/timeUtils';
import { stringToColor } from '../../utils/functions';
import { TextArea } from '../TextArea';
import { Button } from '../Button/Button';
import classes from './NoteCard.module.css';

const NoteCard = ({
  creator,
  message,
  date,
  isRemove = false,
  onRemove,
  isEdit = false,
  setMessage,
  onClose,
  sending,
  submit,
  onEdit,
  showEditButton
}) => {
  return (
    <Box sx={{ minWidth: 275, marginBottom: '5px' }}>
      <Card variant="outlined">
        <Fragment>
          <CardContent>
            <div className={classes.header}>
              <div
                style={{
                  marginBottom: '0.5em',
                  fontSize: '1.2em',
                  color: stringToColor(`${creator}`),
                  cursor: 'pointer',
                }}
              >
                {creator}
              </div>
              <div className="d-flex align-content-center gap-2">
                {showEditButton && <div className={classes.btnDiv} onClick={onEdit}>
                  Edit
                </div>}
                {isRemove && (
                  <div className={classes.btnDiv} onClick={onRemove}>
                    Remove
                  </div>
                )}
              </div>
              {isEdit && (
                <div className={classes.btnDiv} onClick={onClose}>
                  <MdClose size={30} />
                </div>
              )}
            </div>

            {isEdit ? (
              <TextArea
                setter={setMessage}
                value={message}
                placeholder={'Input Message'}
                rows={2}
              />
            ) : (
              <div className={classes.msgContainer}>{message}</div>
            )}

            <div
              style={{
                fontSize: '0.8em',
                color: 'var(--dashboard-main-color)',
                cursor: 'pointer',
              }}
            >
              🕑 {moment(date).format('MM-DD-YYYY hh:mm')}
            </div>
            {isEdit && (
              <div className={classes.btnMain}>
                <Button
                  onClick={submit}
                  className={classes.btn}
                  label={`Submit`}
                  disabled={sending}
                />
              </div>
            )}
          </CardContent>
        </Fragment>
      </Card>
    </Box>
  );
};

export default NoteCard;
