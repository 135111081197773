import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';

import classes from './ContractHeader.module.css';

const TABS_LIST = [
  { label: 'Contract Templates', link: '/contract-templates' },
  { label: 'Contract Agreements', link: '/contract-agreements' },
  { label: 'Agreement Status', link: '/contract-sent' },
  { label: 'NDA Template', link: '/nda-template' },
];

const ContractHeader = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <div className={classes?.tab}>
        {TABS_LIST?.map(({ label, link }) => (
          <Link
            to={link}
            className={pathname === link ? classes?.activeTab : ''}
          >
            {label}
          </Link>
        ))}
      </div>
    </Box>
  );
};

export default ContractHeader;
