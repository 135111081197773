import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  logo: null,
  loading: false,
};

const companySlice = createSlice({
  name: 'companySlice',
  initialState,
  reducers: {
    setLogo(state, action) {
      state.logo = action.payload;
    },
    setCompanyName(state, action) {
      state.companyName = action.payload;
    },
    setCompanyFrontendDomain(state, action) {
      state.frontendDomain = action.payload;
    },
    setCompanyAddress(state, action) {
      state.companyAddress = action.payload;
    },
    setLogoLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {
  setLogo,
  setCompanyName,
  setCompanyFrontendDomain,
  setCompanyAddress,
  setLogoLoading,
} = companySlice.actions;

export default companySlice.reducer;
