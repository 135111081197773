import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MdChat, MdFolderOff, MdFolderOpen } from 'react-icons/md';
import { BsChatDots } from 'react-icons/bs';
import Badge from '@mui/material/Badge';
import { makeStyles } from '@material-ui/core/styles';

import {
  apiHeader,
  BaseURL,
  formRegEx,
  formRegExReplacer,
  ReturnFormatedNumber,
} from '../../config/apiUrl';
import {
  leadProgressColors,
  leadProgressOptions,
  leadStatusOptions,
} from '../../constant/commonData';
import { Get, Patch, Post } from '../../Axios/AxiosFunctions';
import dayjs, { moment } from '../../utils/timeUtils';
import PaginationComponent from '../../Component/PaginationComponent';
import { DropDown } from '../../Component/DropDown/DropDown';
import TableSkeleton from '../../Component/TableSkeleton';
import PoperComponent from '../../Component/PopperComponent';
import NoData from '../../Component/NoData/NoData';
import useDebounce from '../../CustomHooks/useDebounce';
import SearchInput from '../../Component/SearchInput';
import { Button } from '../../Component/Button/Button';
import AddOrEditNotesModal from '../../modals/AddorEditNotesModal';
import NotesDetailPopup from '../../Component/NotesDetail';
import AddInterestModal from '../../modals/AddInterestModal';
import InterestsFilterModal from '../../modals/InterestsFilterModal';
import AreYouSureModal from '../../modals/AreYouSureModal';
import classes from './Interests.module.css';

const useStyles = makeStyles((theme) => ({
  badge: {
    minWidth: 15,
    height: 15,
    backgroundColor: '#ff0000',
    zIndex: '90',
  },
}));

const Interests = ({
  title = 'Interests',
  listingStatus = null,
  entityId = null,
  isShowAll,
  listingID = null,
  companyName = null,
}) => {
  const navigate = useNavigate();
  const {
    access_token: accessToken,
    user,
    company_id: companyId,
  } = useSelector((state) => state?.authReducer);
  const muiClasses = useStyles();
  const recordsLimit = 20;

  const isAdmin = (user?.role || []).some((role) =>
    ['admin', 'super-admin', 'executive', 'hr', 'sales'].includes(role),
  );
  const [listing, setListing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Lead Status
  const [leadStatus, setLeadStatus] = useState();

  // For Options
  const anchorRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState('');
  const [isInterestModalOpen, setIsInterestModalOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isModalLoading, setIsModalLoading] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  // For Filter
  const [allBrokers, setAllBrokers] = useState([]);

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [broker, setBroker] = useState({ _id: 'null', email: 'All' });

  const [filterListing, setFilterListing] = useState({
    _id: listingID,
    companyName,
  });
  const [allListing, setAllListing] = useState([]);
  const [inputListing, setInputListing] = useState('');
  const listingDebouncedSearch = useDebounce(inputListing, 1000);

  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);

  const [notesPopupAnchor, setNotesPopupAnchor] = useState(null);
  const [notes, setNotes] = useState([]);

  const [status, setStatus] = useState({ label: 'All', value: 'all' });
  const [leadProgress, setLeadProgress] = useState({
    label: 'All',
    value: 'all',
  });

  const [outsideLead, setOutsideLead] = useState(false);

  const [selectedInterest, setSelectedInterest] = useState('');
  const [selectedListingID, setSelectedListingID] = useState('');
  // access data room modal
  const [accessRoomModal, setAccessRoomModal] = useState(false);
  const [accessRoomLoader, setAccessRoomLoader] = useState(false);
  const [isAssignDataRoom, setIsAssignDataRoom] = useState('');
  const [selectedNote, setSelectedNote] = useState(null);

  const getLeadProgressColor = (status, isBackground = true) => {
    const style = leadProgressColors[status] || leadProgressColors['new'];
    return isBackground ? style.background : style.color;
  };

  const accessDataRoom = async (businessId, isAssignDataRoom) => {
    const url = BaseURL(
      isAssignDataRoom
        ? `business/unassign-buyer-from-dataroom`
        : `business/assign-buyer-to-dataroom`,
    );
    const params = {
      businessId: businessId,
      ...(isAssignDataRoom
        ? { userId: selectedInterest?.buyer?._id }
        : { userIds: [selectedInterest?.buyer?._id] }),
    };
    setAccessRoomLoader(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    if (response !== undefined) {
      toast.success('Assigned Successfully');
      setIsAssignDataRoom(!isAssignDataRoom);

      let assigned = selectedInterest?.listingID?.buyerAssignedToDataRoom;
      if (isAssignDataRoom) {
        assigned = assigned?.filter(
          (ele) => String(ele) != String(selectedInterest?.buyer?._id),
        );
      } else {
        assigned?.push(selectedInterest?.buyer?._id);
      }

      setListing(
        listing?.map((ele) => {
          if (ele?._id == selectedInterest?._id) {
            return {
              ...selectedInterest,
              listingID: {
                ...ele?.listingID,
                buyerAssignedToDataRoom: assigned,
              },
            };
          } else {
            return ele;
          }
        }),
      );
      setAccessRoomModal(false);
    }
    setAccessRoomLoader(false);
  };

  const getAllInterests = async (
    pg = page,
    filterStatus = status?.value,
    lP = leadProgress?.value,
    br = broker?._id,
    com = filterListing?._id,
  ) => {
    const url = BaseURL(
      `leads/admin?page=${pg}&limit=${recordsLimit}&entity=${entityId}&status=${filterStatus}&lead-progress=${lP}&search=${search}&listing=${com}&broker=${br}&listing-status=${listingStatus}`,
    );
    setLoading(true);
    const response = await Get(url, accessToken, companyId);
    if (response !== undefined) {
      console.log('interests => ', response?.data?.lead);
      setListing(response?.data?.lead);
      setLeadStatus(
        response?.data?.lead?.map((item) => {
          const status = leadProgressOptions?.find(
            (e) => e?.value == item?.leadProgress,
          );
          return {
            leadId: item?._id,
            status: {
              value: status?.value,
              label: `${status?.label}\n(${moment(item?.progressUpdatedAt || item?.createdAt).format('MM/DD/YY h:mm A')})`,
            },
          };
        }),
      );
      setTotalPages(response?.data?.countDoc);
    }
    setLoading(false);
  };

  const getNotes = () => {
    if (selectedItem) {
      Get(BaseURL(`notes/getNote/${selectedItem?.buyer?.email}`), accessToken)
        .then((res) => {
          if (Array.isArray(res?.data)) {
            res?.data.sort((a, b) => new Date(b?.date) - new Date(a?.date));
            setNotes(res.data);
          }
        })
        .catch((err) => {
          setNotes([]);
        });
    }
  };

  const getAdmins = async () => {
    const response = await Get(
      BaseURL(
        `users/get-admins?roles=${encodeURIComponent(['super-admin', 'admin', 'broker', 'executive', 'hr', 'sales'])}`,
      ),
      accessToken,
      companyId,
      false,
    );
    if (response) {
      setAllBrokers(response?.data?.data);
    }
  };

  const getAllBuyerContacts = async (search) => {
    if (!search) {
      setContacts([]);
      return;
    }
    const url = BaseURL(`users/buyers/all?search=${search}&page=0&limit=20`);
    const response = await Get(url, accessToken, companyId);
    if (response !== undefined) {
      setContacts(response?.data?.data);
    }
  };

  const updateLeadStatus = async (item, status) => {
    const apiUrl = BaseURL(`leads/update-lead-status`);
    const body = {
      leadId: item?._id,
      leadProgress: item?.leadProgress,
      status: status,
    };
    const response = await Patch(apiUrl, body, apiHeader(accessToken));
    if (response !== undefined) {
      const newData = [...listing];
      newData?.splice(
        newData.findIndex((item) => item?._id == response?.data?._id),
        1,
        response?.data,
      );
      setListing(newData);
      toast.success('Lead status updated successfully');
    }
  };

  const updateLeadProgress = async (e) => {
    const url = BaseURL(`leads/update-lead-status`);
    const body = {
      leadId: e?.leadId,
      leadProgress: e?.status?.value,
    };
    const response = await Patch(url, body, apiHeader(accessToken));
    if (response !== undefined) {
      const newData = [...listing];
      newData?.splice(
        newData.findIndex((item) => item?._id == response?.data?._id),
        1,
        response?.data,
      );
      setListing(newData);

      const newStatus = leadStatus?.map((item) =>
        item?.leadId == response?.data?._id
          ? {
              ...item,
              status: {
                value: item?.status?.value,
                label: `${item?.status?.label}\n(${moment(response?.data?.progressUpdatedAt || response?.data?.createdAt).format('MM/DD/YY h:mm A')})`,
              },
            }
          : item,
      );
      setLeadStatus(newStatus);
      toast.success('Lead progress updated successfully');
    }
  };

  const onSubmitNote = (newNote) => {
    var postTime = new Date().toLocaleString();
    if (!newNote || !selectedItem) return;

    if (!selectedNote) {
      Post(BaseURL('notes/create'), {
        text: newNote,
        date: null,
        writer: `${user?.firstName} ${user?.lastName}`,
        sender: user?.email,
        receiver: selectedItem?.buyer?.email,
        listing: selectedItem?.listingID?._id,
      })
        .then((res) => {
          setNotes([
            {
              text: newNote,
              date: postTime,
              writer: `${user?.firstName} ${user?.lastName}`,
              listing: selectedItem?.listingID,
              _id: res?.data?._id,
            },
            ...notes,
          ]);
          return toast.success('Added successfully.');
        })
        .catch((err) => {
          return toast.error(err.toString());
        });
    } else {
      Patch(BaseURL(`notes/${selectedNote?._id}`), {
        text: newNote,
        date: postTime,
      })
        .then(() => {
          setSelectedNote(null);
          const updatedNotes = notes?.map((note) => {
            if (note?._id === selectedNote?._id) {
              note.text = newNote;
              note.date = postTime;
            }

            return note;
          });
          setNotes(updatedNotes);
          return toast.success('Note updated successfully.');
        })
        .catch((err) => {
          return toast.error(err.toString());
        });
    }
  };

  async function handleAddNotes(body) {
    setIsModalLoading('add-notes');
    const url = BaseURL('leads/notes');
    const response = await Patch(
      url,
      { leadId: selectedItem?._id, notes: body },
      apiHeader(accessToken),
    );
    if (response !== undefined && response?.data) {
      const index = listing?.findIndex(
        (item) => item?._id == selectedItem?._id,
      );
      const newData = [...listing];
      newData?.splice(index, 1, response?.data);
      setListing(newData);
      setSelectedItem(response?.data);
      toast.success('Notes added successfully');
    }
    setIsModalLoading('');
    setIsModalOpen('');
  }

  useEffect(() => {
    if (!inputListing) {
      setAllListing([]);
      return;
    }
    Get(
      BaseURL(`business/admin/all?search=${inputListing}`),
      accessToken,
      companyId,
    ).then((res) => {
      if (Array.isArray(res?.data?.business)) {
        console.log(res?.data?.business);
        setAllListing(res?.data?.business);
      } else {
        setAllListing([]);
      }
    });
  }, [listingDebouncedSearch]);

  useEffect(() => {
    getNotes();
  }, [selectedItem]);

  useEffect(() => {
    getAllInterests();
  }, [page]);

  useEffect(() => {
    if (page == 1) getAllInterests();
    setPage(1);
  }, [debouncedSearchTerm]);

  const handleDownloadXlsx = async () => {
    const filterStatus = status?.value;
    const br = broker?._id;
    const com = filterListing?._id;
    const lP = leadProgress?.value;

    setIsDownloading(true);

    const url = BaseURL(
      `leads/admin/xlsx?entity=${entityId}&status=${filterStatus}&lead-progress=${lP}&search=${search}&listing=${com}&broker=${br}`,
    );

    const response = await Get(
      url,
      accessToken,
      companyId,
      true,
      undefined,
      'blob',
    );

    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      const fileName = `Interest_${dayjs()?.format('DD-MM-YYYY_hh-mm-ss_A')}.xlsx`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    setIsDownloading(false);
  };

  useEffect(() => {
    getAdmins();
  }, []);

  // useEffect(() => {
  //     if (!openPopper) setSelectedItem(null);
  // }, [openPopper]);

  const handleClick = (flag) => {
    if (flag == 'View') {
      navigate(`/view-interest/${selectedItem?._id}`);
      setOpenPopper(false);
    }
  };

  const handleAddInterest = async (params) => {
    for (let key in params) {
      if (
        params[key] == '' ||
        params[key] == null ||
        params[key] == undefined
      ) {
        return toast.error(
          `Please provide the ${key
            .replace(formRegEx, formRegExReplacer)
            .toLowerCase()}`,
        );
      }
    }
    setLoading(true);
    const data = {
      business: params?.listing?._id,
      contact: params?.contact?._id,
    };
    const response = await Post(
      BaseURL(`leads/add-interest`),
      data,
      apiHeader(accessToken),
      companyId,
    );
    console.log('response', response);
    if (response !== undefined) {
      if (page == 1) {
        getAllInterests(1);
      } else {
        setPage(1);
      }
      toast.success(`Interest Created Successfully.`);
      setIsInterestModalOpen(false);
    }
    setLoading(false);
  };

  return (
    <>
      <style>
        {(isShowAll
          ? `
      .table100-body {
        max-height: 3000px!important;
        min-height: 200px;
      }  
      `
          : listingStatus
            ? `
      .table100-body {
        height:calc(100vh - 390px);
        min-height: 200px;
      }
      `
            : `
      .table100-body {
        height:calc(100vh - 330px);
        min-height: 200px;
      }
      `) +
          `
      .table100-body td {
        padding-block: 10px;
      }
      .column1{
        padding-left: 16px;
      }
      .table100 .table100-body tr {
        margin: 15px;
      }
      .table100 .table100-head tr {
        margin: 0 15px;
      }
      .table100-head, .table100-body {
        min-width: 1440px;
        width: 100%;
      }
      .table100 {
        overflow-x:scroll !important;
      }
      `}
      </style>
      {/* <div className={[classes.mainContainer]}> */}
      <div className={classes.interests_head}>
        <h4>Interests</h4>
        <div className={classes.inputDiv}>
          <SearchInput
            setter={setSearch}
            value={search}
            placeholder={'Search'}
          />
          <Badge
            badgeContent={
              status?.value != 'all' ||
              leadProgress?.value != 'all' ||
              broker?._id != 'null' ||
              (filterListing?._id && filterListing?._id != listingID)
                ? ' '
                : 0
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{ badge: muiClasses.badge }}
          >
            <Button
              label={''}
              onClick={() => {
                setShowFilterModal(true);
              }}
            >
              Filter Interests
            </Button>
          </Badge>
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize', marginRight: '10px' }}
            onClick={() => setIsInterestModalOpen(true)}
          >
            Add interest
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            onClick={() => handleDownloadXlsx()}
            disabled={isDownloading}
          >
            {isDownloading ? 'Downloading...' : 'Download'}
          </Button>
        </div>
      </div>
      <div className="table100 ver1 m-b-110">
        <div className="table100-head">
          <table>
            <thead>
              <tr className="row100 head">
                <th
                  className="cell100 column1"
                  style={{
                    width: '10%',
                    textAlign: 'left',
                  }}
                >
                  Name
                </th>
                <th
                  className="cell100 column4"
                  style={{
                    width: outsideLead ? '35%' : '25%',
                    textAlign: 'left',
                  }}
                >
                  Listing
                </th>
                {!outsideLead && (
                  <th
                    className="cell100 column3"
                    style={{ width: '15%', textAlign: 'center' }}
                  >
                    Email
                  </th>
                )}
                <th
                  className="cell100 column5"
                  style={{ width: '10%', textAlign: 'center' }}
                >
                  Phone
                </th>
                <th
                  className="cell100 column5"
                  style={{ width: '16%', textAlign: 'center' }}
                >
                  Lead Progress
                </th>
                <th className="cell100 column5" style={{ width: '14%' }}>
                  Status
                </th>
                <th
                  className="cell100 column5"
                  style={{ width: outsideLead ? '15%' : '10%' }}
                >
                  Action
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          className="table100-body js-pscroll ps ps--active-y"
          style={{ overflow: notesPopupAnchor ? 'hidden' : 'auto' }}
        >
          {loading ? (
            <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
          ) : (
            <table>
              <tbody>
                {listing?.length > 0 ? (
                  listing?.map((item, index) => (
                    <tr className="row100 body">
                      <td
                        className="cell100 column1"
                        style={{
                          width: '10%',
                          textAlign: 'left',
                        }}
                      >
                        {item?.outsideLead ? (
                          item?.contactName
                        ) : (
                          <Link
                            className={'ellipsis1Line emailLink'}
                            to={`/view-interest/${item?._id}`}
                            style={{
                              textTransform: 'capitalize',
                            }}
                          >
                            {`${item?.buyer?.firstName} ${item?.buyer?.lastName}`}
                          </Link>
                        )}
                      </td>
                      <td
                        className="cell100 column4"
                        style={{
                          width: outsideLead ? '35%' : '25%',
                          textAlign: 'left',
                        }}
                      >
                        <Link
                          className={'ellipsis1Line emailLink'}
                          to={`/listing-detail/${item?.listingID?.slug}?company-name=${encodeURIComponent(item?.listingID?.companyName)}&id=${item?.listingID?._id}&status=${item?.listingID?.status}`}
                          style={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {`${item?.listingID?.companyName}`}
                        </Link>
                      </td>
                      {!item?.outsideLead && (
                        <td
                          className="cell100 column4"
                          style={{ width: '15%', textAlign: 'center' }}
                        >
                          <Link
                            className={'ellipsis1Line emailLink'}
                            title={item?.buyer?.email}
                            to={`/contacts/${item?.buyer?._id}`}
                            state={{ email: item?.buyer?.email }}
                          >
                            {item?.buyer?.email}
                          </Link>
                        </td>
                      )}
                      <td
                        className="cell100 column4"
                        style={{ width: '10%', textAlign: 'center' }}
                      >
                        <a href={`tel:${item?.contactPhone}`}>
                          <span className={classes.phoneNumber}>
                            {!outsideLead
                              ? ReturnFormatedNumber(item?.contactPhone)
                              : item?.contactPhone}
                          </span>
                        </a>
                      </td>
                      <td
                        className="cell100 column4"
                        style={{ width: '16%', textAlign: 'center' }}
                      >
                        {/* {item?.contactPhone} */}
                        <DropDown
                          customStyle={{
                            color: '#fff',
                            borderRadius: '0px',
                            padding: '0px 5px 0px 1px',
                            fontSize: '14px',
                            whiteSpace: 'pre-wrap',
                          }}
                          isValueWrap={true}
                          options={leadProgressOptions}
                          placeholder={'Select'}
                          value={leadStatus[index]?.status}
                          setter={(e) => {
                            const newLeadStatus = [...leadStatus];
                            newLeadStatus[index].status = e;
                            setLeadStatus(newLeadStatus);
                            updateLeadProgress(newLeadStatus[index]);
                          }}
                          optionLabel={'label'}
                          optionValue={'value'}
                          customeClassName={`LeadProgressDropdown${index}`}
                          backgroundColor={getLeadProgressColor(
                            leadStatus[index]?.status?.value,
                          )}
                          singleValueColor={getLeadProgressColor(
                            leadStatus[index]?.status?.value,
                            false,
                          )}
                        />
                      </td>
                      <td
                        className="cell100 column4"
                        style={{ width: '14%', textAlign: 'center' }}
                      >
                        <span
                          className={[
                            item?.status == 'inquired'
                              ? classes.inquired
                              : item?.status == 'nda-signed'
                                ? classes.ndaSignedColor
                                : item?.status == 'nda-submitted'
                                  ? classes.ndaSubmit
                                  : item?.status == 'nda-approved'
                                    ? classes.ndaApproved
                                    : item?.status == 'VIP'
                                      ? classes.VIP
                                      : classes.closed,
                            classes.colorDiv,
                          ].join(' ')}
                        >
                          {item?.status}
                          {'\n('}
                          {moment(
                            item?.statusUpdatedAt || item?.createdAt,
                          ).format('MM/DD/YY h:mm A')}
                          {')'}
                        </span>
                      </td>
                      <td
                        className="cell100 column5"
                        style={{
                          width: item?.outsideLead ? '15%' : '10%',
                          textAlign: '',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div className={classes?.btnContainer}>
                            <MdChat
                              size={20}
                              color={
                                selectedItem?._id == item?._id
                                  ? 'yellow'
                                  : 'white'
                              }
                              onClick={(event) => {
                                notesPopupAnchor && setNotes([]);
                                setSelectedItem(notesPopupAnchor ? null : item);
                                setNotesPopupAnchor(
                                  notesPopupAnchor ? null : event.currentTarget,
                                );
                              }}
                            />
                          </div>
                          {item?.outsideLead ? (
                            item?.contactName
                          ) : (
                            <div
                              className={`${classes?.btnContainer} ${isAdmin || item?.broker?.map((e) => e?._id)?.includes(user?._id) ? classes.active : classes.disable}`}
                              onClick={() => {
                                if (
                                  !isAdmin &&
                                  !item?.broker
                                    ?.map((e) => e?._id)
                                    ?.includes(user?._id)
                                )
                                  return;
                                let query =
                                  item?.listingID?.buyerAssignedToDataRoom?.includes(
                                    item?.buyer?._id,
                                  );
                                setSelectedInterest(item);
                                setSelectedListingID(item?.listingID?._id);
                                setIsAssignDataRoom(query);
                                setAccessRoomModal(true);
                              }}
                            >
                              {item?.listingID?.buyerAssignedToDataRoom?.includes(
                                item?.buyer?._id,
                              ) ? (
                                <MdFolderOff
                                  size={20}
                                  style={{ color: 'white' }}
                                />
                              ) : (
                                <MdFolderOpen
                                  size={20}
                                  style={{ color: 'white' }}
                                />
                              )}
                            </div>
                          )}

                          {!isAdmin && (
                            <div className={classes.btnContainer}>
                              <BsChatDots
                                color={'var(--white-color)'}
                                size={25}
                                onClick={() => {
                                  navigate(`/messages`, {
                                    state: item?.room,
                                  });
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoData text={'No Interests Found'} />
                )}
              </tbody>
            </table>
          )}
          <NotesDetailPopup
            anchor={notesPopupAnchor}
            notes={notes}
            userId={selectedItem?.buyer?._id}
            onAdd={(message) => {
              onSubmitNote(message);
            }}
            onClose={() => {
              setNotes([]);
              setSelectedItem(null);
              setNotesPopupAnchor(null);
            }}
            onEdit={(note) => {
              setSelectedNote(note);
            }}
            selectedNote={selectedNote}
          />
        </div>
      </div>
      {listing?.length > 0 && (
        <div className={[classes.paginationDiv]}>
          <PaginationComponent
            totalPages={Math.ceil(totalPages / recordsLimit)}
            currentPage={page}
            setCurrentPage={setPage}
          />
        </div>
      )}
      {/* </div> */}

      <PoperComponent
        handleClick={handleClick}
        open={openPopper}
        setOpen={setOpenPopper}
        anchorRef={anchorRef}
        data={['View']}
        isCloseOnClick={false}
      />
      {isModalOpen == 'add-notes' && (
        <AddOrEditNotesModal
          setShow={() => setIsModalOpen('')}
          show={isModalOpen == 'add-notes'}
          selectedUser={selectedItem}
          loading={isModalLoading == 'add-notes'}
          onClick={handleAddNotes}
        />
      )}
      <AddInterestModal
        show={isInterestModalOpen}
        setShow={setIsInterestModalOpen}
        selectedListing={{ _id: listingID, companyName: companyName }}
        listings={allListing}
        inputListing={inputListing}
        setInputListing={setInputListing}
        contacts={contacts}
        getContacts={getAllBuyerContacts}
        handleSubmit={handleAddInterest}
        isLoading={loading}
      />
      {showFilterModal && (
        <InterestsFilterModal
          isAdmin={isAdmin}
          show={showFilterModal}
          setShow={setShowFilterModal}
          status={status}
          leadProgress={leadProgress}
          broker={broker}
          allBrokers={allBrokers}
          allListing={allListing}
          listing={filterListing}
          inheritListing={{ _id: listingID, companyName }}
          inputListing={inputListing}
          setInputListing={setInputListing}
          setFilter={(status, leadProgress, broker, listing) => {
            setShowFilterModal(false);

            setStatus(status);
            setLeadProgress(leadProgress);
            setBroker(broker);
            setFilterListing(listing);

            if (page == 1)
              getAllInterests(
                1,
                status?.value,
                leadProgress?.value,
                broker?._id,
                listing?._id ? listing?._id : 'null',
              );
            else setPage(1);
          }}
        />
      )}
      {accessRoomModal && (
        <AreYouSureModal
          show={accessRoomModal}
          setShow={setAccessRoomModal}
          subTitle={`${isAssignDataRoom ? 'Unassign Room' : 'Assign Room'}`}
          isApiCall={accessRoomLoader}
          onClick={() => accessDataRoom(selectedListingID, isAssignDataRoom)}
        />
      )}
    </>
  );
};

export default Interests;
