import React from 'react';

import FolderAndFileBox from '../../Component/FolderAndFileBox';
import classes from './Documents.module.css';

const Documents = ({ onSelect }) => {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.headerContainer}>
        <h4>Documents</h4>
      </div>
      <div className={classes.mainFoldersDiv}>
        <div className={classes.folderBox}>
          <div className={classes.folderInnerBox}>
            <FolderAndFileBox
              data={{ name: 'Main', isFile: false }}
              onClick={() => {
                onSelect();
              }}
              allowDelete={false}
              allowEdit={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
