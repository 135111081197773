import React, { useEffect } from 'react';
import classes from './BVIModal.module.css';
import ModalSkeleton from '../ModalSkeleton';
import { Input } from '../../Component/Input/Input';
import { useState } from 'react';

import { toast } from 'react-toastify';
import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Highlighter from 'react-highlight-words';

const FORM_LABLE = {
  legal_entity: 'Legal entity of business:',
  is_franchise: 'Is the business a franchise:',
  is_relocatable: 'Is the business relocatable:',
  business_phone_number: 'Business phone number:',
  business_website: 'Business website:',
  reason_for_sale: 'Reason for sale:',
  business_opportunity: 'Business opportunity:',
  business_strength: 'Business strengths:',
  business_weaknesses: 'Business weaknesses:',
  maker_business_unique: 'What makes your business unique?',
  hours_of_operation: 'Hours of operation',
  business_history: 'Business history:',
  purchase_date: 'Date of purchase by current owner',
  business_years: 'Total number of years in business:',
  business_management: 'Management of business:',
  week_commitment: 'Seller weekly time commitment:',
  responsibility: 'Seller responsibilities and functions:',
  replacement: 'Replacement of Seller:',
  train_week:
    'Length of time to train Buyer with reasonable skills but no direct knowledge:',
  after_train_week: 'Transition training period:',
  intAsset: 'Intangible or Intellectual assets included in sale:',
  certification_for_usiness:
    'Special skills, certifications, and/or licenses required to operate business:',
  manager_number: 'Manager',
  manager_information: 'Manager information',
  full_time_employee_number: 'Full time employees',
  full_time_employee_information: 'Full time employees information',
  part_time_employee_number: 'Part time employees',
  partTimeEmployeeInfo: 'Part time employees information',
  hire_timeline: 'Recruitment and hiring timeline:',
  turnover_trend: 'Employee turnover trends:',
  recent_improvements:
    'Highlights/recent improvements made to business/premises:',
  FFAndE: 'Furniture, fixtures, and equipment (FF&E):',
  prodtion_service: 'Products and services:',
  revenue_percent: 'Percentage of revenue:',
  production_service_distribute: 'Distribution of products and services:',
  supply_inventory: 'Supply chain and inventory processes:',
  demo_customer: 'Target demographics and customer overview:',
  strategy: 'Current marketing and advertising strategies:',
  opportunity: 'Business growth opportunities:',
  competitors: 'Direct competitors:',
  industry: 'Current industry overview:',
  building_type: 'Building type:',
  square: 'Square Footage',
  locate_years: 'Years at current location:',
  suitability: 'Suitability of location:',
  property_information: 'Property information',
  rent: 'Rent: Base + CAM/Triple Net/All Inclusive',
  lease_term: 'Lease term/Options:',
  relation_with_landlord_lease_flexibility:
    'Relationship with landlord & lease flexibility:',
  sell_property: 'If CRE is owned, do you wish to sell the property?',
  special_zone_requirement: 'Special zoning requirements:',
  business_price: 'Business price:',
  CRE_price: 'CRE Price:',
  inventory_value: 'Inventory Value',
  motivation_level:
    'What is your motivation to sell your business? (1 not motivated, 5 extremely motivated)',
  is_interest_in_finance: 'Are you able to/interested in seller financing?',
};

const BVIModal = ({ show, setShow, noteBox }) => {
  const [search, setSearch] = useState('');
  const [noteField, setNoteField] = useState([]);

  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);

      toast.success('Copied Successfully');
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  useEffect(() => {
    if (show && noteBox) {
      const noteBoxJson = JSON.parse(noteBox?.bvi || '{}');

      const inputFields = [];

      Object.keys(noteBoxJson).forEach((key) => {
        let value = '';

        if (noteBoxJson[key]?.value?.toString()) {
          if (
            noteBoxJson[key]?.value !== 'null' &&
            noteBoxJson[key]?.value !== 'none'
          ) {
            value = noteBoxJson[key]?.label;
          }
        } else {
          value = noteBoxJson[key];
        }

        if (value) {
          inputFields.push({
            name: FORM_LABLE[key?.replaceAll('-', '_')],
            value,
          });
        }
      });

      setNoteField(inputFields);
    }
  }, [show, noteBox]);

  return (
    <>
      <style>{`
      .MuiFormControl-root{
        width: 100%;
      }
      .MuiFormLabel-root {
        color: var(--placeholder-color) !important;
      }
      .MuiOutlinedInput-notchedOutline {
        box-shadow: 0px 0 5px 2px #0000000d;
        border-width: 0;
        border: none;
        border-radius: 10px;
        box-shadow: 0px 0 5px 2px #0000000d;
      }
      `}</style>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        borderRadius="20px"
        width="850px"
        borderLine={false}
        header={'BVI'}
        showCloseIcon={true}
      >
        {noteField && noteField?.length > 0 && (
          <>
            <div className={classes?.searchBar}>
              <h4>
                seller: {noteBox?.seller?.firstName} {noteBox?.seller?.lastName}
              </h4>
              <div className={classes.inputContainer}><Input placeholder={'Search'} value={search} setter={setSearch} /></div>
            </div>

            <div className={classes.add_task_main}>
              {noteField?.map((data) => (
                <div className={classes.input_main}>
                  <p className={classes?.labelText}>{data?.name}</p>

                  <div className={classes?.inputPassContainer}>
                    <p className={classes?.inputBox}>
                      {data?.value?.split('\n')?.map((item, index) => (
                        <>
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={[search?.trim()]}
                            autoEscape={true}
                            textToHighlight={item}
                          />

                          <br />
                        </>
                      ))}
                    </p>

                    <IconButton
                      IconButton
                      onClick={() => copyText(data?.value)}
                      size="small"
                    >
                      <ContentCopy fontSize="small" />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {noteField && !noteField?.length && (
          <p
            style={{
              textAlign: 'center',
              padding: '12px 4px',
              fontWeight: 500,
            }}
          >
            No Data Found
          </p>
        )}
      </ModalSkeleton>
    </>
  );
};

export default BVIModal;
