import React, { useState, useEffect } from 'react';

import ModalSkeleton from '../ModalSkeleton';
import useDebounce from '../../CustomHooks/useDebounce';
import { Button } from '../../Component/Button/Button';
import { DropDown } from '../../Component/DropDown/DropDown';
import classes from './AddInterestModal.module.css';

const AddInterestModal = ({
  show,
  setShow,
  selectedListing = null,
  listings = [],
  inputListing,
  setInputListing,
  contacts = [],
  getContacts = [],
  isLoading = false,
  handleSubmit,
}) => {
  const [search, setSearch] = useState('');
  const [contact, setContact] = useState('');
  const [listing, setListing] = useState(selectedListing);

  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    getContacts(search);
  }, [debouncedSearch]);

  const HandleSubmitData = () => {
    handleSubmit({
      contact: contact,
      listing: listing,
      status: 'inquired',
    });
  };
  return (
    <>
      <style>
        {`
          .MuiFormControl-root{
            width: 100%;
          }
          .MuiFormLabel-root {
            color: var(--placeholder-color) !important;
          }

          .MuiOutlinedInput-notchedOutline {
            box-shadow: 0px 0 5px 2px #0000000d;
            border-width: 0;
            border: none;
            border-radius: 10px;
            box-shadow: 0px 0 5px 2px #0000000d;
          }
        `}
      </style>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        borderRadius="20px"
        width="734px"
        borderLine={false}
        header="Add Interest"
        showCloseIcon={true}
      >
        <div className={classes.add_interest_main}>
          <DropDown
            setter={setContact}
            inputSetter={setSearch}
            value={contact}
            label={'Select User'}
            placeholder={'Input name or email address.'}
            options={contacts}
            getOptionLabel={(e) =>
              `${e?.firstName} ${e?.lastName} (${e?.email})`
            }
            optionValue={'_id'}
          />

          <div className={classes.input_main}>
            <DropDown
              setter={setListing}
              value={listing}
              inputSetter={setInputListing}
              inputValue={inputListing}
              placeholder={'Select Company'}
              label={'Select Company'}
              options={listings}
              optionLabel={'companyName'}
              optionValue={'_id'}
              disabled={selectedListing?._id}
            />
          </div>

          <div className={classes.add_interest_btn_main}>
            <Button
              onClick={() => HandleSubmitData()}
              className={classes.add_interest_btn}
              label="Save"
              disabled={isLoading}
            />
            <Button
              onClick={() => setShow(false)}
              className={classes.add_interest_btn}
              label="Close"
              disabled={isLoading}
            />
          </div>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default AddInterestModal;
