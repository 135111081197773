import { moment } from './timeUtils';
import { matchOfBVI } from '../constant/commonData';

export const formatNumber = (num) => {
  if (typeof num === 'number') {
    // Round the number to two decimal places
    num = Math.round(num * 100) / 100;

    // Convert the number to a string
    const numStr = num.toString();

    // Split the number string into integer and decimal parts
    const [integerPart, decimalPart] = numStr.split('.');

    // Add commas to the integer part
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // If there's a decimal part, format it and return the complete string
    if (decimalPart !== undefined) {
      return `${formattedInteger}.${decimalPart.padEnd(2, '0')}`;
    }

    // Otherwise, just return the formatted integer part
    return formattedInteger;
  }
  return num;
};

export const getRealPhoneNumber = (phoneNumberString) => {
  return ('' + phoneNumberString).replace(/\D/g, '');
};

export const formatPhoneNumber = (phoneNumberString) => {
  // Remove non-numeric characters
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  // Check if the number is a valid US phone number
  const match = cleaned.match(/^.?.?.?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null; // Return null if the number is not valid
};

export const formatPhoneNumberWithoutPrefix = (phoneNumberString) => {
  // Remove non-numeric characters
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  // Define match patterns for various phone number formats
  const formats = [
    { pattern: /^(\d{3})(\d{3})(\d{4})/, format: '($1) $2-$3' }, // (XXX) XXX-XXXX
    { pattern: /^(\d{3})(\d{3})(\d{1,4})/, format: '($1) $2-$3' }, // (XXX) XXX-XXX or (XXX) XXX-X
    { pattern: /^(\d{3})(\d{1,3})/, format: '($1) $2' }, // (XXX) XXX or (XXX) XX
    { pattern: /^(\d{1,3})/, format: '($1' }, // (XXX or (XX or (X
  ];

  // Iterate over formats and return the first match
  for (const { pattern, format } of formats) {
    const match = cleaned.match(pattern);
    if (match) {
      return format.replace(/\$(\d)/g, (_, idx) => match[idx]); // Replace placeholders with matched values
    }
  }

  return null; // Return null if the number is not valid
};

export const formatDate = (date, isForQuery = false) => {
  if (!(date instanceof Date)) return String(date);

  if (isForQuery) return moment(date).format('YYYY-MM-DD hh:mm:ss');
  return moment(date).format('MM/DD/YYYY hh:mm:ss');
};

export const commaStyle = (price) => {
  let tmp = '';
  let value = Number(price);
  let counter = 10;
  if (value < 1000) return value;
  while (value && counter) {
    counter--;
    let ext = value % 1000;
    value = Math.floor(value / 1000);
    if (value >= 1) {
      tmp = String(ext).padStart(3, '0') + tmp;
      tmp = ',' + tmp;
    } else tmp = String(ext) + tmp;
  }
  return tmp;
};

export const commaStyle1 = (price) => {
  // Ensure price is a number first
  const value = Number(price);
  if (isNaN(value) || !price || !isFinite(value)) {
    return 0;
  }

  // Format to 2 decimal places if it's a decimal number
  const formattedPrice = value.toFixed(2);

  // Split the integer and decimal parts
  const [integerPart, decimalPart] = formattedPrice.split('.');
  let tmp = '';
  let intVal = Number(integerPart);
  let counter = 10;

  if (intVal < 1000)
    return (
      integerPart +
      (decimalPart && decimalPart != '00' ? '.' + decimalPart : '')
    );

  while (intVal && counter) {
    counter--;
    let ext = intVal % 1000;
    intVal = Math.floor(intVal / 1000);
    if (intVal > 0) {
      tmp = String(ext).padStart(3, '0') + tmp;
      tmp = ',' + tmp;
    } else {
      tmp = String(ext) + tmp;
    }
  }

  return tmp + (decimalPart && decimalPart != '00' ? '.' + decimalPart : '');
};

export const commaWithUnit = (num) => {
  if (num === null || num === undefined) {
    return '0'; // Handle null or undefined input
  }

  num = Number(num); // Convert input to a number

  // Define thresholds for K (Thousands), M (Millions), B (Billions), T (Trillions)
  const thresholds = {
    T: 1e12, // Trillions
    B: 1e9, // Billions
    M: 1e6, // Millions
    K: 1e3, // Thousands
  };

  let formattedValue;

  if (Math.abs(num) >= thresholds.T) {
    formattedValue = (num / thresholds.T).toFixed(1) + 'T'; // Trillions
  } else if (Math.abs(num) >= thresholds.B) {
    formattedValue = (num / thresholds.B).toFixed(1) + 'B'; // Billions
  } else if (Math.abs(num) >= thresholds.M) {
    formattedValue = (num / thresholds.M).toFixed(1) + 'M'; // Millions
  } else if (Math.abs(num) >= thresholds.K) {
    formattedValue = (num / thresholds.K).toFixed(1) + 'K'; // Thousands
  } else {
    formattedValue = num.toString(); // Return the original number if less than 1K
  }

  return formattedValue;
};

export const getFirstLetters = (name) => {
  name = name.toUpperCase();
  const splitName = name.split(' ');

  if (splitName.length > 1) {
    return `${splitName[0][0]}${splitName[1][0]}`;
  } else {
    return `${splitName[0][0]}${splitName[0][1]}`;
  }
};

export const stringToColor = (str) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
};

export const stringToHSL = (str) => {
  // Calculate a hash based on the string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash += str.charCodeAt(i);
  }

  // Derive hue from the hash, ensuring it wraps around 360
  const h = hash % 360;

  // Derive saturation and lightness
  const s = hash % 100; // Random saturation from 0 to 100%
  const l = 50; // Set lightness to 50% for vibrant colors

  // Return the HSL color string
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export const numberInputValidator = (
  oldVal,
  newVal,
  min = 0,
  max = Infinity,
) => {
  if (min >= max) return oldVal * 1;
  if (newVal < min || newVal > max) return oldVal * 1;
  return newVal * 1;
};

export const getDateRangeBasedOnLabel = (label) => {
  const today = new Date();

  switch (label.toLowerCase()) {
    case 'due today':
      const startOfDay = new Date(
        Date.UTC(
          today.getUTCFullYear(),
          today.getUTCMonth(),
          today.getUTCDate(),
        ),
      );
      startOfDay.setUTCHours(0, 0, 0, 0);

      const endOfDay = new Date(startOfDay);
      endOfDay.setUTCHours(23, 59, 59, 999);

      return {
        start: startOfDay.toISOString(),
        end: endOfDay.toISOString(),
      };

    case 'due this week':
      const startOfWeek = new Date(
        Date.UTC(
          today.getUTCFullYear(),
          today.getUTCMonth(),
          today.getUTCDate(),
        ),
      );
      const dayOfWeek = startOfWeek.getUTCDay();
      const diffToSunday = dayOfWeek === 0 ? 0 : -dayOfWeek;
      startOfWeek.setUTCDate(startOfWeek.getUTCDate() + diffToSunday);
      startOfWeek.setUTCHours(0, 0, 0, 0);

      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6);
      endOfWeek.setUTCHours(23, 59, 59, 999);

      return {
        start: startOfWeek.toISOString(),
        end: endOfWeek.toISOString(),
      };

    case 'due this month':
      const startOfMonth = new Date(
        Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1),
      );
      startOfMonth.setUTCHours(0, 0, 0, 0);

      const endOfMonth = new Date(
        Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0),
      );
      endOfMonth.setUTCHours(23, 59, 59, 999);

      return {
        start: startOfMonth.toISOString(),
        end: endOfMonth.toISOString(),
      };

    default:
      throw new Error('Invalid label');
  }
};

export const getDateRangeBasedSeletions = (search) => {
  let date = null;

  if (search?.dueThisMonth) {
    date = getDateRangeBasedOnLabel(search?.dueThisMonth);
  } else if (search?.dueThisWeek && !search?.dueThisMonth) {
    date = getDateRangeBasedOnLabel(search?.dueThisWeek);
  } else if (
    search?.dueToday &&
    !search?.dueThisWeek &&
    !search?.dueThisMonth
  ) {
    date = getDateRangeBasedOnLabel(search?.dueToday);
  }

  return date;
};

export const uuidGenerator = () => {
  const randomNum = Math.floor(Math.random() * 1000)
    .toString()
    .padStart(3, '0');

  const formattedDateTime = new Date().toISOString().replace(/[^0-9]/g, '');

  return `${formattedDateTime}-${randomNum}`;
};

export const parseNoteBox = (bvi) => {
  const noteBoxJson = JSON.parse(bvi || '{}');

  const inputFields = [];

  Object.keys(noteBoxJson).forEach((key) => {
    let value = '';

    if (noteBoxJson[key]?.value?.toString()) {
      if (
        noteBoxJson[key]?.value !== 'null' &&
        noteBoxJson[key]?.value !== 'none'
      ) {
        value = noteBoxJson[key]?.label;
      }
    } else {
      value = noteBoxJson[key];
    }

    if (value) {
      inputFields.push({
        name: matchOfBVI?.[key] || key?.replaceAll('-', ' '),
        value,
      });
    }
  });

  return inputFields;
};

export const changeStringList = (data) => {
  return data?.map((e) => (e ? `-${e}` : ''))?.join('\n');
};

export const changeStringToList = (data) => {
  return data?.split('\n').map((e) => e?.replace(/^-/, ''));
};
