import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BaseURL } from '../../config/apiUrl';
import { Get } from '../../Axios/AxiosFunctions';
import NotFound from '../NotFound';
import Loader from '../../Component/Loader';
import AddContractAgreement from '../AddContractAgreement';

const EditContractAgreement = () => {
  const templateId = useParams()?.slug;

  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const baseApiUrl = BaseURL(`contract-agreement`);
  
  const [isFound, setIsFound] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [templateData, setTemplateData] = useState(null);

  const getTemplateById = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = `${baseApiUrl}/${templateId}`;

      const response = await Get(url, accessToken);

      if (response?.status === 200) {
        setTemplateData({
          ...response?.data,
          seller: {
            ...response?.data?.seller,
            name: `${response?.data?.seller?.firstName || ''} ${response?.data?.seller?.lastName || ''}`,
          },
          buyer: {
            ...response?.data?.buyer,
            name: `${response?.data?.buyer?.firstName || ''} ${response?.data?.buyer?.lastName || ''}`,
          },
          broker: {
            ...response?.data?.broker,
            name: `${response?.data?.broker?.firstName || ''} ${response?.data?.broker?.lastName || ''}`,
          },
          cooperating_broker_name: {
            ...response?.data?.cooperating_broker_name,
            name: `${response?.data?.cooperating_broker_name?.firstName || ''} ${response?.data?.cooperating_broker_name?.lastName || ''}`,
          },
          escrow_agent_name: {
            ...response?.data?.escrow_agent_name,
            name: `${response?.data?.escrow_agent_name?.firstName || ''} ${response?.data?.escrow_agent_name?.lastName || ''}`,
          },
          listing_broker_name: {
            ...response?.data?.listing_broker_name,
            name: `${response?.data?.listing_broker_name?.firstName || ''} ${response?.data?.listing_broker_name?.lastName || ''}`,
          },
          seller_carry_years: response?.data?.seller_carry_years || 0,
          seller_carry_rate: response?.data?.seller_carry_rate || 0,
          acceptance_deadline: response?.data?.acceptance_deadline || 0,
          inventory_margin: response?.data?.inventory_margin || 0,
        });
      } else {
        setIsFound(false);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (templateId) {
      getTemplateById();
    }
  }, [templateId]);

  return (
    <div>
      {isLoading && <Loader />}

      {isFound && !isLoading && <AddContractAgreement editData={templateData} />}
      {!isFound && !isLoading && <NotFound />}
    </div>
  );
};

export default EditContractAgreement;
