import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FolderAndFileBox from '../../../Component/FolderAndFileBox';
import Loader from '../../../Component/Loader';
import SearchInput from '../../../Component/SearchInput';
import NoData from '../../../Component/NoData/NoData';
import { Button } from '../../../Component/Button/Button';
import classes from './ListingStatus.module.css';

const LISTING_STATUS = [
  {
    id: 1,
    name: 'Under Contract',
    status: 'under-contract',
    slug: '/documents/listing-status/under-contract',
    isFile: false,
  },
  {
    id: 2,
    name: 'Published',
    status: 'published',
    slug: '/documents/listing-status/published',
    isFile: false,
  },
  {
    id: 3,
    name: 'Seller Review',
    status: 'seller-review',
    slug: '/documents/listing-status/seller-review',
    isFile: false,
  },
  {
    id: 4,
    name: 'Broker Review',
    status: 'broker-review',
    slug: '/documents/listing-status/broker-review',
    isFile: false,
  },
  {
    id: 5,
    name: 'Prospects',
    status: 'prospect',
    slug: '/documents/listing-status/prospect',
    isFile: false,
  },
  {
    id: 6,
    name: 'Pocket',
    status: 'pocket',
    slug: '/documents/listing-status/pocket',
    isFile: false,
  },
  {
    id: 7,
    name: 'Lost',
    status: 'lost',
    slug: '/documents/listing-status/lost',
    isFile: false,
  },
  {
    id: 8,
    name: 'Sold',
    status: 'sold',
    slug: '/documents/listing-status/sold',
    isFile: false,
  },
  {
    id: 9,
    name: 'Off Market',
    status: 'off-market',
    slug: '/documents/listing-status/off-market',
    isFile: false,
  },
  {
    id: 10,
    name: 'All Listings',
    status: 'all',
    slug: '/documents/listing-status/all',
    isFile: false,
  },
  {
    id: 11,
    name: 'Administrative',
    status: 'administrative',
    slug: '/documents/administrative',
    isFile: false,
  },
];

const ListingStatus = ({ onSelect, onBack }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [folders, setFolders] = useState(LISTING_STATUS);

  const handleSearch = (searchValue) => {
    setSearch(searchValue);

    const value = searchValue?.trim()?.toLowerCase();

    const data = LISTING_STATUS?.filter(({ name }) =>
      name?.toLowerCase()?.includes(value),
    );

    setFolders(data);
  };

  return (
    <div className={classes.mainContainer}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.headerContainer}>
            <h4>Documents</h4>
            <div className={classes.btnContainer}>
              <Button label={'Back'} onClick={onBack} />
              <SearchInput
                setter={(e) => handleSearch(e)}
                value={search}
                placeholder={'Search'}
              />
            </div>
          </div>
          <div className={classes.mainFoldersDiv}>
            {folders && !folders?.length && <NoData text="No Folders Found" />}

            {folders && folders?.length > 0 && (
              <div className={classes.folderBox}>
                {folders?.map((item) => (
                  <div className={classes.folderInnerBox}>
                    <FolderAndFileBox
                      key={item?.id}
                      data={item}
                      onClick={() => {
                        if (item?.status === 'administrative')
                          navigate(item?.slug);
                        else onSelect(item?.status);
                      }}
                      allowDelete={false}
                      allowEdit={false}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ListingStatus;
