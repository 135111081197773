import React, { useEffect, useRef, useState } from 'react';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { apiHeader, BaseURL, imageUrl } from '../../config/apiUrl';
import classes from './ContractSent.module.css';
import TableSkeleton from '../../Component/TableSkeleton';
import NoData from '../../Component/NoData/NoData';
import { useSelector } from 'react-redux';
import { Get, Delete } from '../../Axios/AxiosFunctions';
import { useNavigate } from 'react-router-dom';
import PoperComponent from '../../Component/PopperComponent';
import AreYouSureModal from '../../modals/AreYouSureModal';
import { toast } from 'react-toastify';
import { LuEye } from 'react-icons/lu';
import SearchInput from '../../Component/SearchInput';
import useDebounce from '../../CustomHooks/useDebounce';
import ContractHeader from '../../Component/ContractHeader';
import ModalSkeleton from '../../modals/ModalSkeleton';
import { commaStyle } from '../../utils/functions';
import dayjs from '../../utils/timeUtils';

const recordsLimit = 40;

const ContractSent = () => {
  const navigate = useNavigate();

  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const { logo } = useSelector(
    (state) => state?.companyReducer,
  );
  const baseApiUrl = BaseURL(`contract-agreement`);

  const [isFetchingTemplates, setIsFetchingTemplates] = useState(true);

  const [page] = useState(1);
  const [templateData, setTemplateData] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);

  const anchorRef = useRef(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState('');
  const [search, setSearch] = useState('');
  const [isOpenEmailPreview, setIsOpenEmailPreview] = useState(false);
  const [companyLogoBase64, setCompanyLogoBase64] = useState(null);

  const debouncedSearchTerm = useDebounce(search, 500);

  const getAllContractSent = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = `${baseApiUrl}?status=all&search=${search}&page=${page}&limit=${recordsLimit}`;

      const response = await Get(url, accessToken);

      if (response?.status === 200) {
        setTemplateData(response?.data);
      }

      setIsFetchingTemplates(false);
    }
  };

  const formateDate = (date) => {
    return `${dayjs(new Date(date))?.format('MM-DD-YYYY')}`;
  };
  
  const getAgreementContent = (content) => {
    if (content) {
      let replacedContent = content || '';

      replacedContent = replacedContent?.replaceAll(
        '*|company_logo|*',
        `   
          <img style="
            width: 200px;
            height: 100%;
            object-fit: contain;
            object-position: center;
          " src="${companyLogoBase64 || ''}" alt="logo" />`,
      );

      replacedContent = replacedContent?.replaceAll(
        '*|company_name|*',
        selectedItem?.company_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|company_address|*',
        selectedItem?.company_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_name|*',
        selectedItem?.entity?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_city|*',
        selectedItem?.entity_city || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_country|*',
        selectedItem?.entity_country || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_state|*',
        selectedItem?.entity_state || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_street|*',
        selectedItem?.entity_street || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_zip|*',
        selectedItem?.entity_zip || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|entity_phone|*',
        selectedItem?.entity_phone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_brokerage_name|*',
        selectedItem?.listing_brokerage_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_brokerage_address|*',
        selectedItem?.listing_brokerage_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Seller_Name|*',
        selectedItem?.seller?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_email|*',
        selectedItem?.seller_email || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_address|*',
        selectedItem?.seller_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_phone|*',
        selectedItem?.seller_phone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_title|*',
        selectedItem?.seller_title || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_signature_date|*',
        selectedItem?.seller_signature_date
          ? formateDate(selectedItem?.seller_signature_date)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Buyer_Name|*',
        selectedItem?.buyer?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_email|*',
        selectedItem?.buyer_email || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_address|*',
        selectedItem?.buyer_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_phone|*',
        selectedItem?.buyer_phone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_title|*',
        selectedItem?.buyer_title || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_signature_date|*',
        selectedItem?.buyer_signature_date
          ? formateDate(selectedItem?.buyer_signature_date)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|broker_name|*',
        selectedItem?.broker?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|broker_firm_name|*',
        selectedItem?.broker_firm_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|broker_provided_info|*',
        selectedItem?.broker_provided_info || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Business_Name|*',
        selectedItem?.business_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Business_Address|*',
        selectedItem?.business_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_brokerage_name|*',
        selectedItem?.cooperating_brokerage_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_brokerage_address|*',
        selectedItem?.cooperating_brokerage_address || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|commission_percentage|*',
        selectedItem?.commission_percentage &&
          Number(selectedItem?.commission_percentage) >= 0
          ? `${Number(selectedItem?.commission_percentage)}%`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|commission_payment_terms|*',
        selectedItem?.commission_payment_terms || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_broker_name|*',
        selectedItem?.cooperating_broker_name?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_broker_title|*',
        selectedItem?.cooperating_broker_title || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_broker_phone|*',
        selectedItem?.cooperating_broker_phone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|cooperating_broker_email|*',
        selectedItem?.cooperating_broker_email || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Agreement_Title|*',
        selectedItem?.agreement_title || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Agreement_Date|*',
        selectedItem?.agreement_date ? formateDate(selectedItem?.agreement_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|closing_date|*',
        selectedItem?.closing_date ? formateDate(selectedItem?.closing_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|document_date|*',
        selectedItem?.document_date ? formateDate(selectedItem?.document_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Termination_Reason|*',
        selectedItem?.termination_reason || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Clause_Reference|*',
        selectedItem?.clause_reference || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Due_Diligence_Deadline|*',
        selectedItem?.due_diligence_deadline || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|Document_List|*',
        selectedItem?.document_list || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|earnest_money_amount|*',
        selectedItem?.earnest_money_amount &&
          Number(selectedItem?.earnest_money_amount) >= 0
          ? `$${commaStyle(selectedItem?.earnest_money_amount)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|escrow_agent_name|*',
        selectedItem?.escrow_agent_name?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|escrow_company_name|*',
        selectedItem?.escrow_company_name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|purchase_price|*',
        selectedItem?.purchase_price && Number(selectedItem?.purchase_price) >= 0
          ? `$${commaStyle(selectedItem?.purchase_price)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|down_payment|*',
        selectedItem?.down_payment && Number(selectedItem?.down_payment) >= 0
          ? `$${commaStyle(selectedItem?.down_payment)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|loan_at_closing|*',
        selectedItem?.loan_at_closing && Number(selectedItem?.loan_at_closing) >= 0
          ? `$${commaStyle(selectedItem?.loan_at_closing)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|note_at_closing|*',
        selectedItem?.note_at_closing && Number(selectedItem?.note_at_closing)
          ? `$${commaStyle(selectedItem?.note_at_closing)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|start_date|*',
        selectedItem?.start_date ? formateDate(selectedItem?.start_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|end_date|*',
        selectedItem?.end_date ? formateDate(selectedItem?.end_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|sale_commission_amount|*',
        selectedItem?.sale_commission_amount &&
          Number(selectedItem?.sale_commission_amount) >= 0
          ? `$${commaStyle(selectedItem?.sale_commission_amount)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|sale_commission_percentage|*',
        selectedItem?.sale_commission_percentage &&
          Number(selectedItem?.sale_commission_percentage) >= 0
          ? `${Number(selectedItem?.sale_commission_percentage)}%`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|RE_commission_amount|*',
        selectedItem?.RE_commission_amount &&
          Number(selectedItem?.RE_commission_amount) >= 0
          ? `$${commaStyle(selectedItem?.RE_commission_amount)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|RE_commission_percentage|*',
        selectedItem?.RE_commission_percentage &&
          Number(selectedItem?.RE_commission_percentage) >= 0
          ? `${Number(selectedItem?.RE_commission_percentage)}%`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|equipment_price|*',
        selectedItem?.equipment_price && Number(selectedItem?.equipment_price) >= 0
          ? `$${commaStyle(selectedItem?.equipment_price)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|inventory_price|*',
        selectedItem?.inventory_price && Number(selectedItem?.inventory_price) >= 0
          ? `$${commaStyle(selectedItem?.inventory_price)}`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|term_cash|*',
        selectedItem?.term_cash || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|term_sba|*',
        selectedItem?.term_sba || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|term_conventional|*',
        selectedItem?.term_conventional || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|term_other|*',
        selectedItem?.term_other || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_carry_years|*',
        selectedItem?.seller_carry_years || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_carry_rate|*',
        selectedItem?.seller_carry_rate || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|acceptance_deadline|*',
        selectedItem?.acceptance_deadline || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|inventory_margin|*',
        selectedItem?.inventory_margin || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_execution_date_1|*',
        selectedItem?.buyer_execution_date_1
          ? formateDate(selectedItem?.buyer_execution_date_1)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_execution_date_2|*',
        selectedItem?.buyer_execution_date_2
          ? formateDate(selectedItem?.buyer_execution_date_2)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_execution_date|*',
        selectedItem?.seller_execution_date
          ? formateDate(selectedItem?.seller_execution_date)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|date_executed|*',
        selectedItem?.date_executed ? formateDate(selectedItem?.date_executed) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|today_date|*',
        selectedItem?.today_date ? formateDate(selectedItem?.today_date) : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_name|*',
        selectedItem?.listing_broker_name?.name || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_title|*',
        selectedItem?.listing_broker_title || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_email|*',
        selectedItem?.listing_broker_email || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_phone|*',
        selectedItem?.listing_broker_phone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_telephone|*',
        selectedItem?.listing_broker_telephone || '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|listing_broker_signature_date|*',
        selectedItem?.listing_broker_signature_date
          ? formateDate(selectedItem?.listing_broker_signature_date)
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|seller_signature|*',
        selectedItem?.seller_signature
          ? `<img draggable="false" width="100" src="${selectedItem?.seller_signature}" alt="Seller Signature" />`
          : '',
      );

      replacedContent = replacedContent?.replaceAll(
        '*|buyer_signature|*',
        selectedItem?.buyer_signature
          ? `<img draggable="false" width="100" src="${selectedItem?.buyer_signature}" alt="Buyer Signature" />`
          : '',
      );

      return replacedContent;
    } else {
      return '';
    }
  };

  const handleActionClick = (flag) => {
    if (flag == 'Delete') {
      setOpenDeleteConfirmation(true);
    } else if (flag == 'Edit') {
      navigate(`/contract-agreements/edit-agreement/${selectedItem?._id}`);
    }
  };

  const handleDelete = async () => {
    const response = await Delete(
      `${baseApiUrl}/${selectedItem?._id}`,
      apiHeader(accessToken),
    );

    if (response?.status === 200) {
      toast.success('Deleted successfully.');
      getAllContractSent();
      setSelectedItem(null);
      setOpenDeleteConfirmation(false);
    } else {
      toast.error(response.data || MSG_ERROR);
    }
  };

  const imageUrlToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };

  useEffect(() => {
    getAllContractSent();
  }, [accessToken, debouncedSearchTerm]);

  const handleLogo = async () => {
    const base64 = await imageUrlToBase64(imageUrl(logo));
    setCompanyLogoBase64(base64);
  };

  useEffect(() => {
    if (logo) {
      handleLogo();
    }
  }, [logo]);

  return (
    <>
      <style>
        {`
      .table100 {
        overflow-x:scroll !important;
        padding-top: 0px;
      }
      .table100-head {
        min-width: 940px;
        position: relative;
      }
      .table100.ver1 th {
        font-size: 13px;
      }
      .table100.ver1 td {
        font-size: 13px;
      }
      .table100-body {
        min-width: 940px;
        max-height: calc(100vh - 340px);
        margin-top: 3px;
        overflow: auto;
      }
      .table100-body td {
        padding-block: 18px;
      }
      .column1 {
        padding-left: 16px;
      }
      .table100.ver1 .table100-body tr {
        margin:15px;
      }
      .table100 .table100-head tr {
        margin: 0 15px;
      }
      .table100-foot {
        min-width: 940px;
        position: relative;
      }
      `}
      </style>
      <div>
        <SideBarSkeleton>
          <ContractHeader />
          <div className={[classes.mainContainer]}>
            <div className={[classes.headerContainer]}>
              <h3>Agreement Status</h3>
              <div className={[classes.inputDiv]}>
                <SearchInput
                  setter={setSearch}
                  value={search}
                  placeholder={'Search'}
                />

                {/* <Button
                  label="Add Agreement"
                  className={classes.addProjectBtn}
                  onClick={() => {
                    navigate('/contract-agreements/add-agreement');
                  }}
                /> */}
              </div>
            </div>

            <div className="table100 ver1 m-b-110">
              <div className="table100-head">
                <table>
                  <thead>
                    <tr className="row100 head">
                      <th
                        className="cell100 column1"
                        style={{ width: '5%', textAlign: 'start' }}
                      >
                        S.No
                      </th>
                      <th
                        className="cell100 column1"
                        style={{ width: '20%', textAlign: 'start' }}
                      >
                        Listing
                      </th>
                      <th
                        className="cell100 column2"
                        style={{ width: '20%', textAlign: 'start' }}
                      >
                        Party 1
                      </th>
                      <th
                        className="cell100 column3"
                        style={{ width: '20%', textAlign: 'start' }}
                      >
                        Party 2
                      </th>
                      <th
                        className="cell100 column3"
                        style={{ width: '20%', textAlign: 'start' }}
                      >
                        Party 3
                      </th>

                      <th
                        className="cell100 column3"
                        style={{ width: '10%', textAlign: 'start' }}
                      >
                        View
                      </th>

                      <th
                        className="cell100 column3"
                        style={{ width: '25%', textAlign: 'start' }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              {isFetchingTemplates ? (
                <TableSkeleton rowsCount={recordsLimit} colsCount={6} />
              ) : (
                <div className="table100-body js-pscroll ps ps--active-y">
                  <table>
                    <tbody>
                      {templateData?.result?.length > 0 ? (
                        templateData?.result?.map((item, index) => (
                          <tr
                            className="row100 body"
                            style={{
                              backgroundColor:
                                selectedItem?._id == item?._id && '#E5F5FF',
                            }}
                          >
                            <td
                              className="cell100 column1"
                              style={{
                                width: '5%',
                                textAlign: 'start',
                              }}
                            >
                              {page == 1
                                ? index + 1
                                : recordsLimit * (page - 1) + index + 1}
                            </td>
                            <td
                              className="cell100 column1"
                              style={{ width: '20%', textAlign: 'start' }}
                            >
                              {item?.listing?.companyName}
                            </td>
                            <td
                              className="cell100 column3"
                              style={{
                                width: '20%',
                                textAlign: 'start',
                                maxWidth: '35%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                textTransform: 'capitalize',
                              }}
                            >
                              {item?.recipients[0]
                                ? `${item?.recipients[0]?.name} (${item?.recipients[0]?.role})`
                                : '-'}
                            </td>

                            <td
                              className="cell100 column7"
                              style={{ width: '20%', textAlign: 'left' }}
                            >
                              <div
                                style={{
                                  position: 'relative',
                                  cursor: 'pointer',
                                }}
                              >
                                {item?.recipients[1]
                                  ? `${item?.recipients[1]?.name} (${item?.recipients[1]?.role})`
                                  : '-'}
                              </div>
                            </td>

                            <td
                              className="cell100 column7"
                              style={{ width: '20%', textAlign: 'left' }}
                            >
                              <div
                                style={{
                                  position: 'relative',
                                  cursor: 'pointer',
                                }}
                              >
                                {item?.recipients[2]
                                  ? `${item?.recipients[2]?.name} (${item?.recipients[2]?.role})`
                                  : '-'}
                              </div>
                            </td>
                            <td
                              className="cell100 column7"
                              style={{ width: '10%', textAlign: 'left' }}
                            >
                              <div
                                style={{
                                  position: 'relative',
                                  cursor: 'pointer',
                                  width: 'fit-content',
                                }}
                                onClick={() => {
                                  setIsOpenEmailPreview(true);
                                  setSelectedItem(item);
                                }}
                              >
                                <LuEye size={25} />
                              </div>
                            </td>

                            <td
                              className="cell100 column7"
                              style={{ width: '25%' }}
                            >
                              <p
                                className={`${classes.status} ${
                                  classes[
                                    `color-${
                                      (item?.status?.toLowerCase() ==
                                        'error' &&
                                        'red') ||
                                      (item?.status?.toLowerCase() ==
                                        'sent' &&
                                        'green') ||
                                      (item?.status?.toLowerCase() ==
                                        'completed' &&
                                        'blue') ||
                                      (item?.status?.toLowerCase() ==
                                        'manually completed' &&
                                        'blue') ||
                                      (item?.status?.toLowerCase() ==
                                        'pending' &&
                                        'blue-1') ||
                                      item?.status?.toLowerCase() ==
                                        'created' ||
                                      (item?.status?.toLowerCase() ==
                                        'viewed' &&
                                        'black')
                                    }`
                                  ]
                                }`}
                              >
                                {item.status?.toLowerCase() ===
                                'pending'
                                  ? 'In-Progress'
                                  : item?.status}
                              </p>

                              <div
                                style={{
                                  position: 'relative',
                                  cursor: 'pointer',
                                }}
                              ></div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoData text={'No Contract Agreements Sent'} />
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {/* {templateData?.result?.length > 1 && (
              <div className={[classes.paginationDiv]}>
                <PaginationComponent
                  totalPages={templateData?.totalPages}
                  currentPage={page}
                  setCurrentPage={setPage}
                />
              </div>
            )} */}

            <PoperComponent
              handleClick={handleActionClick}
              open={openPopper}
              setOpen={setOpenPopper}
              anchorRef={anchorRef}
              data={['Delete']}
            />
          </div>
        </SideBarSkeleton>
      </div>
      <ModalSkeleton
        show={isOpenEmailPreview}
        setShow={setIsOpenEmailPreview}
        borderRadius="20px"
        width="734px"
        borderLine={false}
        header={'Contract Preview'}
        showCloseIcon={true}
      >
        <div
          dangerouslySetInnerHTML={{ __html: getAgreementContent(selectedItem?.content) }}
          style={{
            maxHeight: '70dvh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        />
      </ModalSkeleton>
      <AreYouSureModal
        show={openDeleteConfirmation}
        setShow={setOpenDeleteConfirmation}
        subTitle={`Are you sure you want delete this template`}
        isApiCall={false}
        onClick={() => handleDelete()}
      />
    </>
  );
};

export default ContractSent;
