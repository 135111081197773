import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BaseURL } from '../../config/apiUrl';
import { Get } from '../../Axios/AxiosFunctions';
import Loader from '../../Component/Loader';
import NDATemplate from '../NDATemplate';

const UpdateNDATemplate = () => {
  const { access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state.authReducer,
  );

  const baseApiUrl = BaseURL(`company-nda-template`);

  const [isLoading, setIsLoading] = useState(true);

  const [templateData, setTemplateData] = useState(null);

  const getCompanyTemplate = async () => {
    try {
      const response = await Get(baseApiUrl, accessToken, companyId);

      if (response?.status === 200 && response?.data) {
        setTemplateData(response?.data);
        setIsLoading(false);
      } else {
        setTemplateData(null);
        setIsLoading(false);
      }
    } catch {
      setTemplateData(null);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getCompanyTemplate();
    }
  }, [accessToken]);

  return <NDATemplate editData={templateData} isLoading={isLoading} />;
};

export default UpdateNDATemplate;
